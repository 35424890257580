import { Button, Row, Col, Space, notification, Spin, Input, Checkbox, Switch, Table, Radio, Tree,Modal,Select } from "antd";
import apiHandler from "api";
import endpoint from "api/endpoint";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { EditOutlined,PlusCircleOutlined } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import { setWebsiteUserData } from "features/agency/websiteUserSlice";
import { showWebsiteUser,editOrAdd } from "features/common/commonSlice";
import { showLoader,setSelectedRowKeys} from "features/common/commonSlice";
import Filters from "domain/Filters/Filters";
import { setFilters } from "features/caseload/filterSlice";
import { setTopSearchQueryValue } from "features/caseload/searchSlice";
import TestPhoto from "assets/test_photo.png";
import RefPhoto from "assets/refrence.png";
import axios from 'axios';
import SelectAntd from "components/SelectAntd/SelectAntd";

import {
    StyledColLeft,
    StyledColRight,
    StyledHeading,
    StyledSubHeading,
    ActiveDevicesHeading,
    DeviceList
  } from "./Photos.styles";
import { result } from "lodash";
export const StyledSpace = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45vh;
  font-size: 20px;
  font-weight: bold;
`;
export const StyledModal = styled(Modal)`
  border-radius: 10px;
`;
export const NormalButton = styled.button`
background: rgb(5, 87, 162);
border: 1px solid #d9d9d9;
box-sizing: border-box;
box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
border-radius: 2px;
margin-bottom: 10px;
color: rgb(255, 255, 255);;
padding: 5px 16px;
cursor: pointer;
float:right;
`;
export const RedButton = styled.button`
background: rgb(255,0,0);
border: 1px solid #d9d9d9;
box-sizing: border-box;
box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
border-radius: 2px;
margin-bottom: 10px;
color: rgb(255, 255, 255);;
padding: 5px 16px;
cursor: pointer;
float:right;
`;
export const GreenButton = styled.button`
background: rgb(50,205,50);
border: 1px solid #d9d9d9;
box-sizing: border-box;
box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
border-radius: 2px;
margin-bottom: 10px;
color: rgb(255, 255, 255);;
padding: 5px 16px;
cursor: pointer;
float:right;
`;
const Photos = () => {
  const dispatch = useDispatch();
  const { register, getValues, setValue, reset, handleSubmit, formState: {errors} } = useForm({ defaultValues: { displayNext: false } });
  const [agencyList, getAgencyData] = useState([]);
  const [agencyArr, getAgencyArray] = useState([]);
  const { authToken, userData } = useSelector((state) => state.login);
  const [contactList, setContact] = useState([]);
  const isLoading = useSelector((state) => state.common.showLoader);
  const [actChecked, setActiveId] = useState('');
  const [agencyName, setAgencyName] = useState('');
  const [closableItems, setClosableItems] = useState([]);
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const [photoValidate, setPhotoValidate] = useState([]);
  const [participantData, setParticipantData] = useState([]);
  const [testImage, setTestImag] = useState('');
  const [refImage, setRefImag] = useState('')
  const [selectedWarning, setSelectedWarning] = useState('')
  const [dt,setDt] = useState(false);
  const [recordFound,setRecordFound] = useState(false);
  const warningMessages = [
    { label: "Was not in a well-lit area", value: "BAD_LIGHT" },
    { label: "Was not facing the camera", value: "BAD_ORIENTATION" },
    { label: "Did not have entire face visible", value: "FACE_CUT_OFF" },
    { label: "Had an obstruction to the face", value: "OBSTRUCTION" },
    { label: "Did not make device screen visible", value: "NO_LCD" },
    { label: "No face/not blowing in photo", value: "NOT_BLOWING" },
  ]
  dispatch(showWebsiteUser(false));
  dispatch(editOrAdd(false));
  // On filter change
  const onFilterChange = (e) => {
    console.log("work here innn");
    if(e.target.checked == true){
      dispatch(showLoader(true));
      getSavedAgency(e.target.value);
      setActiveId(e.target.value);
    }
    else {
      setActiveId('');
    }
  };
  const filters = useSelector((state) => state.filterData.filterDataObj);
  const handleCancel = () => {
    setCopyModalVisible(false);
  };
  const selectedFilters = useSelector(
    (state) => state.caseloadFilters.filterObj
  );

  const caseloadFilters = useSelector(
    (state) => state.caseloadFilters.filterObj
  );
  const setFilter = (value, filterType) => {
    dispatch(showLoader(true));

    if (filterType === undefined) {
      filterType = "agencyId";
    }

    let filtersList = caseloadFilters;

    if (filterType === "search") {
      dispatch(setTopSearchQueryValue(""));
      let closeItems = closableItems.filter(
        (obj) => obj.filterType !== "search" && obj
      );
      setClosableItems(closeItems);
      dispatch(showLoader(false));
      return;
    }

    if (filtersList[filterType]?.includes(value)) {
      filtersList = filters[filterType]?.map((item) => {
        return (
          filtersList &&
          filtersList[filterType]?.filter(
            (val) => item.parentAgencyId !== val && val
          )
        );
      });
      filtersList =
        filtersList &&
        filtersList[filterType]?.filter((val) => val !== value && val);
      dispatch(setFilters({ ...caseloadFilters, [filterType]: filtersList }));
    }
  };
 
  // Get Agency Data
  useEffect(() => {
    dispatch(showLoader(true));
    let activeId = '';
    if(userData?.agencies.length > 0){
      activeId = userData?.agencies[0]['id'];
    }
    console.log("activeId",activeId);
    getSavedAgency(activeId);
    //getContactList(activeId);
    getUnvalidatedPhotos()
    getAgeyList();
    setActiveId(activeId);

  }, []);
  const getUnvalidatedPhotos = () =>{
    apiHandler({
      url: `${endpoint.ADMIN_UNVALIDATED_PHOTOS}`,
      authToken,
    }).then((result) => {
      if(result.data){
        setRecordFound(true);
        apiHandler({
          url: `${endpoint.ENROLL_PARTICIPANT}`+"/"+`${result.data.participantId}`,
          authToken,
        }).then((results) => {
         
         setParticipantData(results.data);
         const imageUrl = `${process.env.REACT_APP_PARTICIPANT_IMAGE_URL_QA}/${results.data?.id}/testResult_${result.data.id}.png`; //obj.id_thumb
         const refimageUrl = `${process.env.REACT_APP_PARTICIPANT_IMAGE_URL_QA}/${results.data?.id}/referenceImage.png`; 
         setRefImag(refimageUrl); 
         setDt(CheckImage(imageUrl));
          setTestImag(imageUrl);
        });
        setPhotoValidate(result.data);
      }
      
      
    });
  }
  const getSavedAgency = async (agencyId) => {
    // Get Agency Saved Data
    apiHandler({
      url: `${endpoint.ADMIN_AGENCY}/${agencyId}`,
      authToken,
    }).then((result) => {
      if(result){
        setAgencyName(result?.data?.name);
        setValue("name", result?.data?.name);
        setValue("description", result?.data?.description);
        setValue("timezone", result?.data?.timeZone);
        setValue("autoValidation", result?.data?.autoValidationStatus);
        setValue("zip", result?.data?.address?.zip);
        setValue("city", result?.data?.address?.city);
        setValue("state", result?.data?.address?.state);
        setValue("addressOne", result?.data?.address?.address1);
        setValue("addressLineTwo", result?.data?.address?.address2);
        setValue("addressLineThree", result?.data?.address?.address3);
        setValue("displayNext", result?.data?.displayNextTestTime);
        // setValue("parentAgency", result?.data?.parentAgencyId);
        getAgencyData(result?.data);
        if(result?.data?.websiteUsers?.length > 0){
          dispatch(setWebsiteUserData(result.data.websiteUsers.map((row,i) =>({
            key: (i+1),
            name: row.firstName +" "+row.lastName,
            preferred: row.email,
            status: row.status,
            edits: <EditOutlined />,
          }))));
        }
        if(result?.data?.notificationContacts?.length > 0){
          setContact(result.data.notificationContacts.map((row,i) =>({
            key: (i+1),
            name: row.firstName +" "+row.lastName,
            preferred: row.email,
            role: row.role,
          })));
        }
       
        dispatch(showLoader(false));
      }
    });
  }
const openWarning = () =>{
  setCopyModalVisible(true);
}
const sendWarningMessege = () =>{
  apiHandler({
    url: `${endpoint.VALIDATE_TEST_RESULT}`,
    authToken,
    method: "POST",
    data:{
      id:photoValidate.id,
      validationResult: "INVALID",
      invalidReason: selectedWarning
     
    }
  }).then((results) => {
    if(results.data){
      setCopyModalVisible(false);
      if(results.data.entity){
        setPhotoValidate(results.data.entity);
        getUnvalidatedPhotos();
      }
      if(results.data.message){
        notification.info({
          description: results.data.message,
          placement: "topRight",
          duration: 5,
        });
      }else{
        notification.info({
          description: "Record Updated",
          placement: "topRight",
          duration: 5,
        });
      }
    }
  });
}
const temperMatch = () =>{
  apiHandler({
    url: `${endpoint.VALIDATE_TEST_RESULT}`,
    authToken,
    method: "POST",
    data:{
      id:photoValidate.id,
      validationResult: "INVALID",
      invalidReason: "TAMPER"
     
    }
  }).then((results) => {
    if(results.data){
      if(results.data.entity){
        setPhotoValidate(results.data.entity);
        getUnvalidatedPhotos();
      }
      if(results.data.message){
        notification.info({
          description: results.data.message,
          placement: "topRight",
          duration: 5,
        });
      }else{
        notification.info({
          description: "Record Updated",
          placement: "topRight",
          duration: 5,
        });
      }
    }
  });
}
const escalateMatch = () =>{
  apiHandler({
    url: `${endpoint.VALIDATE_TEST_RESULT}`,
    authToken,
    method: "POST",
    data:{
      id:photoValidate.id,
      requiresManagerApproval: true
    }
  }).then((results) => {
    if(results.data){
      if(results.data.message){
        notification.info({
          description: results.data.message,
          placement: "topRight",
          duration: 5,
        });
      }else{
        notification.info({
          description: "Record Updated",
          placement: "topRight",
          duration: 5,
        });
      }
      if(results.data.entity){
        setPhotoValidate(results.data.entity);
        getUnvalidatedPhotos();
      }
    }
  });
}
const identityMatch = () =>{
  apiHandler({
    url: `${endpoint.VALIDATE_TEST_RESULT}`,
    authToken,
    method: "POST",
    data:{
      id:photoValidate.id,
      validationResult: "VALID"
    }
  }).then((results) => {
    if(results.data){
      if(results.data.entity){
        setPhotoValidate(results.data.entity);
        getUnvalidatedPhotos();
      }
      if(results.data.message){
        notification.info({
          description: results.data.message,
          placement: "topRight",
          duration: 5,
        });
      }else{
        notification.info({
          description: "Record Updated",
          placement: "topRight",
          duration: 5,
        });
      }
    }
  });
}
const identityMismatch = () =>{
  apiHandler({
    url: `${endpoint.VALIDATE_TEST_RESULT}`,
    authToken,
    method: "POST",
    data:{
      id:photoValidate.id,
      validationResult: "INVALID",
      invalidReason: "OTHER_PERSON"
     
    }
  }).then((results) => {
    if(results.data){
      if(results.data.entity){
        setPhotoValidate(results.data.entity);
        getUnvalidatedPhotos();
      }
      if(results.data.message){
        notification.info({
          description: results.data.message,
          placement: "topRight",
          duration: 5,
        });
      }else{
        notification.info({
          description: "Record Updated",
          placement: "topRight",
          duration: 5,
        });
      }
    }
  });

}
const scoreNow = () =>{
  apiHandler({
    url: `${endpoint.TEST_SCORE_NOW}`+"/"+`${photoValidate.id}`+"/score",
    authToken,
  }).then((results) => {
    if(results.data){
      if(results.data.message){
        notification.info({
          description: results.data.message,
          placement: "topRight",
          duration: 5,
        });
      }else{
        notification.info({
          description: "Record Updated",
          placement: "topRight",
          duration: 5,
        });
      }
    }
  });

}
const handleWarningChange = (value) => {
  setSelectedWarning(value);
};
  const getAgeyList = () => {
    apiHandler({
      url: `${endpoint.AGENCIES}`,
      authToken,
    }).then((result) => {
      getAgencyArray(result?.data);
    });
  }
  const CheckImage = (path) => {
    axios
      .get(path)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }
  

  return (
    <Row>
      {isLoading ? (
        <Col md={24}>
          <StyledSpace>
            <Spin size={"large"} />
            Loading...
          </StyledSpace>
      </Col>
      ) : (
        <>
        
        <StyledColLeft md={4}>
        <Filters  pathname={"photos"}/>
          </StyledColLeft>
          <StyledColRight md={20}>
        <StyledHeading>Photo Review</StyledHeading>
        <hr />
        {recordFound?
        <>
        <Row>
        Test #{photoValidate.id} for {participantData.fullName} taken at {photoValidate.takenAt}
        </Row>
        <Row>
        <StyledColLeft md={7}>
          <br />
          <br />
          <Row><h4><b>Automated Validation Results</b></h4></Row>
          <Row>
            <Col md={12}>
            Facial Recognition:
            </Col>
            <Col style={{float:"right"}} md={8}>
              <NormalButton onClick={scoreNow}>Score Now</NormalButton>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            Time Validation: 
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.automatedTimeValidationResult}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            Liveness Detection:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.automatedLivenessDetectionResult}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            Tamper Detection:
            </Col>
            
            <Col style={{float:"right"}} md={10}>
            {photoValidate.automatedTamperDetectionResult}
            </Col>
          </Row>
          <Row>
            <Col  md={12}>
            Confirmation Code:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.visualConfirmationCode}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            <GreenButton onClick={identityMatch} style={{width:"100%"}}>Identity Match</GreenButton>
            </Col >
            <Col md={12}>
            <RedButton onClick={identityMismatch} style={{width:"100%"}}>Identity Mismatch</RedButton>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            <NormalButton   onClick={openWarning}style={{width:"100%"}}>Warn</NormalButton>
            </Col>
            <Col md={12}>
            <NormalButton onClick = {temperMatch} style={{width:"100%"}}>Tamper</NormalButton>
            </Col>
          </Row>
          <Row>
            <Col md ={24}> 
            <NormalButton onClick = {escalateMatch} style={{width:"100%"}}>Escalate</NormalButton>
            
            </Col>
           
          </Row>
        </StyledColLeft>
        <StyledColRight md={17}>
          <Row>
            <Col  md={10}>
              <center>Refrence Photo</center>
              
              {<img  width ="90%" alt="photo_thumb" src={refImage}/>}
            {/* <img width ="90%" src={TestPhoto} /> */}
            </Col>
            <Col  md={10}>
            <center>Test Photo</center>
            {dt && <img width ="90%" src={testImage} />}
            </Col>
          </Row>
          </StyledColRight>
        </Row>
        
        </>
        :<>
        <h4>No Record Found</h4>
        </>}
        
        </StyledColRight>
        <StyledModal visible={copyModalVisible} width={600} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>&nbsp; &nbsp; &nbsp;Send Warning Message</Col>
            <Col md={15}>
             
              <Button style={{float: 'right'}} onClick={handleCancel}>Cancel</Button>
            </Col>

          </Row>
        ]}        
      >
        
        <Row>
          <Col md={24}>
          
          </Col>
        </Row>
        <Row>
        Warning Message To Send
        </Row>
        <Row>
       
        <br />
        <Select
              placeholder="Select Warning Message To Send"
              options={warningMessages}
              style={{ width: 320 }}
              //width="300px"
              onChange={handleWarningChange}
              pageKey="enroll"
            />
        </Row>
       <Row>
        <Col md={24}> <NormalButton style={{float:"right"}} onClick={sendWarningMessege}>Send</NormalButton></Col>
       </Row>
      </StyledModal>
        </>
      )}

    </Row>
  )
}

export default Photos;
