// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  @media print {

    tr {
        display: block;
    }
    span{
        top: 50%;
        right: 50%;
        position: absolute;
        transform: scale(-1) translate(-50%, 50%);
        writing-mode: vertical-lr;
    }
    .pagebreak {
        page-break-after: always !important;
        page-break-before: always !important;
    }
}
.item1 { grid-area: brac; }
.item2 { grid-area: chart; }


.grid-container {
  display: grid;
  grid-template-areas:
    'brac chart chart chart chart chart';
  gap: 10px;
 
}`, "",{"version":3,"sources":["webpack://./src/domain/Caseload/Events/events.css"],"names":[],"mappings":";EACE;;IAEE;QACI,cAAc;IAClB;IACA;QACI,QAAQ;QACR,UAAU;QACV,kBAAkB;QAClB,yCAAyC;QACzC,yBAAyB;IAC7B;IACA;QACI,mCAAmC;QACnC,oCAAoC;IACxC;AACJ;AACA,SAAS,eAAe,EAAE;AAC1B,SAAS,gBAAgB,EAAE;;;AAG3B;EACE,aAAa;EACb;wCACsC;EACtC,SAAS;;AAEX","sourcesContent":["\n  @media print {\n\n    tr {\n        display: block;\n    }\n    span{\n        top: 50%;\n        right: 50%;\n        position: absolute;\n        transform: scale(-1) translate(-50%, 50%);\n        writing-mode: vertical-lr;\n    }\n    .pagebreak {\n        page-break-after: always !important;\n        page-break-before: always !important;\n    }\n}\n.item1 { grid-area: brac; }\n.item2 { grid-area: chart; }\n\n\n.grid-container {\n  display: grid;\n  grid-template-areas:\n    'brac chart chart chart chart chart';\n  gap: 10px;\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
