import {
  Button,
  Row,
  Col,
  Space,
  notification,
  Spin,
  Input,
  Checkbox,
  Switch,
  Table,
  Radio,
  Tree,
  Modal,
} from "antd";
import { DataNode, TreeProps } from "antd/es/tree";
import { StyledStarIcon } from "components/CustomDataTable/CustomDataTable.style";
import apiHandler from "api";
import endpoint from "api/endpoint";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import pinnedSvg from "assets/caseload/pinned.svg";
import unPinnedSvg from "assets/caseload/notPinned.svg";
import moment from "moment";
import CustomDataTable from "components/CustomDataTable/CustomDataTable";
import { setSelectedEvents } from "features/common/commonSlice";
import {
  StyledColLeft,
  StyledColRight,
  StyledHeading,
  StyledSubHeading,
  ActiveDevicesHeading,
  DeviceList,
  resolutionBtns,
  textCenter,
} from "./OpenEvents.styles";
import AddNewWebsite from "../AddNewWebsite/AddNewWebsite";
import NewContact from "../NewContact/NewContact";
import {
  EditOutlined,
  PlusCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import EditAlters from "containers/EditAlerts/EditAlerts";
import { useForm } from "react-hook-form";
import { setWebsiteUserData } from "features/agency/websiteUserSlice";
import { seteditUserData } from "features/agency/editUserSlice";
import { showWebsiteUser, editOrAdd } from "features/common/commonSlice";
import { showLoader, setSelectedRowKeys } from "features/common/commonSlice";
import { cloneDeep } from "lodash";
import { setExceptionErrorMessage } from "features/common/commonSlice";
import { Link } from "react-router-dom";
import axios from "axios";
export const StyledSpace = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45vh;
  font-size: 20px;
  font-weight: bold;
`;

const OpenEvents = () => {
  const dispatch = useDispatch();
  const {
    register,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { displayNext: false } });

  // alcohol resolution modal actions
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState({});
  let dataEvent = [];
  // this resolved state is just used to update the UI and passed in useEffect dependenecy so that without refresh one can easily see the updated UI.
  const [resolved,setResolved] = useState(false);
  const showModal = (mType, resEvent) => {
    setModalType((prev) => {
      return { mType, resEvent };
    });
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    if (modalType?.mType == "UP") {
     
      let payload = {
        id: modalType?.resEvent?.id,
        eventType: {
          id: modalType?.resEvent?.eventType.id,
        },
        participant: {
          id: modalType?.resEvent.participantId,
        },
        device: {
          id: modalType?.resEvent.device.id,
        },
        alcoholConcentration: modalType?.resEvent.alcoholConcentration,
        createdAt: modalType?.resEvent.createdAt,
        compliantAt: modalType?.resEvent.compliantAt,
        isManuallyResolved: true,
      };

      const data = await axios.post(
        `${process.env.REACT_APP_OPENSHIFT_APP_DNS}/complianceEvent`,
        payload,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setResolved(!resolved);
    } else if (modalType.mType == "DOWN") {

      let payload = {
        id: modalType?.resEvent?.id,
        eventType: {
          id: modalType?.resEvent.eventType.id,
        },
        participant: {
          id: modalType?.resEvent.participantId,
        },
        device: {
          id: modalType?.resEvent.device.id,
        },
        alcoholConcentration: modalType?.resEvent.alcoholConcentration,
        createdAt: modalType?.resEvent.createdAt,
        compliantAt: modalType?.resEvent.compliantAt,
        isManuallyResolved: false,
      };
      const data = await axios.post(
        `${process.env.REACT_APP_OPENSHIFT_APP_DNS}/complianceEvent`,
        payload,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setResolved(!resolved);

    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [agencyList, getAgencyData] = useState([]);
  const [agencyArr, getAgencyArray] = useState([]);
  const { authToken, userData } = useSelector((state) => state.login);
  const [contactList, setContact] = useState([]);
  const [deviceList, setDevice] = useState([]);
  const [activeDeviceList, setActiveDeviceList] = useState([]);
  const [isDeviceInititalized, setDeviceInitialized] = useState(false);
  const webloadData = useSelector(
    (state) => state.websiteUserData.websiteDataObj
  );
  const isLoading = useSelector((state) => state.common.showLoader);
  const [actChecked, setActiveId] = useState("");
  const [agencyName, setAgencyName] = useState("");
  let [breathData, setbreathData] = useState([]);
  let [watchData, setwatchData] = useState([]);
  let [gpsData, setgpsData] = useState([]);
  let [maintenanceData, setMaintenanceData] = useState([]);
  let [univarsalData, setunivarsalData] = useState([]);
  const [openEventsData, setOpenEventsData] = useState([]);
  const { selectedEvents } = useSelector((state) => state.common);
  const [showError, setShowError] = useState(false);
  
  dispatch(showWebsiteUser(false));
  dispatch(editOrAdd(false));
  // On filter change
  const onFilterChange = async(e,i) => {
    console.log("event hdhhdh herere",i);
    if (e.target.checked == true) {
      dispatch(showLoader(true));
      getSavedAgency(e.target.value);
      setActiveId(e.target.value);
      if(i==0){
        let data = await getAgeyList()
        getAllEvents(data);
      }else{
        getAllEvents("agencyId="+e.target.value);
      }
      
      
    } else {
      setActiveId("");
    }
  };
  const dataSource = [
    {
      key: "1",
      markasRead: <Checkbox />,
      pin: <StyledStarIcon src={pinnedSvg}></StyledStarIcon>,
      status: "Closed",
      participant: "User Another One",
      deviceid: "12345",
      createdAt: "Jul 7, 2023 6:30 PM",
      recievedAt: "Jul 7, 2023 6:30 P",
      updatedAt: "Jul 7, 2023 6:30 P",
    },
    {
      key: "2",
      markasRead: <Checkbox />,
      pin: <StyledStarIcon src={unPinnedSvg}></StyledStarIcon>,
      status: "Closed",
      participant: "User Another One",
      deviceid: "12345",
      createdAt: "Jul 7, 2023 6:30 PM",
      recievedAt: "Jul 7, 2023 6:30 P",
      updatedAt: "Jul 7, 2023 6:30 P",
    },
  ];

  const columns = [
    {
      title: "   ",
      dataIndex: "markasRead",
      key: "markasRead",
    },
    {
      title: "Pin",
      dataIndex: "pin",
      key: "pin",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Resolution",
      dataIndex: "resolution",
      key: "resolution",
    },
    {
      title: "Participant",
      dataIndex: "participant",
      key: "participant",
    },
    {
      title: "Device Id",
      dataIndex: "deviceid",
      key: "deviceId",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    // {
    //   title: "Recieved At",
    //   dataIndex: "recievedAt",
    //   key: "recievedAt",
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updatedAt",
    //   key: "updatedAt",
    // },
  ];
  const treeData = [
    {
      title: "MAINTENANCE",
      key: "0-0",
      children: maintenanceData,
    },
    {
      title: "TRAC BREATH",
      key: "trac_breath",
      children: breathData,
    },
    {
      title: "TRAC WATCH",
      key: "trac_watch",
      children: watchData,
    },
    {
      title: "TRAC GPS",
      key: "trac_gps",
      children: gpsData,
    },
    {
      title: "UNIVERSAL Events",
      key: "universal_events",
      children: univarsalData,
    },
  ];

  const handleSubmitEvents = () => {
    let localitem = JSON.parse(localStorage.getItem('selectedItem'));
    //let dataS = Object.keys(localitem).map((key) => localitem[key]);
        ///
    try {
      if(localitem.length>0){
      let eventsData = [];
      eventsData = localitem.map((item) => {
        return {
          id: item,
          isRead: true,
        };
      });

         apiHandler({
          url: endpoint.COMPLIANCE_EVENTS,
          method: "POST",
          data: eventsData,
          authToken,
        }).then( async (result) => {
          notification.success({
            description: result?.data?.message,
            placement: "topRight",
            duration: 5,
          });
          let selected = [];
          dispatch(setSelectedRowKeys(selected));
          localStorage.removeItem('selectedItem');
          let data = await getAgeyList();
          console.log("data here for agency");
          getAllEvents(data);
         dispatch(setSelectedEvents([]))
        });
      }
     
    } catch (error) {
      dispatch(
        setExceptionErrorMessage("Select An Event To Complete Mark as Read")
      );
      setShowError(true);
    }
  };

  const pinEvent = (eventId, isPinned) => {
    apiHandler({
      url: endpoint.COMPLIANCE_EVENT,
      method: "POST",
      data: {
        isPinned,
        id: eventId,
      },
      authToken,
    }).then(async (result) => {
      // const eventsData = eventData?.map((obj) => {
      //   if (obj.id === eventId) {
      //     obj.isPinned = !obj.isPinned;
      //   }
      //   return obj;
      // });
      //setEventData(eventsData);
      let activeId = "";
      if (userData?.agencies.length > 0) {
        activeId = userData?.agencies[0]["id"];
      }
      getOpenEvents(activeId);
    });
  };
  useEffect(()=>{
    dataEvent = selectedEvents;
//    localStorage.setItem("selectedItem", selectedEvents)
    },[selectedEvents])
  // Get Agency Data
  useEffect(() => {
    dispatch(showLoader(true));
    let activeId = "";
    if (userData?.agencies.length > 0) {
      activeId = userData?.agencies[0]["id"];
    }

    getSavedAgency(activeId);
    //getContactList(activeId);
   
    getOpenEvents(activeId);
    setActiveId(activeId);
  }, []);

  const getAllEvents = async (data) => {
    //dispatch(setSelectedEvents([]))
    apiHandler({
      url: `${endpoint.AVAILABLE_EVENT_TYPES}`,
      authToken,
    }).then(async (result) => {
      let breath = [],
        gps = [],
        watch = [],
        univarsal = [],
        isMaintenance = [];
      result.data.map((dt, index) => {
        if (dt.isMaintenance) {
          isMaintenance.push({
            title: dt.name,
            key: "maintenance_" + index,
          });
        }
        if (dt.deviceTypeName == "Breathalyzer" && !dt.isMaintenance) {
          breath.push({
            title: dt.name,
            key: "breath_" + index,
          });
        }
        if (dt.deviceTypeName == "Watch" && dt?.name != "Positive Alcohol" && !dt.isMaintenance) {
          watch.push({
            title: dt.name,
            key: "watch_" + index,
          });
        }
        if (dt.deviceTypeName == "GPS" && !dt.isMaintenance) {
          gps.push({
            title: dt.name,
            key: "gps_" + index,
          });
        }
        if (dt.deviceTypeName == null && !dt.isMaintenance) {
          univarsal.push({
            title: dt.name,
            key: "universal_" + index,
          });
        }
      });

      apiHandler({
        url: `${endpoint.OPEN_EVENTS}?${data}`,
        authToken,
      }).then(async (result) => {
        result.data.sort(function(a, b) {
          var keyA = new Date(a.createdAt),
            keyB = new Date(b.createdAt);
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        setOpenEventsData(result.data);

        gps.map((gpsEvt, i) => {
          let source = [];
          let num = 0;
          result.data.map((resultEvt, i) => {
            if (
              resultEvt.eventType.name == gpsEvt.title &&
              resultEvt.eventType.deviceTypeName == "GPS" &&
              (!resultEvt.isRead || resultEvt.isPinned)
            ) {
              const col = {
                key: num,
                id: resultEvt.id,
                markasRead: "",
                pin: (
                  <StyledStarIcon
                    onClick={() => {
                      pinEvent(resultEvt.id, !resultEvt.isPinned);
                    }}
                    src={resultEvt.isPinned ? pinnedSvg : unPinnedSvg}
                  ></StyledStarIcon>
                ),

                status: resultEvt.eventStatus,
                resolution : "----",
                participant: resultEvt.participantName,
                deviceid: resultEvt?.device?.serialNumber
                  ? resultEvt?.device?.serialNumber
                  : "",
                createdAt: moment(resultEvt.createdAt).format(
                  "MMM D, YYYY h:mm A z"
                ),
                recievedAt: moment(resultEvt.receivedAt).format(
                  "MMM D, YYYY h:mm A z"
                ),
                updatedAt: moment(resultEvt.updatedAt).format(
                  "MMM D, YYYY h:mm A z"
                ),
                compliantAt: resultEvt.compliantAt,
              };
              source.push(col);
              num++;
            }
          });

          gpsEvt.title = gpsEvt.title + " " + num;
          gpsEvt.children = [
            {
              title: (
                <>
                  {" "}
                  <Button
                    type="primary"
                    style={{ float: "left" }}
                    onClick={handleSubmitEvents}
                  >
                    Mark As Read
                  </Button>
                  <CustomDataTable
                    showSelect={true}
                    position={["bottomRight"]}
                    columns={columns}
                    data={source}
                    showPagination={false}
                    className="eventsTable"
                    tableSource="events"
                  />
                </>
              ),
              key: "gps_sub_" + i,
            },
          ];

          //setgpsData(gps);
        });

        /// Universal Event
        univarsal.map((universalEvt, i) => {
          let source = [];
          let num = 0;

          result.data.map((resultEvt, index) => {
            if (
              resultEvt.eventType.name == universalEvt.title &&
              resultEvt.eventType.deviceTypeName == null &&
              (!resultEvt.isRead || resultEvt.isPinned)
            ) {
              const col = {
                key: num,
                id: resultEvt.id,
                markasRead: "",
                pin: (
                  <StyledStarIcon
                    onClick={() => {
                      pinEvent(resultEvt.id, !resultEvt.isPinned);
                    }}
                    src={resultEvt.isPinned ? pinnedSvg : unPinnedSvg}
                  ></StyledStarIcon>
                ),
                status: resultEvt.eventStatus,
                resolution : "----",
                participant: resultEvt.participantName,
                deviceid: resultEvt?.device?.serialNumber
                  ? resultEvt?.device?.serialNumber
                  : "",
                createdAt: moment(resultEvt.createdAt).format(
                  "MMM D, YYYY h:mm A z"
                ),
                recievedAt: moment(resultEvt.receivedAt).format(
                  "MMM D, YYYY h:mm A z"
                ),
                updatedAt: moment(resultEvt.updatedAt).format(
                  "MMM D, YYYY h:mm A z"
                ),
                compliantAt: resultEvt.compliantAt,
              };
              source.push(col);
              num++;
            }
          });
          universalEvt.title = universalEvt.title + " " + num;
          universalEvt.children = [
            {
              title: (
                <>
                  {" "}
                  <Button
                    type="primary"
                    style={{ float: "left" }}
                    onClick={handleSubmitEvents}
                  >
                    Mark As Read
                  </Button>
                  <CustomDataTable
                    showSelect={true}
                    position={["bottomRight"]}
                    columns={columns}
                    data={source}
                    showPagination={false}
                    className="eventsTable"
                    tableSource="events"
                  />
                </>
              ),
              key: "uni_sub_" + i,
            },
          ];

          // setunivarsalData(univarsal);
        });
        /// Breath Event
        breath.map((breathEvt, i) => {
          let source = [];
          let num = 0;
          result.data.map((resultEvt, index) => {
            if (
              resultEvt.eventType.name == breathEvt.title &&
              resultEvt.eventType.deviceTypeName == "Breathalyzer" &&
              (!resultEvt.isRead || resultEvt.isPinned)
            ) {
              const col = {
                key: num,
                id: resultEvt.id,
                markasRead: "",
                pin: (
                  <StyledStarIcon
                    onClick={() => {
                      pinEvent(resultEvt.id, !resultEvt.isPinned);
                    }}
                    src={resultEvt.isPinned ? pinnedSvg : unPinnedSvg}
                  ></StyledStarIcon>
                ),
                status: resultEvt.eventStatus,
                resolution : "----",
                participant: resultEvt.participantName,
                deviceid: resultEvt?.device?.serialNumber
                  ? resultEvt?.device?.serialNumber
                  : "",
                createdAt: moment(resultEvt.createdAt).format(
                  "MMM D, YYYY h:mm A z"
                ),
                recievedAt: moment(resultEvt.receivedAt).format(
                  "MMM D, YYYY h:mm A z"
                ),
                updatedAt: moment(resultEvt.updatedAt).format(
                  "MMM D, YYYY h:mm A z"
                ),
                compliantAt: resultEvt.compliantAt,
              };
              source.push(col);
              num++;
            }
          });
          breathEvt.title = breathEvt.title + " " + num;
          breathEvt.children = [
            {
              title: (
                <>
                  {" "}
                  <Button
                    type="primary"
                    style={{ float: "left" }}
                    onClick={handleSubmitEvents}
                  >
                    Mark As Read
                  </Button>
                  <CustomDataTable
                    showSelect={true}
                    position={["bottomRight"]}
                    columns={columns}
                    data={source}
                    showPagination={false}
                    className="eventsTable"
                    tableSource="events"
                  />
                </>
              ),
              key: "breath_sub_" + i,
            },
          ];

          // setbreathData(breath);
        });
        /// Watch Event
        watch.map((watchEvt, i) => {
          let source = [];
          let num = 0;
    
          result.data.map((resultEvt) => {
            if (
              resultEvt.eventType.name == watchEvt.title &&
              resultEvt.eventType.deviceTypeName == "Watch" &&
              (!resultEvt.isRead || resultEvt.isPinned)
            ) {
              if (
                resultEvt.eventType.name == "Positive Alcohol" ||
                resultEvt.eventType.name == "Obstruction Tamper"
              ) {
                var time = moment.duration("02:00:10");
                let link =
                  "/caseload/" +
                  resultEvt.participantId +
                  "/" +
                  moment(resultEvt.compliantAt)
                    .subtract(time)
                    .format("YYYY-MM-DDTHH:mm") +
                  moment().format("Z").replaceAll(":", "") +
                  "/" +
                  moment(resultEvt.compliantAt)
                    .add(time)
                    .format("YYYY-MM-DDTHH:mm") +
                  moment().format("Z").replaceAll(":", "") +
                  "/metrics";
                const col = {
                  key: num,
                  id: resultEvt.id,
                  markasRead: "",
                  pin: (
                    <StyledStarIcon
                      onClick={() => {
                        pinEvent(resultEvt.id, !resultEvt.isPinned);
                      }}
                      src={resultEvt.isPinned ? pinnedSvg : unPinnedSvg}
                    ></StyledStarIcon>
                  ),
                  status: resultEvt.eventStatus,
                  resolution : "----",
                  participant:
                    resultEvt.compliantAt !== null ? (
                      <Link to={link}>{resultEvt.participantName}</Link>
                    ) : (
                      resultEvt.participantName
                    ),
                  deviceid: resultEvt?.device?.serialNumber
                    ? resultEvt?.device?.serialNumber
                    : "",
                  createdAt: moment(resultEvt.createdAt).format(
                    "MMM D, YYYY h:mm A z"
                  ),
                  recievedAt: moment(resultEvt.receivedAt).format(
                    "MMM D, YYYY h:mm A z"
                  ),
                  updatedAt: moment(resultEvt.updatedAt).format(
                    "MMM D, YYYY h:mm A z"
                  ),
                  compliantAt: resultEvt.compliantAt,
                };
                source.push(col);
                num++;
              } else {
                let col =  {
                  key: num,
                  id: resultEvt.id,
                  markasRead: "",
                  pin: (
                    <StyledStarIcon
                      onClick={() => {
                        pinEvent(resultEvt.id, !resultEvt.isPinned);
                      }}
                      src={resultEvt.isPinned ? pinnedSvg : unPinnedSvg}
                    ></StyledStarIcon>
                  ),
                  status: resultEvt.eventStatus,
                  participant: resultEvt.participantName,
                  deviceid: resultEvt?.device?.serialNumber
                    ? resultEvt?.device?.serialNumber
                    : "",
                  createdAt: moment(resultEvt.createdAt).format(
                    "MMM D, YYYY h:mm A z"
                  ),
                  recievedAt: moment(resultEvt.receivedAt).format(
                    "MMM D, YYYY h:mm A z"
                  ),
                  updatedAt: moment(resultEvt.updatedAt).format(
                    "MMM D, YYYY h:mm A z"
                  ),
                  compliantAt: resultEvt.compliantAt,
                };
                //if eventType is critically battery low or other than the alcohol consumption we will not show any resolution kind of thing.
                if(resultEvt.eventType.name == "Critically Low Watch Battery"){
                  col = {
                    key: num,
                    id: resultEvt.id,
                    markasRead: "",
                    pin: (
                      <StyledStarIcon
                        onClick={() => {
                          pinEvent(resultEvt.id, !resultEvt.isPinned);
                        }}
                        src={resultEvt.isPinned ? pinnedSvg : unPinnedSvg}
                      ></StyledStarIcon>
                    ),
                    resolution : "----",
                    status: resultEvt.eventStatus,
                    participant: resultEvt.participantName,
                    deviceid: resultEvt?.device?.serialNumber
                      ? resultEvt?.device?.serialNumber
                      : "",
                    createdAt: moment(resultEvt.createdAt).format(
                      "MMM D, YYYY h:mm A z"
                    ),
                    recievedAt: moment(resultEvt.receivedAt).format(
                      "MMM D, YYYY h:mm A z"
                    ),
                    updatedAt: moment(resultEvt.updatedAt).format(
                      "MMM D, YYYY h:mm A z"
                    ),
                    compliantAt: resultEvt.compliantAt,
                  };
                }else{
                  col = {...col,    resolution:
                    userData?.websiteUserType?.isSuperUser && 
                    resultEvt?.eventType?.requiresManualResolution ? (
                      <>
                        {resultEvt?.isManuallyResolved == null ? (
                          <>
                            <button
                              style={resolutionBtns}
                              onClick={() => showModal("UP", resultEvt)}
                            >
                              <ArrowUpOutlined />
                            </button>{" "}
                            <button
                              style={resolutionBtns}
                              onClick={() => showModal("DOWN", resultEvt)}
                            >
                              <ArrowDownOutlined />
                            </button>
                          </>
                        ) : resultEvt?.isManuallyResolved == true ? (
                          <span style={{ color: "" }}>
                            Confirmed Alcohol Consumption
                          </span>
                        ) : (
                          <span style={{ color: "" }}>
                            Not An Alcohol Consumption
                          </span>
                        )}
                      </>
                    ) : userData?.websiteUserType?.isAgencyOnly &&
                      resultEvt?.isManuallyResolved == null ? (
                      "Unresolved Alcohol Consumption"
                    ) : userData?.websiteUserType?.isAgencyOnly &&
                      resultEvt?.isManuallyResolved == true ? (
                      <span style={{ color: "red" }}>
                        Confirmed Alcohol Consumption
                      </span>
                    ) : (
                      <span style={{ color: "blue" }}>
                        Not An Alcohol Consumption
                      </span>
                    ),}
                }
                source.push(col);
                num++;
              }
            }
          });
        
          watchEvt.title = watchEvt.title + " " + num;
          watchEvt.children = [
            {
              title: (
                <>
                  {" "}
                  <Button
                    type="primary"
                    style={{ float: "left" }}
                    onClick={handleSubmitEvents}
                  >
                    Mark As Read
                  </Button>
                  <CustomDataTable
                    showSelect={true}
                    position={["bottomRight"]}
                    columns={columns}
                    data={source}
                    showPagination={false}
                    className="eventsTable"
                    tableSource="events"
                  />
                </>
              ),
              key: "watch_sub_" + i,
            },
          ];

          //setwatchData(watch);
        });

        setgpsData(gps);
        setunivarsalData(univarsal);
        setwatchData(watch);
        setbreathData(breath);
      });
    });
  };
  useEffect(() => {
    async function getData(){
      let data = await getAgeyList();
      console.log("agency data here",data);
      //setActiveId(data)
      getAllEvents(data);
    }
    
    getData();
  }, [resolved]);

  const getOpenEvents = async (agencyId) => {};

  const getSavedAgency = async (agencyId) => {
    // Get Agency Saved Data
    apiHandler({
      url: `${endpoint.ADMIN_AGENCY}/${agencyId}`,
      authToken,
    }).then((result) => {
      if (result) {
        setAgencyName(result?.data?.name);
        setValue("name", result?.data?.name);
        setValue("description", result?.data?.description);
        setValue("timezone", result?.data?.timeZone);
        setValue("autoValidation", result?.data?.autoValidationStatus);
        setValue("zip", result?.data?.address?.zip);
        setValue("city", result?.data?.address?.city);
        setValue("state", result?.data?.address?.state);
        setValue("addressOne", result?.data?.address?.address1);
        setValue("addressLineTwo", result?.data?.address?.address2);
        setValue("addressLineThree", result?.data?.address?.address3);
        setValue("displayNext", result?.data?.displayNextTestTime);
        // setValue("parentAgency", result?.data?.parentAgencyId);
        getAgencyData(result?.data);
        if (result?.data?.websiteUsers?.length > 0) {
          dispatch(
            setWebsiteUserData(
              result.data.websiteUsers.map((row, i) => ({
                key: i + 1,
                name: row.firstName + " " + row.lastName,
                preferred: row.email,
                status: row.status,
                edits: <EditOutlined />,
              }))
            )
          );
        }
        if (result?.data?.notificationContacts?.length > 0) {
          setContact(
            result.data.notificationContacts.map((row, i) => ({
              key: i + 1,
              name: row.firstName + " " + row.lastName,
              preferred: row.email,
              role: row.role,
            }))
          );
        }

        dispatch(showLoader(false));
      }
    });
  };

  async function getAgeyList(){
    let data ="";
    await apiHandler({
      url: `${endpoint.AGENCIES}`,
      authToken,
    }).then((result) => {
      getAgencyArray(result?.data);
      setActiveId(result?.data[0]?.id);
      let selctedParentId = null;
      result.data.forEach((el,index)=>{
        if(index ==0){
          //console.log("index herrrrrrrrrrr",el);
          selctedParentId = result?.data[0]?.id;
          data = data+"&agencyId="+el.id
        }else{
          if(selctedParentId !== null){
            
              data = data+"&agencyId="+el.id
            
          }
        }
      });
      //data = result?.data[0]?.id
    });
    return data
  };

  useEffect(() => {
    if (
      !isDeviceInititalized &&
      deviceList &&
      deviceList.length > 0 &&
      activeDeviceList &&
      activeDeviceList.length > 0
    ) {
      const devices = cloneDeep(deviceList);
      devices.forEach((dvc) => {
        if (
          activeDeviceList.filter((advc) => advc.name === dvc.name).length > 0
        ) {
          dvc.checked = true;
        } else {
          dvc.checked = false;
        }
      });
      setDevice(devices);
      setDeviceInitialized(true);
    }
  }, [activeDeviceList, deviceList, isDeviceInititalized]);

  return (
    <Row>
      {isLoading ? (
        <Col md={24}>
          <StyledSpace>
            <Spin size={"large"} />
            Loading...
          </StyledSpace>
        </Col>
      ) : (
        <>
          <StyledColLeft md={4}>
            <Row>
              <Col md={24}>
                <h2 style={{ color: "#000" }}>Agency</h2>
              </Col>

              {/* <Col md={24}>
                <Checkbox onChange={onChange}>Root</Checkbox>
              </Col> */}
            </Row>

            {agencyArr.map((list, i) => (
              <>
                <Row style={{ marginTop: "0.3rem" }} key={list.id}>
                  {i == 0 ? <></> : <Col md={4}></Col>}
                  <Col md={20}>
                    <Radio
                      className="cs_check"
                      onChange={(e)=>onFilterChange(e,i)}
                      checked={actChecked == list.id}
                      value={list.id}
                    >
                      {list.name}
                    </Radio>
                  </Col>
                </Row>
              </>
            ))}

            {/* {agencyList?.id != null && agencyList?.id != undefined ?  
            (<>
              <Row>
                <Col md={24} style={{ marginTop: '1rem', textAlign: 'center'}}>
                  <Button type="primary" onClick={(e) => {handleSubmit(createAgency)(e).catch(() => {});}}>Create Child Agency</Button>
                </Col>
              </Row>
            </>): 
            (<></>)} */}
          </StyledColLeft>

          <StyledColRight md={20}>
            <StyledHeading>Open Events</StyledHeading>
            <hr />
            <Tree
              defaultExpandedKeys={["0-0-0", "0-0-1"]}
              treeData={treeData}
            />
          </StyledColRight>

          {/* alcohol consumption resolution modal */}
          <Modal
            title="Resolve Alcohol Consumption Event"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Save"
            cancelText="Cancel"
          >
            {/* {modalType.mType == "UP" ? (
              <h1>Confirmed Alcohol Consumption</h1>
            ) : (
              <h1>Not An Alcohol Consumption</h1>
            )} */}

            <h3>
              {" "}
              Are you sure you want to change the status of this alcohol
              consumption ?
            </h3>
          </Modal>
        </>
      )}
    </Row>
  );
};

export default OpenEvents;
