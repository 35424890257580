import React, { useState, useEffect,useRef } from "react";
import { Row, Col, Button, Checkbox, notification, Table } from "antd";
import { StyledModal, StyledUserHeading } from "./Profile.style";
import "./Profile.css";
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import endpoint from "api/endpoint";
import { useSelector, useDispatch } from "react-redux";
import apiHandler from "api";
import { setWebsiteUserData } from "features/agency/websiteUserSlice";
import { showWebsiteUser,editOrAdd } from "features/common/commonSlice";


const Profile = (props) => {
  const dispatch = useDispatch();
  const { userData, authToken } = useSelector((state) => state.login);
  // const [modalVisible, setModalVisible] = useState(false);
  const [isPhoto, setPhoValidate] = useState(false);
  const [sendDailyData, setSendDaily] = useState(false);
  const [userTypeList, setUserTypeList] = useState([]);
  const [alertType, setAlertType] = useState("");
  const [userAlertType, setUserAlertType] = useState([]);
  const [timezoneList, getTimeZoneList] = useState([]);
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const handleAlertCancel = () => { setAlertModalVisible(false); };
  const photoValidator = (value) => { setPhoValidate(value.target.checked) };
  const sendDaily = (value) => { setSendDaily(value.target.checked) };
  // const handleCancel = () => { dispatch(showWebsiteUser(false));dispatch(editOrAdd(false)); };
  const { register, getValues, setValue, reset, handleSubmit, formState: {errors} } = useForm();
  const { showWebModal } = useSelector((state) => state.common);
  // const editMode = true;
  // const editList = useSelector( (state) => state.editUserData.editDataObj );
  const mobRef1 = useRef();
  const mobRef2 = useRef();
  const [breath, setBreath] = useState([]);
  const [watch, setWatch] = useState([]);
  const [gps, setGps] = useState([]);
  const [univarsal, setUniversal] = useState([]);
  const [userAlertList, setUserAlertList] = useState([]);
  let dummyUserAlertList = [];
  const [alertList, setAlertList] = useState([{
    key: '1',
    name: <Checkbox>Select all</Checkbox>,
    watch: <Checkbox>Select all</Checkbox>,
    gps: <Checkbox>Select all</Checkbox>,
    filter: <Checkbox>Select all</Checkbox>,
  }]);

  useEffect(() => {
    fetchDetails();
    getEventType();
  }, [setUserTypeList]);

  useEffect(() => {
    getTimezone();
  }, [authToken]);

  const fetchDetails = () => { 
    apiHandler({
      url: endpoint.AMIN_WEBSITE_USER_TYPES,
      authToken,
    }).then((result) => {
      setUserTypeList(result.data);
    });
    // getTimezone();
  }

  const getEventType = async () =>{
    // console.log("row here");
    await apiHandler({
      url: `${endpoint.AVAILABLE_EVENT_TYPES}`,
      authToken,
    }).then( async (result) => {
      
      if(result){
        if(result?.data.length > 0){
         
          //result.data.forEach((el,i)=>{
            let breathData = [], gpsData = [], watchData = [], universalData = [];
            await result?.data.forEach(element => {
             if(element.deviceTypeName ==="GPS"){
              element.isChecked = false;
               gpsData.push(element);
               
             }
             if(element.deviceTypeName ==="Watch"){
              element.isChecked = false;
               watchData.push(element);
             }
             if(element.deviceTypeName ==="Breathalyzer"){
              element.isChecked = false;
              breathData.push(element);
             }
             if(element.deviceTypeName ===null){
              element.isChecked = false;
               universalData.push(element);
             }
           });
           setWatch(watchData);
           setUniversal(universalData);
           setGps(gpsData);
           setBreath(breathData);
           generateCheckBox();
        }
      }
    
    });
  }

  const generateCheckBox = async () =>{
    // console.log(breath,"breat here");
    let finalData = [{
      key: '1',
      name: <Checkbox  id ="selectAllBreath" name="selectAllBreath" onChange={handleSelectAll}>Select all</Checkbox>,
      watch: <Checkbox id ="selectAllWatch" name="selectAllWatch" onChange={handleSelectAll}>Select all</Checkbox>,
      gps: <Checkbox id ="selectAllGPS" name="selectAllGPS" onChange={handleSelectAll}>Select all</Checkbox>,
      filter: <Checkbox id ="selectAllFilter" name="selectAllFilter" onChange={handleSelectAll}>Select all</Checkbox>,
    }];
    let array1 = [breath.length,watch.length,gps.length,univarsal.length];
    let max_length = Math.max(...array1);
    for(let i=0; i<max_length; i++){
     let make = {};
     make["key"] = (i+2);
     make.name=  breath[i]? <Checkbox id = {breath[i].id }  onChange={(e) => selectSingleCheck("breath", e)} checked={breath[i].isChecked}>{breath[i].name}</Checkbox>: null;
     make.watch = watch[i] ?<Checkbox id = {watch[i].id }  onChange={(e) => selectSingleCheck("watch", e)} checked={watch[i].isChecked}>{watch[i].name}</Checkbox>: null;
     make.gps = gps[i]?<Checkbox id = {gps[i].id }  onChange={(e) => selectSingleCheck("gps", e)} checked={gps[i].isChecked}>{gps[i].name}</Checkbox> : null;
     make.filter = univarsal[i]? <Checkbox id = {univarsal[i].id }  onChange={(e) => selectSingleCheck("univarsal", e)} checked={univarsal[i].isChecked}>{univarsal[i].name}</Checkbox>: null;
     finalData.push(make);
    }
    
     
  // })
   setAlertList(finalData);
  }

  const getTimezone = () => {
    apiHandler({
      url: `${endpoint.AVAILABLE_TIMEZONE}`,
      authToken,
    }).then((result) => {
      getTimeZoneList(result.data);
    });
  }

  const selectSingleCheck = (value,event) =>{
    if(event.target.checked){
      if(value === "breath"){
        breath.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = true;
        });
        setBreath(breath);
      }
      if(value === "watch"){
        watch.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = true;
        });
        setWatch(watch);
      }
      if(value === "gps"){
        gps.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = true;
        });
        setGps(gps);
      }
      if(value === "univarsal"){
        univarsal.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = true;
        });
        setUniversal(univarsal);
      }

    }else{
      if(value === "breath"){
        breath.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = false;
        });
        setBreath(breath);
      }
      if(value === "watch"){
        watch.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = false;
        });
        setWatch(watch);
      }
      if(value === "gps"){
        gps.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = false;
        });
        setGps(gps);
      }
      if(value === "univarsal"){
        univarsal.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = false;
        });
        setUniversal(univarsal);
      }

    }
    
    generateCheckBox();
  }

  const handleSelectAll = event =>{
    if(event.target.checked){
      if(event.target.name==="selectAllBreath"){

        breath.map(el=>{
          el.isChecked=true;
          
        });
        setBreath(breath);
      }
      if(event.target.name==="selectAllWatch"){
        watch.map(el=>{
          el.isChecked=true;
          
        });
        setWatch(watch);
      }
      if(event.target.name==="selectAllGPS"){
        gps.map(el=>{
          el.isChecked=true;
          
        });
        setGps(gps);
      }
      if(event.target.name==="selectAllFilter"){
        univarsal.map(el=>{
          el.isChecked=true;
          
        });
        setUniversal(univarsal);
      }
    }else{
      if(event.target.name==="selectAllBreath"){
        breath.forEach(el=>{
          el.isChecked=false;
        })
      }
      if(event.target.name==="selectAllWatch"){
        watch.forEach(el=>{
          el.isChecked=false;
        })
      }
      if(event.target.name==="selectAllGPS"){
        gps.forEach(el=>{
          el.isChecked=false;
        })
      }
      if(event.target.name==="selectAllFilter"){
        univarsal.forEach(el=>{
          el.isChecked=false;
        })
      }
    }
    generateCheckBox();
  }

  const onSubmit = async () => {
    // if(editMode == false){ 
      const values = getValues(); 
      const filterName = userTypeList.filter(x => values.userType == x.id);
      if(filterName.length > 0){
        // console.log(values)
        let agency = [];
        if(userData?.agencies.length > 0){
          userData?.agencies.map((list, i) =>  {
            agency.push({id: list.id});
          })
        }
        // console.log(userAlertList);
        // console.log(dummyUserAlertList);
        await apiHandler({
          url: '/admin/user',
          method: "POST",
          data: {
            "firstName": values.firstname,
            "middleName": values.middlename,
            "lastName": values.lastname,
            "timeZone": values.timezone,
            "email": values.email,
            "notifyViaEmail": false,
            "notifyViaMobilePhoneText": true,
            "notifyViaAltPhoneText": false,
            "sendDailyReports": sendDailyData,
            "websiteUserType": {
              "id": parseInt(values.userType),
              "name": filterName[0].name
            },
            "status": values.webStatus,
            "isPhotoValidator": isPhoto,
            "agencies": agency,
            "mobilePhone": "("+values.phone1 +")-"+ values.phone2 +"-"+ values.phone3,
            "altPhone": "("+values.alphone1 +")-"+ values.alphone2 +"-"+ values.alphone3,
            "userAlertTypes":userAlertList
          },
          authToken: authToken,
        }).then(async (result) => {
          if(result?.data?.id != undefined){
            getUpdateWebsiteUser();
            dispatch(showWebsiteUser(false));
            dispatch(editOrAdd(false));
            reset();
            handleCancel();
          }
          else{
            notification.info({
              description: result?.data?.message,
              placement: "topRight",
              duration: 5,
            });
          }
        });
      }
    // }
    else{
      updateWebsite();
    }
  };

  const updateWebsite = async () => {
    const values = getValues(); 
    let agency = [];
    if(userData?.agencies.length > 0){
      userData?.agencies.map((list, i) =>  {
        agency.push({id: list.id});
      })
    }

    await apiHandler({
      url: '/admin/user',
      method: "POST",
      data: {
        "id": userData?.id,
        "firstName": values.firstname,
        "middleName": values.middlename,
        "lastName": values.lastname,
        "timeZone": values.timezone,
        "email": values.email,
        "notifyViaEmail": false,
        "notifyViaMobilePhoneText": true,
        "notifyViaAltPhoneText": false,
        "sendDailyReports": sendDailyData,
        "websiteUserType": userData?.websiteUserType,
        "status": values.webStatus,
        "isPhotoValidator": isPhoto,
        "agencies": agency,
        "mobilePhone": "("+values.phone1 +")-"+ values.phone2 +"-"+ values.phone3,
        "altPhone": "("+values.alphone1 +")-"+ values.alphone2 +"-"+ values.alphone3,
      },
      authToken: authToken,
    }).then(async (result) => {
      if(result?.data?.id != undefined){
        getUpdateWebsiteUser();
        dispatch(showWebsiteUser(false));
        reset();
      }
      else{
        notification.info({
          description: result?.data?.message,
          placement: "topRight",
          duration: 5,
        });
      }
    });
  };

  const handleAlertSave = async () =>{
    console.log("fjdfdf",breath);
    //let data = [];
   await breath.forEach(el=>{
    
      if(el.isChecked){
        console.log("el here",el);
        let alertObj = {
          eventType: {
            id: el.id
          },
          contactMethod:alertType
        }
        dummyUserAlertList.push(alertObj);
      }
    })
     watch.forEach(el=>{
      if(el.isChecked){
        let alertObj = {
          eventType: {
            id: el.id
          },
          contactMethod:alertType
        }
        dummyUserAlertList.push(alertObj);
      }
    });
     gps.forEach(el=>{
      if(el.isChecked){
        let alertObj = {
          eventType: {
            id: el.id
          },
          contactMethod:alertType
        }
        dummyUserAlertList.push(alertObj);
      }
    })
     univarsal.forEach(el=>{
      if(el.isChecked){
        let alertObj = {
          eventType: {
            id: el.id
          },
          contactMethod:alertType
        }
        dummyUserAlertList.push(alertObj);
      }
    })
    setUserAlertList(dummyUserAlertList);
    //console.log("fjdfdf",userAlertList);
    setAlertModalVisible(false);
  }

  const getUpdateWebsiteUser = async () => {
    let activeId = '';
    if(userData?.agencies.length > 0){
      activeId = userData?.agencies[0]['id'];
    }
    apiHandler({
      url: `${endpoint.ADMIN_AGENCY}/${activeId}`,
      authToken,
    }).then((result) => {
      notification.success({
        description: "Website user added successfully",
        placement: "topRight",
        duration: 5,
      });
      if(result){
        if(result?.data?.websiteUsers.length > 0){
          dispatch(setWebsiteUserData(result.data.websiteUsers.map((row,i) =>({
            key: (i+1),
            name: row.firstName +" "+row.lastName,
            preferred: row.email,
            status: row.status,
            edits: <EditOutlined />,
          }))));
        }
      }
    });
  }

  const { handleCancel } = props;
  // Alert Modal
  console.log(props,"props here");
  let columns = [];
  const setColumns = () => {
    const { deviceList } = props;
    // console.log(deviceList);
    columns = [];
    if (deviceList.isArray) {
      deviceList.forEach(dv => {
        if (dv.name === 'Breathalyzer') {
          columns.push({
            title: 'TRAC Breathalyzer',
            dataIndex: 'name',
            key: 'name',
          });
        } else if (dv.name === 'Watch') {
          columns.push({
            title: 'TRAC Watch',
            dataIndex: 'watch',
            key: 'watch',
          });
        } else if (dv.name === 'GPS') {
          columns.push({
            title: 'TRAC GPS Watch',
            dataIndex: 'gps',
            key: 'gps',
          });
        }
      });
    }
    
    columns.push({
      title: 'Universal Filters',
      dataIndex: 'filter',
      key: 'filter',
    });
  };
  setColumns();

  const doCancel = () => {
    reset();
    handleCancel()
  }

  const handleNumberChange = (e) => {
    // console.log(e);
    const { maxLength, value, id } = e.target;
    const [fieldName, fieldIndex] = id.split("-");
  
    let fieldIntIndex = parseInt(fieldIndex, 10);
  
    if (value.length >= maxLength) {
  
      if (fieldIntIndex < 3) {
  
        const nextfield = document.querySelector(
          `input[id=${fieldName}-${fieldIntIndex + 1}]`
        );
  
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };


  /* if(editMode == true){
    // fetchDetails();
    // console.log(editList);
    setValue("firstname", userData?.firstName);
    setValue("middlename", userData?.middleName);
    setValue("lastname", userData?.lastName);
    setValue("timezone", userData?.timeZone);
    setValue("email", userData?.email);
    // setValue("phone1", '');
    // setValue("phone2", '');
    // setValue("phone3", '');
    // setValue("alphone1", '');
    // setValue("alphone2", '');
    // setValue("alphone3", '');
    setValue("userType", userData?.role);
    // setValue("newpassword", editList?.firstName);
    setValue("webStatus", userData?.status);
    // setSendDaily(editList?.sendDailyReports);
    // setPhoValidate(editList?.isPhotoValidator);
  } */
  useEffect(() => {
    // if(editMode == true){
      // fetchDetails();
      // console.log(editList);
      setValue("firstname", userData?.firstName);
      setValue("middlename", userData?.middleName);
      setValue("lastname", userData?.lastName);
      setValue("timezone", userData?.timeZone);
      setValue("email", userData?.email);
      // setValue("phone1", '');
      // setValue("phone2", '');
      // setValue("phone3", '');
      // setValue("alphone1", '');
      // setValue("alphone2", '');
      // setValue("alphone3", '');
      setValue("userType", userData?.role);
      // setValue("newpassword", editList?.firstName);
      setValue("webStatus", userData?.status);
      // setSendDaily(editList?.sendDailyReports);
      // setPhoValidate(editList?.isPhotoValidator);
    // } 

  }, [userData]);

  return (
    <>
      {/* {props.showProfile && ( */}
        <StyledModal visible={props.showProfile}  width={800} onCancel={doCancel}
        title={[
          <Row>
            User Profile
          </Row>
        ]}
          footer={[
            <Button key="back" onClick={doCancel}>
              Close
            </Button>,
            
            <Button key="save" type="primary" onClick={(e) => {handleSubmit(onSubmit)(e).catch(() => {});}} className="ant-btn ant-btn-primary">
              Save
            </Button>,
          ]}
        >
          <form>
            <Row>
              <Col md={8}>
                <label><span className='required'>*</span> Name</label>
                {/* <Input placeholder="First Name" style={{ width: '95%',borderRadius: '0px' }} /> */}
                <input placeholder="Enter first name"  {...register("firstname", { required: true})} style={{ width: '95%',borderRadius: '0px' }} />
                {errors?.firstname?.type === 'required' && <span className='error'> First name is required</span>}
              </Col>
              <Col md={8}>
                <input placeholder="Enter middle name" {...register("middlename", { required: false})} style={{ width: '95%',borderRadius: '0px', marginTop: '1.3rem' }} />
              </Col>
              <Col md={8}>
                <input placeholder="Enter last name" {...register("lastname", { required: true})} style={{ width: '95%',borderRadius: '0px', marginTop: '1.3rem' }} />
                {errors?.lastname?.type === 'required' && <span className='error'> Last name is required</span>}
              </Col>
            </Row>

            <Row style={{ marginTop: '1.3rem' }}>
              <Col md={24}>
                <label><span className='required'>*</span> Time Zone</label>
                <select {...register("timezone", { required: true})}>
                  <option value="">Select time zone</option>
                  { timezoneList.map((list,i) => <option value={list} key={'id'+i}>{list}</option>) }
                </select>
                {errors?.timezone?.type === 'required' && <span className='error'> Timezone is required</span>}
              </Col>
            </Row>

            <Row style={{ marginTop: '1.3rem' }}>
              <Col md={12}>
                <label><span className='required'>*</span> Email</label>
                <input placeholder="Enter email" {...register("email", { required: true, pattern: /\S+@\S+\.\S+/ })} />
                {errors?.email?.type === 'required' && <span className='error'> Email is required</span>}
                {errors?.email?.type === 'pattern' && <span className='error'> Invalid email</span>}
              </Col>
              <Col md={10}>
                <Button type="primary" onClick={() => { setAlertModalVisible(true); setAlertType("email");getEventType();}}  style={{width: '100%',marginTop: '1.3rem',marginLeft: '1rem'}}>
                  Manage Email Phone Alerts
                </Button>
              </Col>
              <Col md={2}></Col>
            </Row>

            <Row style={{ marginTop: '1.3rem' }}>
              <Col md={24}><label>Mobile Phone</label></Col>
              <Col md={4}>
                <input placeholder="000" id={'ffd-1'} onKeyUp={(e)=> handleNumberChange(e)} maxlength="3" {...register("phone1", { pattern: /^[\d]{0,3}$/ })}  
                
                />
              </Col>
              <Col md={4} className="mg_2">
                <input placeholder="000" id={'ffd-2'}  onKeyUp={(e)=> handleNumberChange(e)} maxlength="3"  ref={mobRef1} {...register("phone2", {pattern: /^[\d]{0,3}$/ })} 
                
                />
              </Col>
              <Col md={4}>
                <input placeholder="0000" id={'ffd-3'}  onKeyUp={(e)=> handleNumberChange(e)} maxlength="4" ref={mobRef2} {...register("phone3", { pattern: /^[\d]{0,3}$/ })} />
              </Col>
              <Col md={10}>
                <Button type="primary" onClick={() => { setAlertModalVisible(true);setAlertType("mobilePhone"); getEventType();}} style={{ width: '100%', marginLeft: '1rem' }}>
                  Manage Mobile Phone Alerts
                </Button>
              </Col>
              <Col md={2}></Col>
              <Col md={24}>
                {(errors.phone1?.type === 'required' || errors.phone2?.type === 'required' || errors.phone3?.type === 'required') && <span className='error'> Phone no required</span>}
                {(errors.phone1?.type === 'pattern' || errors.phone2?.type === 'pattern' || errors.phone3?.type === 'pattern') && <span className='error'> Invalid phone number</span>}
              </Col>
            </Row>

            <Row style={{ marginTop: '1.3rem' }}>
              <Col md={24}><label> Alternative Phone</label></Col>
              <Col md={4}>
                <input placeholder="000" id={'ffld-1'} onKeyUp={(e)=> handleNumberChange(e)} maxlength="3" {...register("alphone1", { required: true, pattern: /^[\d]{0,3}$/ })} />
              </Col>
              <Col md={4} className="mg_2">
                <input placeholder="000" id={'ffld-2'} onKeyUp={(e)=> handleNumberChange(e)} maxlength="3" {...register("alphone2", { required: true, pattern: /^[\d]{0,3}$/ })} />
              </Col>
              <Col md={4}>
                <input placeholder="0000" id={'ffld-3'} onKeyUp={(e)=> handleNumberChange(e)} maxlength="4" {...register("alphone3", { required: true, pattern: /^[\d]{0,3}$/ })} />
              </Col>
              <Col md={10}>
                <Button type="primary" onClick={() => { setAlertModalVisible(true); setAlertType("altPhone");getEventType();}} style={{ width: '100%',marginLeft: '1rem' }}>
                  Manage Alternative Phone Alerts
                </Button>
              </Col>
              <Col md={2}></Col>
              <Col md={24}>
                {(errors.alphone1?.type === 'required' || errors.alphone2?.type === 'required' || errors.alphone3?.type === 'required') && <span className='error'> Phone no required</span>}
                {(errors.alphone1?.type === 'pattern' || errors.alphone2?.type === 'pattern' || errors.alphone3?.type === 'pattern') && <span className='error'> Invalid phone number</span>}
              </Col>
            </Row>

            <Row style={{ marginTop: '1.3rem' }}>
              <Col md={24} className="cs_check"><Checkbox  onChange={sendDaily}>Send Daily Breath Reports</Checkbox></Col>
            </Row>
            
            
          </form>
        </StyledModal>


        {alertModalVisible == true ?  (
        <StyledModal visible={alertModalVisible}  width={1000} footer={false} closable={false} onCancel={handleAlertCancel}
          title={[
            <Row>
              <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>Alerts Board</Col>
              <Col md={15}>
                <Button type="primary" style={{float: 'right',marginLeft:'1rem'}} onClick={handleAlertSave}>
                  Save
                </Button>
                <Button style={{float: 'right'}} onClick={handleAlertCancel}>Cancel</Button>
              </Col>
            </Row>
          ]}        
        >
          <Row>
            <Col md={24} className="cs_table">
              <Table dataSource={alertList} columns={columns} pagination={false} className="cs_check" />
            </Col>
          </Row>
        </StyledModal>): (<></>)}
        
      
    </>
  );
};

export default Profile;