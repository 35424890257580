import { Table } from "antd";
import { setSelectedEvents,setSelectedRowKeys } from "features/common/commonSlice";
import { useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import "./CustomDataTable.css";
const CustomDataTable = ({
  columns,
  data,
  position,
  showSelect,
  showPagination,
  tableSource,
  className,
  onClick
}) => {
  const dispatch = useDispatch();
 // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { selectedRowKeys } = useSelector((state) => state.common);
  const onSelectChange = (selectedRowKeys) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));
    
    let selectedNewEvents = [];
    console.log(tableSource,"data here  Source");
    if(tableSource === "events"){
      selectedRowKeys.map((key) => {
        selectedNewEvents.push(data[key].id);
      })
      console.log(selectedNewEvents,"data here  Source");
      dispatch(setSelectedEvents(selectedNewEvents));
      localStorage.setItem("selectedItem", JSON.stringify(selectedNewEvents))

      if(selectedRowKeys.length>0){
       // dispatch(setSelectedEvents([]));
      }
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      if(tableSource === "events"){
       // console.log("recordrecord",record);
        // return {
        //   disabled: record.compliantAt ==null? true: false  //disable the first 4 rows only
        // };
      }
      //const rowIndex = data.findIndex((item) => item.key === record.key);
     
    },
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <Table
      rowSelection={showSelect ? rowSelection : showSelect}
      columns={columns}
      dataSource={data}
      className={className}
      position={position}
      onrow = {onClick}
      pagination={
        showPagination
          ? {
              showSizeChanger: true,
              pageSize: 100,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }
          : false
      }
      scroll={{ y: 590 }}
    />
  );
};

export default CustomDataTable;
