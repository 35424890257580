// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pinnedNotesModal .ant-modal-content .ant-modal-close{
    display: none;
}
.pinnedNotesModal .ant-modal-content .ant-modal-header{
   text-align: center;
   border-bottom: 0;
}
.pinnedNotesModal .ant-modal-content .ant-modal-header .ant-modal-title{
    font-weight: 800;
    font-family: Aileron-Bold;
}
.pinnedNotesModal .ant-modal-content .ant-modal-footer{
    border-top: 0;
}
.pinnedNotesModal .ant-modal-content .ant-modal-footer .ant-btn:first-child{
    float: left;
    border: none;
    font-family: Bicyclette-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #000;
}
.pinnedNotesModal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary{
    border: none;
    font-family: Bicyclette-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #1890ff;
    background: none;
    border-color:none;
    text-shadow: none;
    box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/containers/ParticipantPinnedNotes/ParticipantPinnedNotesCustom.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;GACG,kBAAkB;GAClB,gBAAgB;AACnB;AACA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B;AACA;IACI,aAAa;AACjB;AACA;IACI,WAAW;IACX,YAAY;IACZ,+BAA+B;IAC/B,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;AACA;IACI,YAAY;IACZ,+BAA+B;IAC/B,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".pinnedNotesModal .ant-modal-content .ant-modal-close{\n    display: none;\n}\n.pinnedNotesModal .ant-modal-content .ant-modal-header{\n   text-align: center;\n   border-bottom: 0;\n}\n.pinnedNotesModal .ant-modal-content .ant-modal-header .ant-modal-title{\n    font-weight: 800;\n    font-family: Aileron-Bold;\n}\n.pinnedNotesModal .ant-modal-content .ant-modal-footer{\n    border-top: 0;\n}\n.pinnedNotesModal .ant-modal-content .ant-modal-footer .ant-btn:first-child{\n    float: left;\n    border: none;\n    font-family: Bicyclette-Regular;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 18px;\n    line-height: 30px;\n    color: #000;\n}\n.pinnedNotesModal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary{\n    border: none;\n    font-family: Bicyclette-Regular;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 18px;\n    line-height: 30px;\n    color: #1890ff;\n    background: none;\n    border-color:none;\n    text-shadow: none;\n    box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
