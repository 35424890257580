
import Layout from "hocs/Layout";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const ProtectedRoute = ({ children}) => {
  const loginData = useSelector((state) => state.login);
  const prevLocation = useLocation();
  const { pathname: lastRouteVisited } = useLocation();
  if (!loginData?.authToken) {
    // user is not authenticated
    return <Navigate to={{
      pathname: `/login?redirectTo=${prevLocation}`,
      state: lastRouteVisited,
    }} />;
  }
  return children;
};

/* function ProtectedRoute({ component: Component, ...rest }) {
  const loginData = useSelector((state) => state.login);
  const { pathname: lastRouteVisited } = useLocation();

  return (
    <Route
      {...rest}
      render={() => {
        return loginData?.authToken ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: lastRouteVisited,
            }}
          />
        );
      }}
    />
  );
}

export default ProtectedRoute; */
