import { createSlice } from "@reduxjs/toolkit";

export const enrollSurvivorSlice = createSlice({
  name: "enrollSurvivor",
  initialState: {
    enrollSurvivorFormData: {},
  },
  reducers: {
    saveEnrollSurvivorFormData: (state, action) => {
      state.enrollSurvivorFormData = action.payload;
    },
  },
});

export const { saveEnrollSurvivorFormData } = enrollSurvivorSlice.actions;

export default enrollSurvivorSlice.reducer;
