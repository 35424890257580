import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: { agencies: [] },
  reducers: {
    setAgencies: (state, action) => {
      state.agencies = action.payload;
    },
    showLoader: (state, action) => {
      state.showLoader = action.payload;
    },
    userType: (state, action) => {
      state.userType = action.payload;
    },
    showEnrollParticipant: (state, action) => {
      state.showEnrollParticipant = action.payload;
    },
    setEnrollParticipantData: (state, action) => {
      state.enrollParticipantData = action.payload;
    },
    setEditParticipantProfile: (state, action) => {
      state.editParticipantProfile = action.payload;
    },
    setHistoryDrawer: (state, action) => {
      state.showHistoryDrawer = action.payload;
    },
    showMessageDrawer: (state, action) => {
      state.showMessageDrawer = action.payload;
    },
    setActiveParticipant: (state, action) => {
      state.activeParticipantId = action.payload;
    },
    setActiveHistoryTab: (state, action) => {
      state.activeHistoryTab = action.payload;
    },
    setDrawerErrorMessage: (state,action) => {
      state.drawerErrorMessage = action.payload;
    },
    setSelectedEvents: (state, action) => {
      console.log("action.payload",action.payload);
      //state.selectedEvents = action.payload
      return {...state, selectedEvents: action.payload};

    },
    setHistoryStartDate: (state, action) => {
      state.historyStartDate = action.payload
    }, 
    setHistoryEndDate: (state, action) => {
      state.historyEndDate = action.payload
    },
    setAssignedDevices: (state, action) => {
      state.assignedDevices = action.payload
    },
    setButtonLoading: (state, action) => {
      state.buttonLoading = action.payload
    },
    showWebsiteUser: (state, action) => {
      state.showWebModal = action.payload;
    },
    showWebsiteContact: (state, action) => {
      state.showWebModalContact = action.payload;
    },
    editOrAdd: (state,action)=>{
      state.editMode = action.payload
    },
    showSurvivorDrawer: (state, action) => {
      state.showSurvivorDrawer = action.payload;
    },
    setExceptionErrorMessage:(state,action)=>{
      state.exceptionErrorMessage = action.payload;
    },
    setEnrollSurvivorData: (state, action) => {
      state.enrollSurvivorData = action.payload;
    },
    setSelectedRowKeys:(state,action)=>{
      state.selectedRowKeys = action.payload;
    },
    
  },
});

export const {
  setAgencies,
  showLoader,
  userType,
  showEnrollParticipant,
  setEditParticipantProfile,
  setEnrollParticipantData,
  setActiveParticipant,
  setHistoryDrawer,
  showMessageDrawer,
  setActiveHistoryTab,
  setDrawerErrorMessage,
  setSelectedEvents,
  setSelectedRowKeys,
  setAssignedDevices,
  setHistoryStartDate,
  setHistoryEndDate,
  setButtonLoading,
  showWebsiteUser,
  showWebsiteContact,
  editOrAdd,
  showSurvivorDrawer,
  setEnrollSurvivorData,
  setExceptionErrorMessage
} = commonSlice.actions;

export default commonSlice.reducer;
