// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table th,
td {
    padding: 5px;
}

.square {
    height: 20px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    display: inline-block;
    border-radius: 2px;
    cursor: pointer;
}

.bg_blue {
    background-color: #0557A2;
    border: 1px solid #0557A2;
}

.bg_orange {
    background-color: #ab0404;
    border: 1px solid #ab0404;
}

.square2 {
    height: 22px;
    width: 30px;
    background-color: #fff;
    border: 1px solid #ccc;
    display: inline-block;
    border-radius: 2px;
    cursor: pointer;
}

.bg_blue2 {
    background-color: #0557A2!important;
    border: 1px solid #0557A2!important;
}

.bg_white2 {
    background-color: #fff;
    border: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/domain/Caseload/ManageSchedule/ManageScheduleCustom.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,sBAAsB;IACtB,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,sBAAsB;IACtB,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mCAAmC;IACnC,mCAAmC;AACvC;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;AAC1B","sourcesContent":[".table th,\ntd {\n    padding: 5px;\n}\n\n.square {\n    height: 20px;\n    width: 15px;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    display: inline-block;\n    border-radius: 2px;\n    cursor: pointer;\n}\n\n.bg_blue {\n    background-color: #0557A2;\n    border: 1px solid #0557A2;\n}\n\n.bg_orange {\n    background-color: #ab0404;\n    border: 1px solid #ab0404;\n}\n\n.square2 {\n    height: 22px;\n    width: 30px;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    display: inline-block;\n    border-radius: 2px;\n    cursor: pointer;\n}\n\n.bg_blue2 {\n    background-color: #0557A2!important;\n    border: 1px solid #0557A2!important;\n}\n\n.bg_white2 {\n    background-color: #fff;\n    border: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
