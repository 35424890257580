// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ant-input-group {
      border: none!important;
      border-radius: 6px;
      margin-bottom: 10px;
}

.mt-5 {
      margin-top: 5px;
}
.mt-15 {
      margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/domain/Caseload/ManageZones/ManageZonesCustom.css"],"names":[],"mappings":";AACA;MACM,sBAAsB;MACtB,kBAAkB;MAClB,mBAAmB;AACzB;;AAEA;MACM,eAAe;AACrB;AACA;MACM,gBAAgB;AACtB","sourcesContent":["\n.ant-input-group {\n      border: none!important;\n      border-radius: 6px;\n      margin-bottom: 10px;\n}\n\n.mt-5 {\n      margin-top: 5px;\n}\n.mt-15 {\n      margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
