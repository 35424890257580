// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ant-input-group {
      border: none!important;
      border-radius: 6px;
      margin-bottom: 10px;
}

.mt-5 {
      margin-top: 5px;
}
.mt-15 {
      margin-top: 15px;
}
.selectedArea{
      display:inline-block;
      margin:20px 0;
    }
    
    .fixTableHead {
      overflow-y: auto;
      height: 400px;
  }
  
  .fixTableHead thead th {
      position: sticky;
      top: 0;
  }
  
  table {
      border-collapse: collapse;
      width: 100%;
  }
  
  th.header {
      background: rgb(176 212 244);
  }
  
  th.row,
  td.row {
      padding: 8px 15px;
      border: 1px solid rgb(64 104 143);
      ;
  }
  .tbl{
      table-layout: fixed;
      width: calc(100%* 1);
  }
  .tbl_2{
      table-layout: fixed;
      width: calc(100%* 4);
  }
  .first_col{
    position: sticky;
    left: 0;
    
  }
  .sticky-col {
    position: sticky;
    background: rgb(176 212 244);
  }`, "",{"version":3,"sources":["webpack://./src/domain/Caseload/ManageZones/ManageZonesCustom.css"],"names":[],"mappings":";AACA;MACM,sBAAsB;MACtB,kBAAkB;MAClB,mBAAmB;AACzB;;AAEA;MACM,eAAe;AACrB;AACA;MACM,gBAAgB;AACtB;AACA;MACM,oBAAoB;MACpB,aAAa;IACf;;IAEA;MACE,gBAAgB;MAChB,aAAa;EACjB;;EAEA;MACI,gBAAgB;MAChB,MAAM;EACV;;EAEA;MACI,yBAAyB;MACzB,WAAW;EACf;;EAEA;MACI,4BAA4B;EAChC;;EAEA;;MAEI,iBAAiB;MACjB,iCAAiC;;EAErC;EACA;MACI,mBAAmB;MACnB,oBAAoB;EACxB;EACA;MACI,mBAAmB;MACnB,oBAAoB;EACxB;EACA;IACE,gBAAgB;IAChB,OAAO;;EAET;EACA;IAEE,gBAAgB;IAChB,4BAA4B;EAC9B","sourcesContent":["\n.ant-input-group {\n      border: none!important;\n      border-radius: 6px;\n      margin-bottom: 10px;\n}\n\n.mt-5 {\n      margin-top: 5px;\n}\n.mt-15 {\n      margin-top: 15px;\n}\n.selectedArea{\n      display:inline-block;\n      margin:20px 0;\n    }\n    \n    .fixTableHead {\n      overflow-y: auto;\n      height: 400px;\n  }\n  \n  .fixTableHead thead th {\n      position: sticky;\n      top: 0;\n  }\n  \n  table {\n      border-collapse: collapse;\n      width: 100%;\n  }\n  \n  th.header {\n      background: rgb(176 212 244);\n  }\n  \n  th.row,\n  td.row {\n      padding: 8px 15px;\n      border: 1px solid rgb(64 104 143);\n      ;\n  }\n  .tbl{\n      table-layout: fixed;\n      width: calc(100%* 1);\n  }\n  .tbl_2{\n      table-layout: fixed;\n      width: calc(100%* 4);\n  }\n  .first_col{\n    position: sticky;\n    left: 0;\n    \n  }\n  .sticky-col {\n    position: -webkit-sticky;\n    position: sticky;\n    background: rgb(176 212 244);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
