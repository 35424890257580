// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-button {
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  margin :10px;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button-line {
  width: 30px;
  height: 2px;
  background: #000;
}

.toggle-button.ml-3 {
  margin-left: 1rem;
  width: 44px;
  height: 32px;
}

.cross {
  width: 30px;
  height: 2px;
  transition: 0.4s;
}

.cross.button-line-1 {
  transform: rotate(-45deg) translate(-9px, 6px);
  background: #000;
}

.cross.button-line-2 {
  opacity: 0;
}

.cross.button-line-3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  background: #000;
}
`, "",{"version":3,"sources":["webpack://./src/components/SideMenu/SideMenu.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,UAAU;EACV,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;;AAEA;EAEE,8CAA8C;EAC9C,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sDAAsD;EACtD,gBAAgB;AAClB","sourcesContent":[".toggle-button {\n  height: 24px;\n  width: 30px;\n  background: transparent;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  padding: 0;\n  margin :10px;\n  box-sizing: border-box;\n}\n\n.toggle-button:focus {\n  outline: none;\n}\n\n.toggle-button-line {\n  width: 30px;\n  height: 2px;\n  background: #000;\n}\n\n.toggle-button.ml-3 {\n  margin-left: 1rem;\n  width: 44px;\n  height: 32px;\n}\n\n.cross {\n  width: 30px;\n  height: 2px;\n  transition: 0.4s;\n}\n\n.cross.button-line-1 {\n  -webkit-transform: rotate(-45deg) translate(-9px, 6px);\n  transform: rotate(-45deg) translate(-9px, 6px);\n  background: #000;\n}\n\n.cross.button-line-2 {\n  opacity: 0;\n}\n\n.cross.button-line-3 {\n  -webkit-transform: rotate(45deg) translate(-8px, -8px);\n  background: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
