import { Col, Drawer, Row, Button, message, Steps, Table, Checkbox ,DatePicker} from "antd";
import { showSurvivorDrawer } from "features/common/commonSlice";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputAntd from "components/InputAntd/InputAntd";
import SelectAntd from "components/SelectAntd/SelectAntd";
import apiHandler from "api";
import endpoint from "api/endpoint";
import { saveEnrollSurvivorFormData } from "features/enrollSurvivor/enrollSurvivorSlice";
import SelectSearchAntd from "components/SelectSearchAntd/SelectSearchAntd";
import { getOffsetsTz, getUserTz } from "utils";
const SurvivorDrawer = () => {
  const dispatch = useDispatch();
  const showDrawer = useSelector((state) => state.common.showSurvivorDrawer);

  const onClose = () => {
    dispatch(showSurvivorDrawer(false));
    dispatch(
      saveEnrollSurvivorFormData({})
    );
  };
  const { authToken } = useSelector((state) => state.login);
  const [current, setCurrent] = useState(0);

  const [survivorData, setSurvivorData] = useState([]);

  const { enrollSurvivorFormData } = useSelector(
    (state) => state.enrollSurvivor
  );
  const genderList = [
    { id: "M", name: "Male" },
    { id: "F", name: "Female" },
  ];
  const requiredValues = [
    "firstname",
    "lastname",
    "mobilephone",
  ];
  const statesList = [
    { id: "AL", name: "Alabama" },
    { id: "AK", name: "Alaska" },
    { id: "AZ", name: "Arizona" },
    { id: "AR", name: "Arkansas" },
    { id: "CA", name: "California" },
    { id: "CO", name: "Colorado" },
    { id: "CT", name: "Connecticut" },
    { id: "DE", name: "Delaware" },
    { id: "DC", name: "Dist of Columbia" },
    { id: "FL", name: "Florida" },
    { id: "GA", name: "Georgia" },
    { id: "HI", name: "Hawaii" },
    { id: "ID", name: "Idaho" },
    { id: "IL", name: "Illinois" },
    { id: "IN", name: "Indiana" },
    { id: "IA", name: "Iowa" },
    { id: "KS", name: "Kansas" },
    { id: "KY", name: "Kentucky" },
    { id: "LA", name: "Louisiana" },
    { id: "ME", name: "Maine" },
    { id: "MD", name: "Maryland" },
    { id: "MA", name: "Massachusetts" },
    { id: "MI", name: "Michigan" },
    { id: "MN", name: "Minnesota" },
    { id: "MS", name: "Mississippi" },
    { id: "MO", name: "Missouri" },
    { id: "MT", name: "Montana" },
    { id: "NE", name: "Nebraska" },
    { id: "NV", name: "Nevada" },
    { id: "NH", name: "New Hampshire" },
    { id: "NJ", name: "New Jersey" },
    { id: "NM", name: "New Mexico" },
    { id: "NY", name: "New York" },
    { id: "NC", name: "North Carolina" },
    { id: "ND", name: "North Dakota" },
    { id: "OH", name: "Ohio" },
    { id: "OK", name: "Oklahoma" },
    { id: "OR", name: "Oregon" },
    { id: "PA", name: "Pennsylvania" },
    { id: "RI", name: "Rhode Island" },
    { id: "SC", name: "South Carolina" },
    { id: "SD", name: "South Dakota" },
    { id: "TN", name: "Tennessee" },
    { id: "TX", name: "Texas" },
    { id: "UT", name: "Utah" },
    { id: "VT", name: "Vermont" },
    { id: "VA", name: "Virginia" },
    { id: "WA", name: "Washington" },
    { id: "WV", name: "West Virginia" },
    { id: "WI", name: "Wisconsin" },
    { id: "WY", name: "Wyoming" },
  ];

  const [errorObj, setErrorObj] = useState({});

  // const { enrollSurvivorData } = useSelector((state) => state.common);

  const steps = [
    {
      title: 'Enroll Survivor',
      active: ''
    },
    {
      title: 'Manage Alert Notifications',
    }
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle = {
    // lineHeight: '260px',
    // textAlign: 'center',
    // marginTop: 16,
  };
  const buttonStyle = {
    position: 'fixed',
    width: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    bottom: '0px',
    borderTop: '1px solid rgb(197, 197, 197)',
    left: '0px',
    background: 'rgb(255, 255, 255)',
    gap: '8px'
  };


  const columns = [
    {
      dataIndex: 'col1',
      key: 'col1',
    },
    {
      dataIndex: 'col2',
      key: 'col2',
    }
  ];

  const dataSource = [
    {
      key: '2',
      col1: <Checkbox checked>Zone Violation (incl & excl)</Checkbox>,
      col2: <Checkbox checked>Critically Low Phone Battery</Checkbox>,
    },
    {
      key: '3',
      col1: <Checkbox checked>Critically Low TRAC GPS Watch Battery</Checkbox>,
      col2: <Checkbox checked>Location Unavailable</Checkbox>,
    },
    {
      key: '4',
      col1: <Checkbox checked>Strap Tamper</Checkbox>,
      col2: <Checkbox checked>No Recent Communication</Checkbox>,
    },
    {
      key: '4',
      col1: <Checkbox checked>TRAC GPS Watch Shutdown</Checkbox>,
      col2: <Checkbox checked>Insufficient Permissions</Checkbox>,
    },
    {
      key: '4',
      col1: <Checkbox checked>Shielding Detected</Checkbox>,
      col2: <Checkbox checked>Case Tamper</Checkbox>,
    },
  ];
  

  const fetchSurvivorData = () => { 
    apiHandler({
      url: endpoint.AVAILABLE_ZONES + '/' + `${activeParticipantId}` + '/survivors',
      authToken,
    }).then((result) => {
      setSurvivorData(result&&result.data? result.data: [ ]);
    });
  }

  const { activeParticipantId } = useSelector((state) => state.common);
  useEffect(() => {
    if (activeParticipantId) {
      fetchSurvivorData();
    }
  }, [activeParticipantId]);

  const saveSurvivor = () => {
    console.log(enrollSurvivorFormData,"enrollSurvivorFormData");
    const dataError = handleErrorChange();
    console.log("errorObj",Object.keys(dataError));
    if (dataError&& Object.keys(dataError) && Object.keys(dataError).length > 0) {
      console.log("errorObj k",Object.keys(errorObj));
      return;
    }
    let fullName = '';
    if (enrollSurvivorFormData.lastname && enrollSurvivorFormData.lastname.trim('') !== '') {
      fullName = enrollSurvivorFormData.lastname;
    }
    if (enrollSurvivorFormData.firstname) {
      fullName = fullName + ', ' + enrollSurvivorFormData.firstname;
    }
    if (enrollSurvivorFormData.middlename&& enrollSurvivorFormData.middlename.trim('') !== '') {
      fullName = fullName + ' ' + enrollSurvivorFormData.middlename;
    }
    
    const data = [{
      fullName: fullName,
      mobilePhone: enrollSurvivorFormData.mobilephone,
      address: {
        address1: enrollSurvivorFormData.address1,
        address2: enrollSurvivorFormData['apt/suite'],
        address3: '',
        city: enrollSurvivorFormData.city,
        state: enrollSurvivorFormData.state,
        zip: enrollSurvivorFormData.zipcode
      },
      timeZone: /* 'US/Central' */enrollSurvivorFormData.timezone,
      radius: 100,
      uninstallEnabled: true
     }]
     
    apiHandler({
      url: endpoint.AVAILABLE_ZONES + '/' + `${activeParticipantId}` + '/survivors',
      authToken,
      method: 'POST',
      data: data
    }).then((result) => {
      console.log(result,"result here");
      if(result.data.entity){
        message.success(result.data.message)
        next();
      }else{
        message.error(result.data.message);
      }
      
      // onClose()
    });
  }

  const handleChange = (val, attr) => {
    console.log(val,attr,"change here");
    enrollSurvivorFormData[attr] = val;
    dispatch(
      
      saveEnrollSurvivorFormData({
        ...enrollSurvivorFormData
      })
    );
  }

  function hasNumber(text) {
    return /\d/.test(text);
  }

  const handleErrorChange = () => {
    console.log(enrollSurvivorFormData,"j");
    const errorData = {};
    requiredValues.map((index)=>{
      if(index === "firstname" && !enrollSurvivorFormData['firstname']){
        errorData['firstname'] = 'This is required field';
        errorData['showFNameError'] = true;
      }
       if(index === "lastname" && !enrollSurvivorFormData['lastname']){
        errorData['lastname'] = 'This is required field';
        errorData['showLNameError'] = true;
      }
      if(index === "mobilephone" && !enrollSurvivorFormData['mobilephone']){
        errorData['mobilephone'] = 'This is required field';
        errorData['showMobileError'] = true;
      }
    })
    if(Object.keys(enrollSurvivorFormData).length !== 0){
      const formkeys = Object.keys(enrollSurvivorFormData);
    if (formkeys&&formkeys.filter(obj => obj === 'firstname').length > 0) {
      errorData['showFNameError'] = true;
    }
    if (!enrollSurvivorFormData['firstname'] || (enrollSurvivorFormData['firstname']+'').trim().length < 2) {
      if (hasNumber((enrollSurvivorFormData['firstname']+'').trim())) {
        errorData['firstname'] = 'First name must be at least 2 characters and cannot contain numbers';
      } else {
        errorData['firstname'] = 'First name must be at least 2 characters';
      }
    } else if (hasNumber((enrollSurvivorFormData['firstname']+'').trim())) {
      errorData['firstname'] = 'First name cannot contain numbers';
    } else {
      if (errorData['firstname']) {
        delete errorData['firstname'];
      }
      if (errorData['showFNameError']) {
        delete errorData['showFNameError'];
      }
    }


    if (formkeys&&formkeys.filter(obj => obj === 'lastname').length > 0) {
      errorData['showLNameError'] = true;
    }
    if (!enrollSurvivorFormData['lastname'] || (enrollSurvivorFormData['lastname']+'').trim().length < 2) {
      if (hasNumber((enrollSurvivorFormData['lastname']+'').trim())) {
        errorData['lastname'] = 'Last name must be at least 2 characters and cannot contain numbers';
      } else {
        errorData['lastname'] = 'Last name must be at least 2 characters';
      }
    } else if (hasNumber((enrollSurvivorFormData['lastname']+'').trim())) {
      errorData['lastname'] = 'Last name cannot contain numbers';
    } else {
      if (errorData['lastname']) {
        delete errorData['lastname'];
      }
      if (errorData['showLNameError']) {
        delete errorData['showLNameError'];
      }
    }


    /////////////
    if (formkeys&&formkeys.filter(obj => obj === 'mobilephone').length > 0) {
      errorData['showMobileError'] = true;
    }
    if (!enrollSurvivorFormData["mobilephone"]) {
      errorData["mobilephone"] = "This is required field";
    } else {
      if (
        enrollSurvivorFormData["mobilephone"] &&
        (enrollSurvivorFormData["mobilephone"].length > 10 ||
        enrollSurvivorFormData["mobilephone"].length < 10)
      ) {
        errorData["mobilephone"] = "Mobile Phone is invalid";
      } else {
        if (errorData['showMobileError']) {
          delete errorData["showMobileError"];
        }
        if (errorData['mobilephone']) {
          delete errorData["mobilephone"];
        }
      }
    }

    }
    

    // if (formkeys&&formkeys.filter(obj => obj === 'middlename').length > 0) {
    //   errorData['showMNameError'] = true;
    // }
    // if (!enrollSurvivorFormData['middlename'] || hasNumber((enrollSurvivorFormData['middlename']+'').trim())) {
    //   errorData['middlename'] = 'Middle name cannot contain numbers.';
    // } else {
    //   if (errorData['middlename']) {
    //     delete errorData['middlename'];
    //   }
    //   if (errorData['showMNameError']) {
    //     delete errorData['showMNameError'];
    //   }
    // }

    setErrorObj(errorData);
    return errorData;
  } 
  
  useEffect(() => {
    if (Object.keys(enrollSurvivorFormData).length !== 0) {
      console.log("enrollSurvivorFormData",enrollSurvivorFormData);
      handleErrorChange();
    }
  }, [enrollSurvivorFormData]);


  return (
    <Drawer
      destroyOnClose
      title="Enrollment Wizard"
      placement="right"
      size="500"
      onClose={onClose}
      visible={showDrawer}
    >
      <div className="container" style={{ marginBottom: '3rem' }}>
        <Col md={10}>
          <div className="ant-steps ant-steps-horizontal ant-steps-label-horizontal">
            <div className="ant-steps-item ant-steps-item-process ant-steps-item-active">
              <div role="button" tabindex="0" className="ant-steps-item-container">
                <div className="ant-steps-item-tail"></div>
                <div className="ant-steps-item-icon"><span className="ant-steps-icon">1</span></div>
                <div className="ant-steps-item-content">
                  <div className="ant-steps-item-title">Enroll Survivor</div>
                </div>
              </div>
            </div>

            <div className="ant-steps-item ant-steps-item-wait ant-steps-item-active">
              <div className="ant-steps-item-container">
                <div className="ant-steps-item-tail"></div>
                <div className="ant-steps-item-icon"><span className="ant-steps-icon">2</span></div>
                <div className="ant-steps-item-content">
                  <div className="ant-steps-item-title">Manage Alert Notifications</div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>

      <div className="container">
        {steps[current].title == 'Enroll Survivor' && (
          <Row>
            <Col md={24}>
              <Steps current={current} items={items} />

              <div style={contentStyle}>
                {/* {steps[current].content} */}

                <Row>
                  <Col md={8}>
                    <h2>Enroll Survivor</h2>
                  </Col>
                </Row>


          
                <Row>
                  <Col md={5}>
                    <InputAntd
                      label="First Name"
                      required={true}
                      placeholder="First Name"
                      inputWidth="300px"
                      formSource="enrollSurvivor"
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.firstname) ||
                        ""
                      }
                    />
                    {(errorObj && errorObj["firstname"] && errorObj['showFNameError']) && 
                      <p style={{ color: "red"}}
                    >
                        {errorObj["firstname"]}
                      </p>
                    }
                  </Col>
                  <Col md={1}></Col>
                  <Col md={5}>
                    <InputAntd
                      label="Middle Name"
                      placeholder="Middle Name"
                      inputWidth="300px"
                      formSource="enrollSurvivor"
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.middlename) ||
                        ""
                      }
                    />
                    {(errorObj && errorObj["middlename"] && errorObj['showMNameError']) && 
                      <p style={{ color: "red"}}
                    >
                        {errorObj["middlename"]}
                      </p>
                    }
                  </Col>
                  <Col md={1}></Col>
                  <Col md={5}>
                    <InputAntd
                      label="Last Name"
                      placeholder="Last Name"
                      required={true}
                      inputWidth="300px"
                      formSource="enrollSurvivor"
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.lastname) ||
                        ""
                      }
                    />
                    {(errorObj && errorObj["lastname"] && errorObj['showLNameError']) && 
                      <p style={{ color: "red"}}
                    >
                        {errorObj["lastname"]}
                      </p>
                    }
                    
                  </Col>
                  <Col md={1}></Col>
                  <Col md={5}>
                    <InputAntd
                      label="Mobile Phone"
                      required={true}
                      placeholder="Mobile Phone"
                      inputWidth="300px"
                      formSource="enrollSurvivor"
                      //onChange={handleErrorChange}
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.mobilephone) ||
                        ""
                      }
                      className="cs_h"  />
                       {(errorObj && errorObj["mobilephone"] && errorObj['showMobileError']) && 
                      <p style={{ color: "red"}}
                    >
                        {errorObj["mobilephone"]}
                      </p>
                    }
                  </Col>
                  <Col md={1}></Col>
                  </Row>
                <Row>
                  <Col md={5}>
                    <InputAntd
                      label="Alert Distance"
                      inputWidth="300px"
                      formSource="enrollSurvivor"
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.alertdistance) ||
                        ""
                      }
                    />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={5}>
                    <InputAntd
                      label="Phone Make/Model"
                      placeholder="Phone Make/Model"
                      inputWidth="300px"
                    />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={5}>
                  <SelectSearchAntd
                    label="Time Zone"
                    optionsArr={getOffsetsTz()}
                    defaultValue={getUserTz()}
                    width="300px"
                    
                  />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={5}>
                    <InputAntd
                      label="Email"
                      placeholder="Email"
                      inputWidth="300px"
                    />
                  </Col>
                  <Col md={1}></Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <InputAntd
                      label="Address 1"
                      placeholder="Address"
                      inputWidth="250px"
                      formSource="enrollSurvivor"
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.address1) ||
                        ""
                      }
                    />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <InputAntd
                      label="Apt/Suite"
                      placeholder="Apt/Suite"
                      inputWidth="250px"
                      formSource="enrollSurvivor"
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.address2) ||
                        ""
                      }
                    />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <InputAntd
                      label="City"
                      placeholder="City"
                      inputWidth="250px"
                      formSource="enrollSurvivor"
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.city) ||
                        ""
                      }
                    />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={4}>

                  <SelectAntd
                    label="State"
                    placeholder="Select State"
                    optionsArr={statesList}
                    width="250px"
                      formSource="enrollSurvivor"
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.state) ||
                        ""
                      }
                    // hideLabel={true}
                  />
                  
                  </Col>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <InputAntd
                      label="Zip Code"
                      placeholder="Zip Code"
                      inputWidth="250px"
                      formSource="enrollSurvivor"
                      value={
                        (enrollSurvivorFormData &&
                          enrollSurvivorFormData?.zipcode) ||
                        ""
                      }
                    />
                  </Col>
                  <Col md={1}></Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <InputAntd
                      label="Occupation"
                      placeholder="Occupation"
                      inputWidth="250px"
                    />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={4}>
                  <div value="" class="ant-row ant-form-item" >
              <div class="ant-col ant-col-32 ant-form-item-label">
                <label for="basic_height" class="" title="Height">Date of Birth:</label>
              </div>
              <div class="ant-col ant-form-item-control">
                <div class="ant-form-item-control-input">
                  <div class="ant-form-item-control-input-content">
                  <DatePicker
              label="Birthdate*"
              style={{
                width: "250px",
                marginTop: "5px",
              }}
              
    
            />
                  </div>
                </div>
              </div>
            </div>

                  </Col>
                  <Col md={1}></Col>
                  <Col md={4}>
                  <SelectAntd
                    label="Gender"
                    placeholder="Select Gender"
                    optionsArr={genderList}
                    width="250px"
                    pageKey="enroll"
                  />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <InputAntd
                      label="Height"
                      placeholder="Height"
                      inputWidth="250px"
                    />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={4}>
                    <InputAntd
                      label="Weight"
                      placeholder="Weight"
                      inputWidth="250px"
                    />
                  </Col>
                  <Col md={1}></Col>
                  
                  {/* <Col md={5}>
                    <SelectAntd
                      label="Gender"
                      placeholder="Select Gender"
                      width="250px"
                      pageKey="enroll"
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col md={5}>
                    <InputAntd
                      label="Ethnicity"
                      placeholder="Ethnicity"
                      inputWidth="250px"
                    />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={5}>
                    <InputAntd
                      label="Eye Color"
                      placeholder="Eye Color"
                      inputWidth="250px"
                    />
                  </Col>
                  <Col md={1}></Col>
                  <Col md={5}>
                    <InputAntd
                      label="Hair Color"
                      placeholder="Hair Color"
                      inputWidth="250px"
                    />
                  </Col>
                  <Col md={7}></Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}

        {steps[current].title == 'Manage Alert Notifications' && (
          <Row>
            <Col md={8}>
              <h2>Manage Alert Notifications</h2>
            </Col>

            <Col md={24} className="cs_table">
              <Checkbox>Select All</Checkbox>
              <Table dataSource={dataSource} columns={columns} pagination={false} className="cs_check" />
            </Col>
          </Row>
        )}
      </div>

      <div className="container" style={buttonStyle}>
        <div className="ant-space-item">
          <button type="button" style={{ marginRight: '1rem' }} onClick={onClose}className="ant-btn"><span>Cancel</span></button>
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}

          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Processing complete!')}>
              Done
            </Button>
          )}

          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => saveSurvivor()}>
              Next
            </Button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default SurvivorDrawer;
