// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bdr-cus {
    border: 1px solid #cecece;
}

.chart-empty-con {
    min-height: 15.4rem;
    text-align: center;
    vertical-align: middle;
    padding-top: 7rem;
    font-weight: 600;
}

.pb-20-cus {
    padding-bottom: 20 px;
}`, "",{"version":3,"sources":["webpack://./src/domain/Caseload/History/MetricsChart.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".bdr-cus {\n    border: 1px solid #cecece;\n}\n\n.chart-empty-con {\n    min-height: 15.4rem;\n    text-align: center;\n    vertical-align: middle;\n    padding-top: 7rem;\n    font-weight: 600;\n}\n\n.pb-20-cus {\n    padding-bottom: 20 px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
