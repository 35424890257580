import { Tabs, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import ParticipantProfile from "../ParticipantProfile/ParticipantProfile";
import "./CaseloadTabsCustom.css";
import { setActiveParticipant } from "features/common/commonSlice";
import { useEffect, useState } from "react";

const { TabPane } = Tabs;

const CaseloadTabs = () => {
  let [dataObj, setDataObj] = useState();

  const dispatch = useDispatch();

  const caseloadData = useSelector(
    (state) => state.caseloadData.caseloadDataObj
  );

  const { searchDataObj, topSearchQueryValue } = useSelector(
    (state) => state.searchData
  );
  const [itemsList, setItemList] = useState([]);
  const { activeParticipantId } = useSelector((state) => state.common);

  const handleChange = (participantId) => {
    //console.log("work here active",participantId);
    dispatch(setActiveParticipant(participantId));
  };

 
  useEffect(()=>{
    let sourceObj = [];
    
    
    if (topSearchQueryValue) {
      console.log("work here active 3",activeParticipantId);
      setDataObj(searchDataObj);
      if(searchDataObj?.length > 0){
        searchDataObj.map(element => {
          const obj = {
            key: element.id,
            label: element.fullName,
            children: <ParticipantProfile />,
          }
          sourceObj.push(obj);
        });
        setItemList(sourceObj);
        if(activeParticipantId){
          dispatch(setActiveParticipant(activeParticipantId))
        }else {
          dispatch(setActiveParticipant(searchDataObj[0].id))
        }
       
      }
    } else {
      caseloadData.map(element => {
        const obj = {
          key: element.id,
          label: element.fullName,
          children: <ParticipantProfile />,
        }
        sourceObj.push(obj);
      });
      setItemList(sourceObj);
      setDataObj(caseloadData);
    }
  }, [topSearchQueryValue,activeParticipantId])
   


  if (dataObj?.length === 0)
    return (
      <Space>
        <h2>No Data Found</h2>
      </Space>
    );

  return (
    <Tabs
      //defaultActiveKey={activeParticipantId}
      activeKey={activeParticipantId ? activeParticipantId : dataObj[0].id}
      tabPosition="left"
      onChange={(e) => handleChange(e)}
      destroyInactiveTabPane
      items={itemsList} 
   />
    
  );
};

export default CaseloadTabs;
