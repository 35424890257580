// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-tabs{
      border-top: 1px solid #c4c4c4;
}

.ant-tabs-tab.ant-tabs-tab-active{
      background: #c4c4c4;
}

.ant-tabs-tab-btn{
      color: #000 !important;
}

.ant-tabs-tab:hover{
      color: #000 !important;
}

.ant-tabs-tab{
      font-weight: 600;
}

.ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder{
      margin-left: 15px;
      border-left: 1px solid #c4c4c4;
      padding: 20px 0;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
      padding: 0;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane{
      padding-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/domain/Caseload/CaseloadTabs/CaseloadTabsCustom.css"],"names":[],"mappings":"AAAA;MACM,6BAA6B;AACnC;;AAEA;MACM,mBAAmB;AACzB;;AAEA;MACM,sBAAsB;AAC5B;;AAEA;MACM,sBAAsB;AAC5B;;AAEA;MACM,gBAAgB;AACtB;;AAEA;MACM,iBAAiB;MACjB,8BAA8B;MAC9B,eAAe;AACrB;;AAEA;MACM,UAAU;AAChB;;AAEA;MACM,kBAAkB;AACxB","sourcesContent":[".ant-tabs{\n      border-top: 1px solid #c4c4c4;\n}\n\n.ant-tabs-tab.ant-tabs-tab-active{\n      background: #c4c4c4;\n}\n\n.ant-tabs-tab-btn{\n      color: #000 !important;\n}\n\n.ant-tabs-tab:hover{\n      color: #000 !important;\n}\n\n.ant-tabs-tab{\n      font-weight: 600;\n}\n\n.ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder{\n      margin-left: 15px;\n      border-left: 1px solid #c4c4c4;\n      padding: 20px 0;\n}\n\n.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{\n      padding: 0;\n}\n\n.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane{\n      padding-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
