import { Button, Row, Col, Space, notification, Spin, Input, Checkbox, Switch, Table, Radio } from "antd";
import apiHandler from "api";
import endpoint from "api/endpoint";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
    StyledColLeft,
    StyledColRight,
    StyledHeading,
    StyledSubHeading,
    ActiveDevicesHeading,
    DeviceList
  } from "./Reports.styles";
import { showLoader } from "features/common/commonSlice";
export const StyledSpace = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45vh;
  font-size: 20px;
  font-weight: bold;
`;

const Reports = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.common.showLoader);
    const [actChecked, setActiveId] = useState('');
    const [webloadData, setwebloadData] = useState({});
    useEffect(() => {
        dispatch(showLoader(true));
        setActiveId(1);
        dispatch(showLoader(false));
      }, []);
    const reportsType = [
        {
            id:1,
            name:"Breath Voilation Data"
        },
        {
            id:2,
            name:"Watch Voilation Data"
        },
        {
            id:3,
            name:"GPS Voilation Data"
        }
    ];
    const dataSource = [
        {
          key: '1',
          date: "",
          time: "",
          type: "",
          reading: "",
        }
      ];
    const websiteColumn = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: 'Time',
          dataIndex: 'time',
          key: 'time',
        },
        {
          title: 'Violation Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Reading',
          dataIndex: 'reading',
          key: 'reading',
        }
      ];
    const onFilterChange = (e) => {
        setActiveId(e.target.value);
      };
    return (
        <Row>
                  {isLoading ? (
        <Col md={24}>
          <StyledSpace>
            <Spin size={"large"} />
            Loading...
          </StyledSpace>
      </Col>
      ) : (
        <>
          <StyledColLeft md={4}>
            <Row>
              <Col md={24}>
                <h2 style={{ color: '#000'}}>Reports Type</h2>
              </Col>
            
              {/* <Col md={24}>
                <Checkbox onChange={onChange}>Root</Checkbox>
              </Col> */}
            </Row>

            { reportsType.map((list, i) => 
              <>
                <Row style={{ marginTop: '0.3rem'}} key={list.id}>
                  <Col md={20}>
                    <Radio className="cs_check" onChange={onFilterChange} checked={actChecked == list.id} value={list.id}>{list.name}</Radio>
                  </Col>
                </Row>
              </>
            )}
          </StyledColLeft>
          <StyledColRight md={20}>
            {/* Website table */}
            
            {actChecked == 1 ? (
                
            <Row>
                <StyledSubHeading style={{marginTop: '4rem'}}>Breath Voilation Data</StyledSubHeading>
                <Col md={24} className="cs_table">
                    <Table dataSource={dataSource} columns={websiteColumn} pagination={false} className="cs_check" />
                </Col>
            </Row>
         ) : (<></>)}
          {actChecked == 2 ? (
        <Row>
            <StyledSubHeading style={{marginTop: '4rem'}}>Watch Voilation Data</StyledSubHeading>
          <Col md={24} className="cs_table">
            <Table dataSource={dataSource} columns={websiteColumn} pagination={false} className="cs_check" />
          </Col>
        </Row>
         ) : (<></>)}
          {actChecked == 3 ? (
        <Row>
            <StyledSubHeading style={{marginTop: '4rem'}}>GPS Voilation Data</StyledSubHeading>
          <Col md={24} className="cs_table">
            <Table dataSource={dataSource} columns={websiteColumn} pagination={false} className="cs_check" />
          </Col>
        </Row>
         ) : (<></>)}
          </StyledColRight>
          </>
          )}
        </Row>
    );
}
export default Reports;