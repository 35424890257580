import { Input, Select } from "antd";
import {
  setSearchData,
  setTopSearchQueryValue,
} from "features/caseload/searchSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./TopSearch.css";
import { setActiveParticipant } from "features/common/commonSlice";
const { Search } = Input;
const { Option } = Select;

const TopSearch = () => {
  const dispatch = useDispatch();
  let [searchPreset, setSearchPreset] = useState("All");

  const selectPreset = (value) => {
    setSearchPreset(value);
  };

  const suffix = (
    <Select
      defaultValue="All"
      onChange={selectPreset}
      className="select-before"
    >
      <Option value="All">All</Option>
      <Option value="fullName">Name</Option>
      <Option value="caseNumber">Case Number</Option>
      <Option value="mobilePhoneUnformatted">Phone Number</Option>
      <Option value="serialNumber">Serial Number</Option>
    </Select>
  );

  const caseloadData = useSelector(
    (state) => state.caseloadData.caseloadDataObj
  );

  const handleSearch = (searchQueryString) => {
    if (searchQueryString.length === 0) {
      dispatch(setTopSearchQueryValue(""));
    } else {
      dispatch(setTopSearchQueryValue(searchQueryString));
    }
    searchInCaseload(searchQueryString);
  };

  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   if (value.length === 0) {
  //     dispatch(setTopSearchQueryValue(""));
  //   } else {
  //     dispatch(setTopSearchQueryValue(value));
  //   }
  //   searchInCaseload(value);
  // };

  const searchInCaseload = (searchQueryString) => {
    let searchResultsArr = [];

    if(caseloadData.length > 0) {
      const userDataId = [];
      searchResultsArr = caseloadData.filter((data) => {
        if (searchPreset === "All") {
          console.log("search preset", searchPreset);
          return Object.keys(data).some((newItem) => {
            const item = data[newItem]?.toString().toLowerCase().replace(/\s/g, '');
            const searchQuery = searchQueryString.toLowerCase().replace(/\s/g, '');
            if(item?.includes(searchQuery)) {
              userDataId.push(data.id)
              return item.includes(searchQuery);
            }
            if(userDataId.length > 0) {
              dispatch(setActiveParticipant(userDataId[0]))
            }
          });
        } else {
         
          dispatch(setActiveParticipant(null))
          if (searchPreset === "fullName") {
              const item = data["fullName"]?.toString().toLowerCase().replace(/\s/g, '');
              const searchQuery = searchQueryString.toLowerCase().replace(/\s/g, '');
              if(item?.includes(searchQuery)) {
                userDataId.push(data.id)
                return item.includes(searchQuery);
              }
              if(userDataId.length > 0) {
                dispatch(setActiveParticipant(userDataId[0]))
              }
          } else {
            if(searchPreset === "serialNumber"){
              const assignedDevices = data['assignedDevices'];
              return assignedDevices.some( device => device['device']['serialNumber']?.toString().toLowerCase() ===  searchQueryString.toLowerCase())
            }
            else{
              return (
                data[searchPreset]?.toString().toLowerCase() ===
                searchQueryString.toLowerCase()
              );
            }
            
          }
        }
      });
    }

    dispatch(setSearchData(searchResultsArr));
  };

  return (
    <div className="topSearch">
      <Search
        // value={topSearchQueryValue}
        placeholder="Search"
        suffix={suffix}
        onSearch={handleSearch}
        // onChange={handleChange}
        enterButton
      />
    </div>
  );
};

export default TopSearch;
