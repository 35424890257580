// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anticon svg{
      color: #0557a2;
      font-size: 18px;
}

/* .ant-btn-primary, .ant-btn-primary:hover{ */
      /* background-color: #fff;
      border-left: none;
      padding-top: 5px !important; */
/* } */

.ant-input-group{
      border: 1px solid #0557a2;
      border-radius: 6px;
      margin-bottom: 10px;
}

.ant-input-group-wrapper{
      width : 90%;
}

.ant-select-arrow svg{
      color: #0557a2 !important;
}

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border: 1px solid #0557a2 !important;
      border-radius: 5px;
} */

ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #c4c4c4;
}`, "",{"version":3,"sources":["webpack://./src/components/FilterTreeGroup/FilterTreeGroupCustom.css"],"names":[],"mappings":"AAAA;MACM,cAAc;MACd,eAAe;AACrB;;AAEA,8CAA8C;MACxC;;oCAE8B;AACpC,MAAM;;AAEN;MACM,yBAAyB;MACzB,kBAAkB;MAClB,mBAAmB;AACzB;;AAEA;MACM,WAAW;AACjB;;AAEA;MACM,yBAAyB;AAC/B;;AAEA;;;GAGG;;AAEH;MACM,qBAAqB;AAC3B","sourcesContent":[".anticon svg{\n      color: #0557a2;\n      font-size: 18px;\n}\n\n/* .ant-btn-primary, .ant-btn-primary:hover{ */\n      /* background-color: #fff;\n      border-left: none;\n      padding-top: 5px !important; */\n/* } */\n\n.ant-input-group{\n      border: 1px solid #0557a2;\n      border-radius: 6px;\n      margin-bottom: 10px;\n}\n\n.ant-input-group-wrapper{\n      width : 90%;\n}\n\n.ant-select-arrow svg{\n      color: #0557a2 !important;\n}\n\n/* .ant-select:not(.ant-select-customize-input) .ant-select-selector{\n      border: 1px solid #0557a2 !important;\n      border-radius: 5px;\n} */\n\nant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {\n      border-color: #c4c4c4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
