import { StyledStarIcon } from "components/CustomDataTable/CustomDataTable.style";
import CustomDataTable from "../../../components/CustomDataTable/CustomDataTable";
import ExceptionHandle from "containers/ExceptionHandle/ExceptionHandle";
import pinnedSvg from "assets/caseload/pinned.svg";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import apiHandler from "api";
import endpoint from "api/endpoint";
import moment from "moment";
import unPinnedSvg from "assets/caseload/notPinned.svg";
import { Row, DatePicker, Col, notification } from "antd";
import styled from "styled-components";
import {
  setSelectedEvents,
  setSelectedRowKeys,
  setExceptionErrorMessage,
} from "features/common/commonSlice";
import generatePDF,{ usePDF } from "react-to-pdf";
import Compliance from "assets/compliance.png";
import Nonompliance from "assets/noncompliance.png.png";
import React from "react";
import './events.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { values } from "lodash";
const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const ReadButton = styled.button`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  margin-bottom: 10px;
  color: #000;
  padding: 5px 16px;
  cursor: pointer;
`;
export const DownloadButton = styled.button`
  background: rgb(5, 87, 162);
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
  padding: 5px 16px;
  cursor: pointer;
  float: right;
`;

export const options = {
  bezierCurve: false,
  borderWidth: 1,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      ticks: {
        // Maximum rotation angle for tick labels
        maxRotation: 90,
        // Minimum rotation angle for tick labels
        minRotation: 90,
      },
    },
    y: {
      ticks: {
        padding: 7,
      },
      // beginAtZero: true
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const Events = () => {
  const { authToken } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const startDate = moment().subtract(12, "months").format("YYYY-MM-DD");
  const [eventData, setEventData] = useState();
  const [deviceNumber, setDeviceNum] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [positiveBrac, setpositiveBrac] = useState(false);
  const [showError, setShowError] = useState(false);
  const { toPDF, targetRef } = usePDF();
  const [startDateAlco, setstartDateAlco] = useState("");
  const [endDateAlco, setendDateAlco] = useState("");
  const [dates, setDates] = useState(null);
  const [peakData, setPeakData] = useState("");
  const [labels, setlabels] = useState([]);
  const [alcoholData, setAlcoholData] = useState([]);
  const [noncomplianceData, setnoncomplianceData] = useState([]);
  const [allnonData, setAllNonData] = useState([]);

  //const labels = ['Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM','Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM','Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM','Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM','Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM'];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: alcoholData,
        borderColor: "rgb(87, 75, 128)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        lineTension: 0.8,
      },
    ],
  };
  let reportStartDate = null;
  let reportEndDate = null;
  const activeHistoryTab = useSelector(
    (state) => state.common.activeHistoryTab
  );
  const activeParticipantId = useSelector(
    (state) => state.common.activeParticipantId
  );
  const { participantProfileData } = useSelector(
    (state) => state.participantProfileData
  );

  const { selectedEvents } = useSelector((state) => state.common);
  const [display, setDisplay] = useState("none");
  // const showLoader = useSelector((state) => state.showLoader);

  const pinEvent = (eventId, isPinned) => {
    // console.log('pin event', isPinned);
    apiHandler({
      url: endpoint.COMPLIANCE_EVENT,
      method: "POST",
      data: {
        isPinned,
        id: eventId,
      },
      authToken,
    }).then(async (result) => {
      const eventsData = eventData?.map((obj) => {
        if (obj.id === eventId) {
          obj.isPinned = !obj.isPinned;
        }
        return obj;
      });
      setEventData(eventsData);
    });
  };
  
  const setStartDate = (value) => {
    if(value){
      setDates(value);
      value[0] ? reportStartDate = value[0] : reportStartDate = null;
      value[1] ? reportEndDate = value[1] : reportEndDate = null;
      if(reportStartDate && reportEndDate) {
        getReportData(reportStartDate, reportEndDate, true);
      }
    } else {
      setDates(value);
      reportStartDate = null;
      reportEndDate = null;
    }
   
  }

  const getEvents = async () => {
    apiHandler({
      url:
        endpoint.ENROLL_PARTICIPANT +
        "/" +
        activeParticipantId +
        "/events/" +
        startDate,
      authToken,
    }).then(async (result) => {
      console.log("enroll participant evnts : ", result.data);
      if (!result) return;
      // getting the last seven days event.
      let newEvents = await result.data?.map((obj) => {
        if (!obj.isRead) {
          return obj;
        } else {
          return "";
        }
      });

      // removing empty strings and only storing usable objects.
      newEvents = newEvents?.filter((obj) => {
        if (obj) return obj;
      });

      let data = [];
      //preparing the data objects for the table.
      data = newEvents?.map((obj, index) => {
        return {
          key: index,
          id: obj.id,
          eventType: obj.eventType.name,
          device: obj?.device?.serialNumber,
          eventStatus: obj?.eventManualResolutionStatusName == "Not an"?'Not an alcohol consumption':obj?.eventManualResolutionStatusName,
          isPinned: (
            <StyledStarIcon
              onClick={() => {
                pinEvent(obj.id, !obj.isPinned);
              }}
              key={index}
              src={obj.isPinned ? pinnedSvg : unPinnedSvg}
            ></StyledStarIcon>
          ),
          scheduled: (
            <>
              <p>{moment(obj.createdAt).format("MMM DD, YYYY hh:mm A")}</p>
              {/* <p>{moment(obj.receivedAt).format("MMM D, YYYY h:mm A z")}</p> */}
            </>
          ),
          compliant: (
            <>
              <p>
                {obj.compliantAt &&
                  moment(obj.compliantAt).format("MMM DD, YYYY hh:mm A")}
              </p>
            </>
          ),
          notificationTime: (
            <>
              <p>
                {obj.notifiedSubscribersCreatedAt &&
                  moment(obj.notifiedSubscribersCreatedAt).format("MMM DD, YYYY hh:mm A")}
              </p>
            </>
          ),
          updatedAt: (
            <>
              <p>{moment(obj.updatedAt).format("MMM DD, YYYY hh:mm A")}</p>
            </>
          ),
          status: obj.eventStatus,
        };
      });

      setEventsList(data);
    });
    dispatch(setSelectedEvents());
  };

  const columns = [
    {
      title: "Pin",
      dataIndex: "isPinned",
      width: "50px"
     
    },
    {
      title: "Device",
      dataIndex: "device",
      width: "100px"
     
    },
    {
      title: "Event Type",
      dataIndex: "eventType",
       width: "150px"

    },
    {
      title: "Event Status",
      dataIndex: "eventStatus",
        width: "110px"
    
    },
    
    {
      title: "Status",
      dataIndex: "status",
     width: "100px"
    },
    {
      title: "Created At",
      dataIndex: "scheduled",
       width: "170px"

    },
    // {
    //   title: "Updated At",
    //   dataIndex: "updatedAt",
    // },
    {
      title: "Compliant At",
      dataIndex: "compliant",
       width: "170px"
      // sorter: (a, b) => { return new Date(moment(a.compliantDate, "MMMM Do YYYY, h:mm:ss a").format("LLL")) -
      // new Date(moment(b.compliantDate, "MMMM Do YYYY, h:mm:ss a").format("LLL"))},
    },
    {
      title: "Notification Sent At",
      dataIndex: "notificationTime",
       width: "160px"
      // sorter: (a, b) => { return new Date(moment(a.notificationDate, "MMMM Do YYYY, h:mm:ss a").format("LLL")) -
      // new Date(moment(b.notificationDate, "MMMM Do YYYY, h:mm:ss a").format("LLL"))},
    }
  ];

  const downloadReport = async () => {
    //const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
    notification.info({
      description: "Report Download is In Progress",
      placement: "topRight",
      duration: 5,
    });
    setDisplay("block");
    setTimeout(() => {
      generatePDF(() => document.getElementById("container"), {
        method: "save",
        filename: +positiveBrac?participantProfileData?.fullName+"_non_compliance":participantProfileData?.fullName+"_compliance"+".pdf",
      });
      //toPDF({ method: "save",filename:participantProfileData?.fullName});
      setDisplay("none");
    }, 3000);
  };

  const markAsRead = async () => {
    let localitem = JSON.parse(localStorage.getItem('selectedItem'));
    try {
      if(localitem.length>0){
      let eventsData = [];
      eventsData = localitem?.map((item) => {
        return {
          id: item,
          isRead: true,
        };
      });

      await apiHandler({
        url: endpoint.COMPLIANCE_EVENTS,
        method: "POST",
        data: eventsData,
        authToken,
      }).then(async (result) => {
        let selected = [];
        dispatch(setSelectedRowKeys(selected));
        localStorage.removeItem('selectedItem')
         dispatch(setSelectedEvents([]))
        getEvents();
        notification.info({
          description: result.data.message,
          placement: "topRight",
          duration: 5,
        });
      });
    }
    } catch (error) {
      dispatch(
        setExceptionErrorMessage("Select An Event To Complete Mark as read")
      );
      setShowError(true);
      console.log("error here", error);
    }
  };

  useEffect(() => {
    if (!activeHistoryTab) {
      getEvents();
    }
  }, [activeHistoryTab]);

  const getAllCompliance = () => {};

  useEffect(() => {
    getReportData(participantProfileData.latestProgram?.activationDate, new Date(), false);
    // participantProfileData.openComplianceEvents.forEach(element => {
    // apiHandler({
    //   url: endpoint.ENROLL_PARTICIPANT+"/"+participantProfileData?.id+"/events/" +moment(element.receivedAt).format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":",""),
    //   authToken,
    // }).then((result) => {
    //   console.log("all events here",result);
    // })
    // if(element.eventType.deviceTypeName =="Breathalyzer"){
    //setpositiveBrac(true);
    // console.log(element);

    //  if(element.eventType.indicator =="Behavior" && element.eventType.isMaintenance == false){

    //  setnoncomplianceData(noncompliance);
    // apiHandler({
    //   url: endpoint.ENROLL_PARTICIPANT+"/"+participantProfileData?.id+"/watchMetrics/" +moment(element.receivedAt).format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":",""),
    //   authToken,
    // }).then((result) => {
    //   let alcData = [];
    //   let lblData = [];
    //   if (result&&result.data) {
    //    result.data.forEach(el=>{
    //     if(el.alcoholConcentration!==null){
    //       alcData.push(el.alcoholConcentration);
    //       lblData.push(el.evaluatedAt);
    //     }
    //    });
    //    setstartDateAlco(lblData[0]);
    //    setendDateAlco(lblData[lblData.length-1]);
    //    setPeakData(Math.max.apply(null, alcData));
    //    setlabels(lblData);
    //    setAlcoholData(alcData);
    //   }
    // });

    //  });
    dispatch(setSelectedEvents());
  }, []);

  const getReportData = (startDate, endDate, violation) => {
    let query = ''
    if(violation) {
      query = moment(startDate).format(
        "YYYY-MM-DD"
      )+'/'+moment(endDate).format(
        "YYYY-MM-DD"
      )+'/violations/Watch'
    } else {
      query = moment(startDate).format(
        "YYYY-MM-DD"
      )+'/'+moment(endDate).format(
        "YYYY-MM-DD"
      )
    }
    if (participantProfileData.latestProgram) {
      apiHandler({
        url:
          endpoint.ENROLL_PARTICIPANT +
          "/" +
          participantProfileData?.id +
          "/events/" + query,
        authToken,
      }).then(async (result) => {
        console.log("events data qq", result.data);
        const allnon = [];
        if (Array.isArray(result.data)) {
          // setDeviceNum(result?.data[0]?.device?.serialNumber);

          console.log("resultss : ", result.data);
          result.data.map((element) => {
            const noncompliance = [];
            if (
              element.eventType.indicator == "Behavior" &&
              element.eventType.isMaintenance == false &&
              element.eventType.name === "Alcohol Consumption"  &&
              element.isManuallyResolved === true
            ) {
              setpositiveBrac(true);
              setDeviceNum(result.data[0].device.serialNumber);
              noncompliance.push(element);
              let url =
                endpoint.ENROLL_PARTICIPANT +
                "/" +
                participantProfileData?.id +
                "/alcEventMetrics/" +
                moment(element.createdAt).format("YYYY-MM-DDTHH:mm") +
                moment().format("Z").replaceAll(":", "");
              if (element.compliantAt !== null) {
                url =
                  endpoint.ENROLL_PARTICIPANT +
                  "/" +
                  participantProfileData?.id +
                  "/alcEventMetrics/" +
                  moment(element.createdAt).format("YYYY-MM-DDTHH:mm") +
                  moment().format("Z").replaceAll(":", "") +
                  "/" +
                  moment(element.compliantAt).format("YYYY-MM-DDTHH:mm") +
                  moment().format("Z").replaceAll(":", "");
              }

              console.log("events data ss", url);
              apiHandler({
                url: url,
                authToken,
              }).then(async (results) => {
                let alcData = [];
                let lblData = [];
                if (results && results.data) {
                  console.log("allresult", results.data);
                  const dumData = {
                    alcData: results.data.alcoholConcentrations,
                    lblData: results.data.timestamps.map((el) => {
                      return moment(el).format("MM/DD/YYYY hh:mm A");
                    }),
                    startDate: moment(results.data.timestamps[0]).format(
                      "MMM DD, YYYY hh:mm A"
                    ),
                    endDate: moment(
                      results.data.timestamps[
                        results.data.timestamps.length - 1
                      ]
                    ).format("MMM DD, YYYY hh:mm A"),
                    peakData:
                      element.alcoholConcentration !== null
                        ? element.alcoholConcentration
                        : 0,
                    serialNumber: element.device.serialNumber,
                    chartData: {
                      labels: results.data.timestamps.map((el) => {
                        return moment(el).format("MMM DD, YYYY hh:mm A");
                        // return moment(el).format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","");
              
                      }),
                      datasets: [
                        {
                          label: "Dataset 1",
                          data: results.data.alcoholConcentrations.map(el=> Number(el*0.21).toFixed(2)),
                          borderColor: "rgb(87, 75, 128)",
                          backgroundColor: "rgba(255, 99, 132, 0.5)",
                          lineTension: 0,
                        },
                      ],
                    },
                  };

                  console.log("dumm data : ", dumData);

                  //allnon.push(dumData);
                  setAllNonData((oldValue) => [...oldValue, dumData]);
                  //setstartDateAlco(lblData[0]);
                  //setendDateAlco(lblData[lblData.length-1]);
                  //setPeakData(Math.max.apply(null, alcData));
                  //setlabels(lblData);
                  //setAlcoholData(alcData);
                }
              });
            }
          });
        }
        // setAllNonData(allnon);
        console.log("alll non", allnonData);
      });
    }
  };
  return (
    <>
      <style>{`
    table th,
    td {
        padding: 0px !important;
    }
  `}</style>
      <h2>New Events</h2>
      <Row style={{ justifyContent: "space-between" }}>
        <Col>
          <ReadButton onClick={() => markAsRead()}>Mark as Read</ReadButton>
        </Col>
        <Col>
          <RangePicker
          value={dates}
          showTime={false}
          format="MMM DD, YYYY"
          showSecond={false}
          minuteStep={30}
          onCalendarChange={(v) => setStartDate(v)}
          style={{ width: 230, float: "right" }}
          /> 
        </Col>
        <Col>
          {positiveBrac ? (
            <DownloadButton onClick={() => downloadReport()}>
              Download Non Compliance Report
            </DownloadButton>
          ) : (
            <>
              <DownloadButton onClick={() => downloadReport()}>
                Download Compliance Report
              </DownloadButton>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <p style={{ color: "red", fontWeight: 600 }}>
          Mark as Read removes events from this page but they will be viewable
          in the View History page.
        </p>
      </Row>
      <CustomDataTable
        showSelect={true}
        position={["bottomLeft"]}
        columns={columns}
        data={eventsList}
        tableSource="events"
        // showPagination={true}
      />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
      <div
      id="container"
        ref={targetRef}
        style={{
         display,
          padding: " 0 50px 0 50px",
          width:"900px",
          marginLeft:"-251px"
        }}
      >
        {/* <div ref={targetRef}> */}
        <div>
          <div style={{ height:"auto", maxWidth: "780px",marginBottom:"20px" }}>
            {" "}
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <span></span>
            </p>
            {/* this tables adds a compliance or non compliance image only based on positiveBrc state. */}
            <table border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td>
                    {positiveBrac ? (
                      <img width="100%" src={Nonompliance} />
                    ) : (
                      <img width="100%" src={Compliance} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p />
            {/* this tables renders the basic details */}
            <table
              style={{
                display: "block",
                borderCollapse: "collapse",
                marginLeft: "6pt",
                paddingBottom: "10px",
                paddingTop: "10px",
                borderBottom: "1px solid gray",
                borderTop: "1px solid gray",
              }}
              cellSpacing={0}
            >
              <tbody>
                <tr>
                  <td>Client:</td>
                  <td style={{ fontWeight: "600",width:"187px" }}>
                    {participantProfileData?.fullName}
                  </td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  <td>Supervising Authority:</td>
                  <td style={{width:"120px"}}>&nbsp; &nbsp; {participantProfileData?.agency?.name}</td>
                </tr>
                <tr>
                  <td>Case Number:</td>
                  <td>{participantProfileData?.caseNumber}</td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  <td>Monitored by:</td>
                  <td> &nbsp; &nbsp; CAM Systems</td>
                </tr>
                <tr>
                  <td>Offense:</td>
                  <td style={{width:"187px"}}>{participantProfileData?.offense}</td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  <td>Report Prepared on:</td>
                  <td style={{width:"187px"}}>
                    {" "}
                    &nbsp; &nbsp; {moment(new Date()).format("MMM DD, YYYY")}
                  </td>
                </tr>
                <tr>
                  <td>Date Of Birth:</td>
                  <td>
                    {moment(participantProfileData?.birthDate).format(
                      "MMM DD, YYYY"
                    ) === "Invalid date"
                      ? ""
                      : moment(participantProfileData?.birthDate).format(
                          "MMM DD, YYYY"
                        )}
                  </td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  <td>TRAC Watch Number:</td>
                  <td>&nbsp; &nbsp; {deviceNumber}</td>
                  {/* <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td> */}
                  {/* <td>Assigned Equipment:</td>
                  <td style={{width:"187px"}}>
                    &nbsp; &nbsp;
                    {
                      participantProfileData?.latestProgram?.alcoholProgramType
                        ?.name
                    }
                  </td> */}
                </tr>
                <tr>
                  <td>Address:</td>
                  <td style={{width:"187px"}}>
                    {participantProfileData?.address?.address123}{" "}
                    {participantProfileData?.address?.cityStateZip}
                  </td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  <td>Days Monitored:</td>
                  <td>
                    &nbsp; &nbsp;
                    {moment(new Date()).diff(
                      moment(
                        participantProfileData?.latestProgram?.activationDate
                      ),
                      "days"
                    )+1}{" "}
                    Days
                  </td>
                </tr>
                <tr>
                  <td>Program Enrollment Date:</td>
                  <td> &nbsp; &nbsp;
                    {moment(
                      participantProfileData?.latestProgram?.activationDate
                    ).format("MMM DD, YYYY")}
                  </td>
                </tr>
              </tbody>
            </table>
            {positiveBrac ? (
              ""
            ) : (
              <>
                <p
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "7pt",
                    textIndent: "0pt",
                    lineHeight: "114%",
                    textAlign: "left",
                  }}
                >
                  The following is a business record prepared on the date first
                  written above and includes all monitoring for the{" "}
                  <b>
                    {moment(new Date()).diff(
                      moment(
                        participantProfileData?.latestProgram?.activationDate
                      ),
                      "days"
                    )+1}{" "}
                    Days{" "}
                  </b>
                  from the{" "}
                  <b>
                    {moment(
                      participantProfileData?.latestProgram?.activationDate
                    ).format("MMM DD, YYYY")}{" "}
                  </b>
                  through the Report Prepared on{" "}
                  <span className="p">
                    date:{moment(new Date()).format("MMM DD, YYYY")}
                  </span>
                </p>
                {/* Activity */}
                <h1
                  style={{
                    paddingLeft: "7pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Activity:
                </h1>
                <p
                  style={{
                    paddingTop: "0",
                    paddingLeft: "7pt",
                    textIndent: "0pt",
                    lineHeight: "114%",
                    textAlign: "left",
                  }}
                >
                  {participantProfileData?.fullName} was placed on the TRAC
                  Watch program on{" "}
                  {moment(
                    participantProfileData?.latestProgram?.activationDate
                  ).format("MMM DD, YYYY")}
                  , and continues to be monitored under the TRAC Watch program
                  as of the date of this report.
                  There have been no alcohol or tamper events during the monitoring period.
                </p>
              </>
            )}
          </div>

          {/* if postiveBrac is true it will renders all the events passing the condtion indicator=='behaviour' && isMaintenec == false && eventType.name == 'Alcohol Consumption' && isManuallyResolved == true*/}
          {positiveBrac ? (
            <>
              {allnonData.map((object, i) => {
                if (i == 0) {
                  return (
                    <>
                      <div
                        style={{
                          height: "550px",

                          aspectRatio: "auto",
                          // position: "relative",
                          // top: positiveBrac ?  "-301px" : "-285px",
                        }}
                      >
                        <p
                          style={{
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "left",
                          }}
                        >
                          This is a business record created on or about the date
                          of the occurrence and in the normal course of
                          business.
                        </p>
                        <p
                          style={{
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "left",
                          }}
                        >
                          The TRAC Watch has detected {allnonData.length}{" "}
                          Confirmed Alcohol event(s). Event details below.
                        </p>
                        <p
                          style={{
                            paddingTop: "0",
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "left",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                              display: "inline-block",
                              margin: "2px 0px 2px 0px",
                            }}
                          >
                            Confirmed Alcohol Consumption{" "}
                          </span>
                          <br />
                          Start:
                          
                          {object.startDate}
                          <br />
                          Peak Alcohol Content: {object.peakData} mg/L/BrAC: {Number(object.peakData*0.21).toFixed(2)} <br />
                          End:{" "}
                          {/* {moment(object.endDate).format("YYYY-MM-DD HH:mm:ss")} */}
                          {object.endDate}
                          <div class="grid-container">
                              <div class="item1"><span style={ {marginTop: "130px",position: "absolute",transform: "rotate(-90deg)",writingMode:"vertical-lr"}}>BrAC</span></div>
                              <div class="item2"><Line options={options} data={object.chartData} /></div>
  
                          </div>
                          {/* <Row>
                          <Col span={2}></Col>
                          <Col span={22}></Col>
                        </Row> */}
                        </p>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <div
                      style={{
                        height: "900px",
                        marginTop:"408px",
                        aspectRatio: "auto",
                      }}
                    >
                      <table border={0} cellSpacing={0} cellPadding={0}>
                        <tbody>
                          <tr>
                            <td>
                              <img width="814" src={Nonompliance} />
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <table
                      
                        style={{
                          display: "block",
                          borderCollapse: "collapse",
                          marginLeft: "6pt",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          borderBottom: "1px solid gray",
                          borderTop: "1px solid gray",
                        }}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td>Client:</td>
                            <td style={{ fontWeight: "600" ,width:"187px"}}>
                              {participantProfileData?.fullName}
                            </td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td>Supervising Authority:</td>
                            <td style={{width:"187px"}}>
                              &nbsp; &nbsp;{" "}
                              {participantProfileData?.agency?.name}
                            </td>
                          </tr>
                          <tr>
                            <td>Case Number:</td>
                            <td>{participantProfileData?.caseNumber}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td>Monitored by:</td>
                            <td> &nbsp; &nbsp; CAM Systems</td>
                          </tr>
                          <tr>
                            <td>Offense:</td>
                            <td style={{width:"187px"}}>{participantProfileData?.offense}</td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td>Report Prepared on:</td>
                            <td>
                              &nbsp; &nbsp;{" "}
                              {moment(new Date()).format("MMM DD, YYYY")}
                            </td>
                          </tr>
                          <tr>
                            <td>Date Of Birth:</td>
                            <td>
                              {moment(participantProfileData?.birthDate).format(
                                "MMM DD, YYYY"
                              )}
                            </td>
                            {/* <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td>Assigned Equipment:</td>
                            <td style={{width:"187px"}}>
                              &nbsp; &nbsp;
                              {
                                participantProfileData?.latestProgram
                                  ?.alcoholProgramType?.name
                              }
                            </td> */}
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td>TRAC Watch Number:</td>
                            <td>&nbsp; &nbsp; {deviceNumber}</td>
                          </tr>
                          <tr>
                            <td>Address:</td>
                            <td style={{width:"187px"}}>
                              {participantProfileData?.address?.address123}{" "}
                              {participantProfileData?.address?.cityStateZip}
                            </td>
                            <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                            <td>Days Monitored:</td>
                            <td>
                              &nbsp; &nbsp;
                              {moment(new Date()).diff(
                                moment(
                                  participantProfileData?.latestProgram
                                    ?.activationDate
                                ),
                                "days"
                              )+1}{" "}
                              Days
                            </td>
                          </tr>
                          <tr>
                            <td>Program Enrollment Date:</td>
                            <td> &nbsp; &nbsp;
                              {moment(
                                participantProfileData?.latestProgram
                                  ?.activationDate
                              ).format("MMM DD, YYYY")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      
                      <p
                          style={{
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "left",
                            marginTop:"14px"
                          }}
                        >
                          This is a business record created on or about the date
                          of the occurrence and in the normal course of
                          business.
                        </p>
                        <p
                          style={{
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "left",
                          }}
                        >
                          The TRAC Watch has detected {allnonData.length}{" "}
                          Confirmed Alcohol event(s). Event details below.
                        </p>
                      <p
                        style={{
                          paddingTop: "0",
                          paddingLeft: "7pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "left",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bolder",
                            display: "inline-block",
                            margin: "10px 0px 2px 0px",
                          }}
                        >
                          Confirmed Alcohol Consumption{" "}
                        </span>
                        <br />
                        Start:{" "}
                        {/* {moment(object.startDate).format("YYYY-MM-DD HH:mm:ss")}{" "} */}
                        {object.startDate}
                        <br />
                        <br />
                        Peak Alcohol Content: {object.peakData} mg/L/BrAC: {Number(object.peakData*0.21).toFixed(2)} <br />
                        End:
                        {/* {moment(object.endDate).format("YYYY-MM-DD HH:mm:ss")} */}
                        {object.endDate}
                     
                        <div class="grid-container">
                              <div class="item1"><span style={ {marginTop: "130px",position: "absolute",transform: "rotate(-90deg)",writingMode:"vertical-lr"}}>BrAC</span></div>
                              <div class="item2"><Line options={options} data={object.chartData} /></div>
  
                          </div>
                        
                        
                      </p>

                      <br />
                    </div>
                  );
                }
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <>
        {showError ? (
          <>
            <ExceptionHandle />
          </>
        ) : (
          <></>
        )}
      </>
    </>
  );
};

export default Events;

// date for format : 2024-03-17T12:50-0600 -> moment().format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","").replaceAll("+","-")
