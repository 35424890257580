import React from 'react';
import { useState } from "react";
import { Row, Col, Button, Checkbox, Table } from "antd";
import { StyledModal } from "./ExceptionHandle.style";
import "./ExceptionHandle.css";
import { EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import Logo from "assets/logo.png";
const ExceptionHandle = (props) => {
    const {  exceptionErrorMessage } = useSelector(
        (state) => state.common
      );
  const [modalVisible, setModalVisible] = useState(true);
  const handleCancel = () => {
    setModalVisible(false);
  };


  


  return (
    <>
      <StyledModal visible={modalVisible} width={800} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>&nbsp; &nbsp; &nbsp;System Message</Col>
            <Col md={15}>
              {/* <Button type="primary" style={{float: 'right',marginLeft:'1rem'}}>
                Save
              </Button> */}
              <Button style={{float: 'right'}} onClick={handleCancel}>Cancel</Button>
            </Col>
          </Row>
        ]}        
      >
        <Row>
          {/* <Col md={24} className="cs_table">
            <Table dataSource={dataSource} columns={columns} pagination={false} className="cs_check" />
          </Col> */}
          {exceptionErrorMessage}
        </Row>

       
      </StyledModal>
    </>
  );
};

export default ExceptionHandle;
