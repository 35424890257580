import { useState } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import SideDrawer from "../../components/SideMenu/SideDrawer";
import { StyledDivider } from "containers/EnrollParticipant/EnrollParticipant.styles";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Layout = (/* { children } */) => {
  const loginData = useSelector((state) => state.login);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  return (
    <> 
      {loginData&&loginData.authToken ?  <>
      <Header drawerToggleClickHandler={() => drawerToggleClickHandler()} />
      <StyledDivider />
      <SideDrawer
        closeSideDrawer={() => drawerToggleClickHandler()}
        show={sideDrawerOpen}
      />
      <Outlet />
      <StyledDivider />
      <Footer />
    </> : <Navigate to={{
      pathname: "/login"
    }} />}
    </>
   
  );
};

export default Layout;
