import React from "react";
import WeekCalendar from "react-week-calendar-opt";
import "react-week-calendar-opt/dist/style.css";

const ManageSchedule = () => {
  return (
    
    <>
    <WeekCalendar
      style={{ marginTop: 50 }}
      scaleUnit="60"
      dayFormat={"ddd M/DD"}
    />
      {/* Need to add Week Calendar */}
    </>
  );
};

export default ManageSchedule;
