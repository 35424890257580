// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-steps-item-process .ant-steps-item-icon{
      background: #0557a2 !important;
      border-color: #0557a2;
}

.ant-steps-item-finish .ant-steps-item-icon{
      border-color: #0557a2;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description{
      max-width: 200px;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
      background-color: #c4c4c4 !important;
}

.ant-steps{
      max-width: 90%;
}

.ant-steps-finish-icon{
      color: #0557a2 !important;
}`, "",{"version":3,"sources":["webpack://./src/domain/EnrollParticipantWizard/WizardProgressBar.css"],"names":[],"mappings":"AAAA;MACM,8BAA8B;MAC9B,qBAAqB;AAC3B;;AAEA;MACM,qBAAqB;AAC3B;;AAEA;MACM,gBAAgB;AACtB;;AAEA;MACM,oCAAoC;AAC1C;;AAEA;MACM,cAAc;AACpB;;AAEA;MACM,yBAAyB;AAC/B","sourcesContent":[".ant-steps-item-process .ant-steps-item-icon{\n      background: #0557a2 !important;\n      border-color: #0557a2;\n}\n\n.ant-steps-item-finish .ant-steps-item-icon{\n      border-color: #0557a2;\n}\n\n.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description{\n      max-width: 200px;\n}\n\n.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{\n      background-color: #c4c4c4 !important;\n}\n\n.ant-steps{\n      max-width: 90%;\n}\n\n.ant-steps-finish-icon{\n      color: #0557a2 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
