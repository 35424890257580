import { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import Caseload from "./containers/Caseload/Caseload";
import ParticipantCaseload from "./containers/ParticipantCaseload/ParticipantCaseload";
import Admin from "./containers/Admin/Admin";
import AddParticipant from "containers/EnrollParticipant/EnrollParticipant";
import MyAccount from "containers/MyAccount/MyAccount";
import Login from "containers/Login/Login";
import Agency from "containers/Agency/Agency";
import OpenEvents from "containers/OpenEvents/OpenEvents";
import Photos from 'containers/Photos/Photos'
import Reports from "containers/Reports/Reports";
import { ProtectedRoute } from "hocs/ProtectedRoute/ProtectedRoute";
import Layout from "hocs/Layout";
function App() {


  useEffect(() => {
    // setAgenciesDropDown(authToken, dispatch);
  }, []);

  return (
    <Router>
      {/* <Switch>
        <Route exact path="/login" render={(props) => <Login {...props} />} />
        <ProtectedRoute exact path="/caseload" component={Caseload} />
        <ProtectedRoute exact path="/agency" component={Agency} />
        <ProtectedRoute exact path="/reports" component={Reports} />
        <ProtectedRoute exact path="/admin" component={Admin} />
        <ProtectedRoute exact path="/add-participant" component={AddParticipant} />
        <ProtectedRoute exact path="/my-account" component={MyAccount} />
        <Route
          path="/*"
          render={(props) => (
            <Redirect {...props} to={{ pathname: "/login" }} />
          )}
        />
      </Switch> */}
      <Routes>
        <Route exact path="/participantcaseload/:id" element={<ParticipantCaseload />} />
        <Route exact path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route exact path="/caseload" 
            element={
              <ProtectedRoute> 
                <Caseload />
              </ProtectedRoute>
            } 
          />
          <Route exact path="/caseload/:id" 
            element={
              <ProtectedRoute> 
                <Caseload />
              </ProtectedRoute>
            } 
          />
          <Route exact path="/caseload/:id/:startDate/:endDate/:type" 
            element={
              <ProtectedRoute> 
                <Caseload />
              </ProtectedRoute>
            } 
          />
           <Route exact path="/photos" 
            element={
              <ProtectedRoute> 
                <Photos />
              </ProtectedRoute>
            } 
          />
          <Route exact path="/agency" 
            element={
              <ProtectedRoute> 
                <Agency />
              </ProtectedRoute>
            } 
          />
          <Route exact path="/openevents" 
            element={
              <ProtectedRoute> 
                <OpenEvents />
              </ProtectedRoute>
            } 
          />
          <Route exact path="/reports" 
            element={
              <ProtectedRoute> 
                <Reports />
              </ProtectedRoute>
            } 
          />
          <Route exact path="/admin" 
            element={
              <ProtectedRoute> 
                <Admin />
              </ProtectedRoute>
            } 
          />
          <Route exact path="/add-participant" 
            element={
              <ProtectedRoute> 
                <AddParticipant />
              </ProtectedRoute>
            } 
          />
          <Route exact path="/my-account" 
            element={
              <ProtectedRoute> 
                <MyAccount />
              </ProtectedRoute>
            } 
          />
        </Route>
        

        <Route
          path="/*"
          element={<Navigate  to={{ pathname: "/login" }} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
