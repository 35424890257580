import CustomDataTable from "../../../components/CustomDataTable/CustomDataTable";
import moment from "moment";
import { useEffect, useState } from "react";
import apiHandler from "api";
import { useSelector } from "react-redux";
import endpoint from "api/endpoint";
import EditIcon from "assets/caseload/EditIcon.svg";
import PinnedNote from "assets/caseload/pinned.svg";
import UnpinnedNote from "assets/caseload/notPinned.svg";
import DownloadTableButton from "components/DownloadTableButton/DownloadTableButton";
import { Col, Row, Radio } from "antd";
import {
  StyledInputTextArea,
  StyledModal,
} from "../PinnedNotes/PinnedNotes.style";
import Checkbox from "antd/lib/checkbox/Checkbox";

const ViolationHistory = () => {
  const [actChecked, setActiveId] = useState(1);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      width: '10%'
    },
    {
      title: "Time",
      dataIndex: "time",
      width: '40%'
    },
    {
      title: "Violation Type",
      dataIndex: "violation_type",
      width: '25%'
    },
    {
      title: "Reading",
      dataIndex: "reading",
      width: '25%'
    },
  ];

  const csvHeaders = [
    {
      label: "Date",
      key: "date",
    },
    {
      label: "Time",
      key: "time",
    },
    {
      label: "Violation Type",
      key: "violation_type",
    },
    {
      label: "Reading",
      key: "reading",
    },
  ];
  const reportsType = [
    {
      id: 1,
      name: "Breath Violation Data"
    },
    {
      id: 2,
      name: "Watch Violation Data"
    },
    {
      id: 3,
      name: "GPS Violation Data"
    }
  ];
  const onFilterChange = (e) => {
    setActiveId(e.target.value);
    getViolationData(e.target.value)
  };
  const { historyStartDate } = useSelector((state) => state.common);

  const { authToken } = useSelector((state) => state.login);

  const [NotesHistoryData, setNotesHistoryData] = useState();

  const [message, setMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [currentNoteId, setCurrentNoteId] = useState(0);
  const [editNoteId, setEditNoteId] = useState();
  const [messageError, setMessageError] = useState();
  const [isPinned, setIsPinned] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length > 99) {
      setMessageError('Only 100 characters allowed');
    }
    else {
      setMessageError('');
      setMessage(value);
      // dispatch(setPinnedNotesData(pinnedNotesData));
    }

  };


  const { participantProfileData } = useSelector(
    (state) => state.participantProfileData
  );

  const editNote = (obj) => {
    console.log('Edit note', obj);
    setEditNoteId(obj.id);
    setMessage(obj.message);
    setIsPinned(obj.isPinned);
  }

  useEffect(() => {
    setActiveId(1);
    if (historyStartDate) {
      getViolationData(1);
    }


  }, [historyStartDate]);

  useEffect(() => {
    if (editNoteId > 0) {
      setModalVisible(true);
    }
  }, [editNoteId]);
  const getViolationData = (value) => {
    console.log("historyStartDate",historyStartDate);
    let deviceType = "";
    if (value == 1)
      deviceType = "Breathalyzer"
    if (value == 2)
      deviceType = "Watch"
    if (value == 3)
      deviceType = "GPS"
    console.log("deviceType: ", deviceType, actChecked);
    apiHandler({
      url:
        endpoint.ENROLL_PARTICIPANT +
        "/" +
        participantProfileData?.id +
        "/events/" +
        (historyStartDate.format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")) + "/violations/" + deviceType,
      authToken,
    }).then((result) => {
      if (result.data.length > 0) {
        let passData = [];
        result.data.sort(function (a, b) {
          var keyA = new Date(a.receivedAt),
            keyB = new Date(b.receivedAt);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        result.data.forEach(element => {
          const obj = {
            date: moment(element.receivedAt).format("MM/DD/YYYY"),
            time: moment(element.receivedAt).format("hh:mm A"),
            violation_type: element.eventType.name,
            reading: element.description
          }
          passData.push(obj);
        });
        setNotesHistoryData(passData);
      }
      else {
        setNotesHistoryData([]);
      }
    });
  }
  const updateNote = async (e, { id, message, isPinned }, editNote) => {
    // e.stopPropagation();
    await apiHandler({
      url: `${endpoint.ENROLL_PARTICIPANT}/${participantProfileData?.id}/note`,
      method: "POST",
      data: {
        id,
        isPinned: !isPinned,
        message,
      },
      authToken,
    }).then((result) => {
      apiHandler({
        url:
          endpoint.ENROLL_PARTICIPANT +
          "/" +
          participantProfileData?.id +
          "/notes/" +
          (historyStartDate.format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")),
        authToken,
      }).then((result) => {
        setNotesHistoryData(result.data);
      });
    });
  };


  const createNewNote = async () => {
    if (message.length > 100) {
      setMessageError('Only 100 characters allowed');
    }
    else {
      setMessageError('');
      await apiHandler({
        url: `${endpoint.ENROLL_PARTICIPANT}/${participantProfileData?.id}/note`,
        method: "POST",
        data: {
          id: editNoteId || currentNoteId,
          isPinned,
          message,
        },
        authToken,
      });
      setModalVisible(false);
      // getPinnedNotesData();
      setMessage("");
      setIsPinned(false);
      if (editNoteId) {
        setEditNoteId(null);
        setIsPinned(false);
      }

      apiHandler({
        url:
          endpoint.ENROLL_PARTICIPANT +
          "/" +
          participantProfileData?.id +
          "/notes/" +
          (historyStartDate.format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")),
        authToken,
      }).then((result) => {
        setNotesHistoryData(result.data);
      });
    }
  };

  const data = [];
  const csvData = [];
  //   date:moment(element.receivedAt).format("YYYY-MM-DD"),
  //   time:moment(element.receivedAt).format("hh:mm:ss"),
  //   violation_type:element.eventType.name,
  //reading: element.description
  NotesHistoryData?.map(({ date, time, violation_type, reading }, index) => {
    data.push({
      key: index,
      date: date,
      time: time,
      violation_type: violation_type,
      reading: reading
    });

    csvData.push({
      date: date,
      time: time,
      violation_type: violation_type,
      reading: reading
    });
  });

  return (
    <>
      <Row>
        <Col span={8}>
          {actChecked == 1 ? (
            <h2>Breath Violation History</h2>
          ) : <></>}
          {actChecked == 2 ? (
            <h2>Watch Violation History</h2>
          ) : <></>}
          {actChecked == 3 ? (
            <h2>GPS Violation History</h2>
          ) : <></>}
        </Col>
        <Col span={12}>
          <Row style={{ marginTop: '0.3rem' }}>
            {reportsType.map((list, i) =>
              <>

                <Col>
                  <Radio className="cs_check" onChange={onFilterChange} checked={actChecked == list.id} value={list.id}>{list.name}</Radio>
                </Col>

              </>
            )}
          </Row>
        </Col>
        <Col span={4}>
          <DownloadTableButton csvData={csvData} headers={csvHeaders} />
        </Col>
      </Row>
      <CustomDataTable
        position={["bottomRight"]}
        columns={columns}
        data={data}
        showPagination={true}
      />
      <StyledModal
        title="New Note"
        centered
        width="270px"
        visible={modalVisible}
        okText="Save"
        cancelText="Cancel"
        onOk={(e) => { createNewNote() }}
        onCancel={(e) => { setModalVisible(false) }}
        wrapClassName="pinnedNotesModal"
      >
        <StyledInputTextArea
          onChange={(e) => {
            // e.stopPropagation();
            handleChange(e);
          }}

          autoSize={true}
          rows={5}
          maxLength={100}
          value={message}
        />
        <p className="text-danger">{messageError}</p>
        <Checkbox
          checked={isPinned}

          onChange={(e) => {
            // e.stopPropagation();
            setIsPinned(!isPinned);
          }}
        >
          Pin this note
        </Checkbox>
      </StyledModal>
    </>
  );
};

export default ViolationHistory;