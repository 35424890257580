// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-drawer {
  height: 100%;
  background: #fff;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1rem;
  padding-inline-start: 0;
}

.side-drawer li {
  margin: 2.5rem;
  text-align: center;
}

.side-drawer a {
  text-decoration: none;
  color: #000;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #0557a2;
}

.side-drawer a img{
  width: 250px;
}

@media screen and (min-width: 759px) {
  .side-drawer {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SideMenu/SideDrawer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,0CAA0C;EAC1C,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,4BAA4B;EAC5B,mCAAmC;AACrC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,iBAAiB;AACnB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".side-drawer {\n  height: 100%;\n  background: #fff;\n  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  max-width: 400px;\n  z-index: 200;\n  transform: translateX(-100%);\n  transition: transform 0.3s ease-out;\n}\n\n.side-drawer.open {\n  transform: translateX(0);\n}\n\n.side-drawer ul {\n  height: 100%;\n  list-style: none;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  margin-top: 1rem;\n  padding-inline-start: 0;\n}\n\n.side-drawer li {\n  margin: 2.5rem;\n  text-align: center;\n}\n\n.side-drawer a {\n  text-decoration: none;\n  color: #000;\n  font-size: 1.2rem;\n}\n\n.side-drawer a:hover,\n.side-drawer a:active {\n  color: #0557a2;\n}\n\n.side-drawer a img{\n  width: 250px;\n}\n\n@media screen and (min-width: 759px) {\n  .side-drawer {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
