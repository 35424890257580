import { Button, Row, Select, Input, Col, Table, message, Checkbox,AutoComplete ,notification,Modal} from "antd";
import apiHandler from "api";
import endpoint from "api/endpoint";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { StyledButton } from "theme/StyledComponents";
import AlertBoard from "containers/AlertBoard/AlertBoard";
import { StyledModal } from "./WizardStepTwo.style";
import { EditOutlined,PlusCircleOutlined,DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

export const StyledSelectLabel = styled.label`
  min-width: 110px !important;
  display: flex;
  justify-content: flex-start;
  margin-right: 10px !important;
  padding-top: 5px !important;
`;

const WizardStepTwo = () => {
  const [alertGroups, setAlertGroups] = useState([]);
  const [alertRecipients, setalertRecipients] = useState([]);
  const [availableContacts, setAvailableContacts] = useState([]);
  const [selectedAlertGroup, setSelectedAlertGroup] = useState(alertGroups && alertGroups[0]?.id);
  const [selectedSubscription, setselectedSubscription] = useState(alertGroups && alertGroups[0]?.topicArn);
  const [selectedTopicArn, setselectedTopicArn] = useState("");
  const { authToken,userData } = useSelector((state) => state.login);


  const [contactData, setContactData] = useState({});

  const [formErrors, setFormErrors] = useState({});
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [alertTypeCalled, setAlertTypeCalled] = useState(false);
  const handleAlertCancel = () => { setAlertModalVisible(false); };
  const [alertType, setAlertType] = useState("");
  const [breath, setBreath] = useState([]);
  const [watch, setWatch] = useState([]);
  const [gps, setGps] = useState([]);
  const [univarsal, setUniversal] = useState([]);
  const [userAlertList, setUserAlertList] = useState([]);
  const [firstNameOptions, setfirstNameOptions] = useState([]);
  const [lastNameOptions, setlastNameOptions] = useState([]);
  const [roleOptions, setroleOptions] = useState([]);
  const [emailOptions, setemailOptions] = useState([]);
  const [phoneOptions, setphoneOptions] = useState([]);
  const [additionalContact, setadditionalContact] = useState([]);
  const [allcontact, setAllcontact] = useState([]);
  const [selectedRecipients, setselectedRecipients] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [scontact, setSContact] = useState("");
  const [sArn, setsArn] = useState("");
  let dummyUserAlertList = [];
  const [alertList, setAlertList] = useState([{
    key: '1',
    name: <Checkbox>Select all</Checkbox>,
    watch: <Checkbox>Select all</Checkbox>,
    gps: <Checkbox>Select all</Checkbox>,
    filter: <Checkbox>Select all</Checkbox>,
  }]);

  const enrollParticipantData = useSelector(
    (state) => state.common.enrollParticipantData
  );

  const { editParticipantProfile } = useSelector(
    (state) => state.common
  );

  const { participantProfileData } = useSelector(
    (state) => state.participantProfileData
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if(value.length>=2){
      console.log("e.target",value, name);
    }
    setContactData({ ...contactData, [name]: value });
  };

const saveAdditionalRecipients = () =>{
  if(selectedRecipients){
    let contactData = {
     // id: selectedRecipients.id,
      firstName: selectedRecipients.firstName,
      lastName: selectedRecipients.lastName,
      mobilePhone: selectedRecipients.mobilePhone,
      email: selectedRecipients.email
    }
    apiHandler({
      url: `${endpoint.ENROLL_PARTICIPANT}/${enrollParticipantData.id}/notificationContact`,
      method: "POST",
      data: contactData,
      authToken,
    }).then((result) => {
      console.log('message', result.data.message);
      getavailableContacts();
      message.success(result.data.message);
    });
  }
}
  const addNewContact = () => {
    let errrorObj = {};
    if(!contactData['firstName']){
      errrorObj['firstName'] = 'Please enter the first name';
    }
    else{
      errrorObj['firstName'] = '';
    }

    if(!contactData['lastName']){
      errrorObj['lastName'] = 'Please enter the last name';
    }
    else{
      errrorObj['lastName'] = '';
    }
    if(!contactData['email'] && !contactData['mobilePhone']){
      errrorObj['email'] = 'Please enter the email';
      errrorObj['mobilePhone'] = 'Please enter the phone number';
    }
    else{
      errrorObj['mobilePhone'] = '';
      errrorObj['mobilePhone'] = '';
    }


    setFormErrors(errrorObj);

    if(!errrorObj['firstName'] && !errrorObj['lastName'] && !errrorObj['mobilePhone']){
      apiHandler({
        url: `${endpoint.ENROLL_PARTICIPANT}/${enrollParticipantData.id}/notificationContact`,
        method: "POST",
        data: contactData,
        authToken,
      }).then((result) => {
        console.log('message', result.data.message);
        message.success(result.data.message);
        updatedContacts();
        getavailableContacts();
        
      });
    }
   
  };

  const populateAlertGroups = () => {
    enrollParticipantData?.agency?.id && apiHandler({
      url: `${endpoint.AGENCY_ALERT_LIST}/${enrollParticipantData?.agency?.id}`,
      authToken,
    }).then((result) => {
      setAlertGroups(result.data);
    });
  };
const changeAlertRecipients = (value) =>{
 // console.log("value heree kk",value);
  allcontact.map((el)=>{
    if(el.id === value){
      setselectedRecipients(el);
      }
  })
}
  const changeAlertGroup = (value) => {
    console.log("value heree",value);
    setSelectedAlertGroup(value);
    alertGroups.forEach(element => {
      if(element.id == value){
        setselectedSubscription(element.topicArn);
      //     apiHandler({
      //   // url: `${endpoint.SUBSCRIPTIONS}/${selectedAlertGroup}/${selectedSubscription}`,
      //   url: `${endpoint.SUBSCRIPTIONS}/${enrollParticipantData?.agency?.id}/${element.topicArn}`,
      //   authToken,
      // }).then((result) => {
      //   let objData = result.data.map((obj)=>{
      //     return {
      //       contact:obj.contact,
      //       description: obj.description,
      //       endpoint: obj.endpoint,
      //       protocol: obj.protocol,
      //       subscriptionArn: obj.subscriptionArn
      //     }
      //   });
      //   setDataSource(objData);
      // });
      }
    });
    // if(value){
    //   apiHandler({
    //     // url: `${endpoint.SUBSCRIPTIONS}/${selectedAlertGroup}/${selectedSubscription}`,
    //     url: `${endpoint.SUBSCRIPTIONS}/${enrollParticipantData?.agency?.id}/${selectedSubscription}`,
    //     authToken,
    //   }).then((result) => {
    //     let objData = result.data.map((obj)=>{
    //       return {
    //         contact:obj.contact,
    //         description: obj.description,
    //         endpoint: obj.endpoint,
    //         protocol: obj.protocol,
    //         subscriptionArn: obj.subscriptionArn
    //       }
    //     });
    //     setDataSource(objData);
    //   });
    // }
  }

  const saveAlertGroup = () => {
    apiHandler({
      url: endpoint.ENROLL_PARTICIPANT,
      method: "POST",
      data: {
        id: enrollParticipantData.id,
        notificationList : {
          id : selectedAlertGroup
        }
      },
      authToken,
    }).then((result) => {
      notification.success({
        description: "Alert Group Updated",
        placement: "topRight",
        duration: 5,
      });
    });
  }

  const [dataSource, setDataSource] = useState([]);
  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Subscription Status",
      dataIndex: "subscriptionStatus",
      key: "subscriptionStatus",
    },
    {
      title: "",
      dataIndex: "filterIcon",
      key: "filterIcon",
      align: 'center'
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    // {
    //   title: "Endpoint",
    //   dataIndex: "endpoint",
    //   key: "endpoint",
    // },
    // {
    //   title: "Protocol",
    //   dataIndex: "protocol",
    //   key: "protocol",
    // },
    // {
    //   title: "SubscriptionArn",
    //   dataIndex: "subscriptionArn",
    //   key: "subscriptionArn",
    // },
   
  ];

  const alertColumns = [
    {
      title: 'TRAC Breathalyzer',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'TRAC Watch',
      dataIndex: 'watch',
      key: 'watch',
    },
    {
      title: 'TRAC GPS Watch',
      dataIndex: 'gps',
      key: 'gps',
    },
    {
      title: 'Universal Filters',
      dataIndex: 'filter',
      key: 'filter',
    },
  ];

  const getEventType = async () =>{
    console.log("row here");
    await apiHandler({
      url: `${endpoint.AVAILABLE_EVENT_TYPES}`,
      authToken,
    }).then( async (result) => {
      
      if(result){
        if(result?.data.length > 0){
         
          //result.data.forEach((el,i)=>{
            let breathData = [], gpsData = [], watchData = [], universalData = [];
            await result?.data.forEach(element => {
             if(element.deviceTypeName ==="GPS"){
              element.isChecked = false;
               gpsData.push(element);
               
             }
             if(element.deviceTypeName ==="Watch"){
              element.isChecked = false;
               watchData.push(element);
             }
             if(element.deviceTypeName ==="Breathalyzer"){
              element.isChecked = false;
              breathData.push(element);
             }
             if(element.deviceTypeName ===null){
              element.isChecked = false;
               universalData.push(element);
             }
           });
           setWatch(watchData);
           setUniversal(universalData);
           setGps(gpsData);
           setBreath(breathData);
           console.log("Before Alert Type Data Set=====", watchData, breathData, gpsData, universalData);
          //  generateCheckBox();
        }
      }
    
    });
  }

  useEffect(() => {
    if (authToken && !alertTypeCalled) {
      setAlertTypeCalled(true);
      getEventType()
      
    }
  }, [authToken]);

  useEffect(() => {
    generateCheckBox()
  }, [breath, gps, watch, univarsal]);
useEffect(()=>{
  //getavailableContacts();
},[])
  const generateCheckBox = async () =>{
    console.log("Alert Type Data=====");
    console.log(breath, gps, watch, univarsal);
    let finalData = [{
      key: '1',
      name: <Checkbox  id ="selectAllBreath" name="selectAllBreath" onChange={handleSelectAll}>Select all</Checkbox>,
      watch: <Checkbox id ="selectAllWatch" name="selectAllWatch" onChange={handleSelectAll}>Select all</Checkbox>,
      gps: <Checkbox id ="selectAllGPS" name="selectAllGPS" onChange={handleSelectAll}>Select all</Checkbox>,
      filter: <Checkbox id ="selectAllFilter" name="selectAllFilter" onChange={handleSelectAll}>Select all</Checkbox>,
    }];
    let array1 = [breath.length,watch.length,gps.length,univarsal.length];
    let max_length = Math.max(...array1);
    for(let i=0; i<max_length; i++){
     let make = {};
     make["key"] = (i+2);
     make.name=  breath[i]? <Checkbox id = {breath[i].id }  onChange={(e) => selectSingleCheck("breath", e)} checked={breath[i].isChecked}>{breath[i].name}</Checkbox>: null;
     make.watch = watch[i] ?<Checkbox id = {watch[i].id }  onChange={(e) => selectSingleCheck("watch", e)} checked={watch[i].isChecked}>{watch[i].name}</Checkbox>: null;
     make.gps = gps[i]?<Checkbox id = {gps[i].id }  onChange={(e) => selectSingleCheck("gps", e)} checked={gps[i].isChecked}>{gps[i].name}</Checkbox> : null;
     make.filter = univarsal[i]? <Checkbox id = {univarsal[i].id }  onChange={(e) => selectSingleCheck("univarsal", e)} checked={univarsal[i].isChecked}>{univarsal[i].name}</Checkbox>: null;
     finalData.push(make);
    }
    
    console.log("Alert Type Final Data=====", finalData);
  // })
   setAlertList(finalData);
  }

  const selectSingleCheck = (value,event) =>{
    if(event.target.checked){
      if(value === "breath"){
        breath.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = true;
        });
        setBreath(breath);
      }
      if(value === "watch"){
        watch.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = true;
        });
        setWatch(watch);
      }
      if(value === "gps"){
        gps.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = true;
        });
        setGps(gps);
      }
      if(value === "univarsal"){
        univarsal.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = true;
        });
        setUniversal(univarsal);
      }

    }else{
      if(value === "breath"){
        breath.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = false;
        });
        setBreath(breath);
      }
      if(value === "watch"){
        watch.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = false;
        });
        setWatch(watch);
      }
      if(value === "gps"){
        gps.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = false;
        });
        setGps(gps);
      }
      if(value === "univarsal"){
        univarsal.map(el=>{
          if(el.id == event.target.id)
          el.isChecked = false;
        });
        setUniversal(univarsal);
      }

    }
    
    generateCheckBox();
  }

  const handleSelectAll = event =>{
    if(event.target.checked){
      if(event.target.name==="selectAllBreath"){

        breath.map(el=>{
          el.isChecked=true;
          
        });
        setBreath(breath);
      }
      if(event.target.name==="selectAllWatch"){
        watch.map(el=>{
          el.isChecked=true;
          
        });
        setWatch(watch);
      }
      if(event.target.name==="selectAllGPS"){
        gps.map(el=>{
          el.isChecked=true;
          
        });
        setGps(gps);
      }
      if(event.target.name==="selectAllFilter"){
        univarsal.map(el=>{
          el.isChecked=true;
          
        });
        setUniversal(univarsal);
      }
    }else{
      if(event.target.name==="selectAllBreath"){
        breath.forEach(el=>{
          el.isChecked=false;
        })
      }
      if(event.target.name==="selectAllWatch"){
        watch.forEach(el=>{
          el.isChecked=false;
        })
      }
      if(event.target.name==="selectAllGPS"){
        gps.forEach(el=>{
          el.isChecked=false;
        })
      }
      if(event.target.name==="selectAllFilter"){
        univarsal.forEach(el=>{
          el.isChecked=false;
        })
      }
    }
    generateCheckBox();
  }

  const handleAlertSave = async () =>{
    console.log("fjdfdf",breath);
    //let data = [];
   await breath.forEach(el=>{
    
      if(el.isChecked){
        console.log("el here",el);
        let alertObj = {
          eventType: {
            id: el.id
          },
          contactMethod:alertType
        }
        dummyUserAlertList.push(alertObj);
      }
    })
     watch.forEach(el=>{
      if(el.isChecked){
        let alertObj = {
          eventType: {
            id: el.id
          },
          contactMethod:alertType
        }
        dummyUserAlertList.push(alertObj);
      }
    });
     gps.forEach(el=>{
      if(el.isChecked){
        let alertObj = {
          eventType: {
            id: el.id
          },
          contactMethod:alertType
        }
        dummyUserAlertList.push(alertObj);
      }
    })
     univarsal.forEach(el=>{
      if(el.isChecked){
        let alertObj = {
          eventType: {
            id: el.id
          },
          contactMethod:alertType
        }
        dummyUserAlertList.push(alertObj);
      }
    })
    setUserAlertList(dummyUserAlertList);
    //console.log("fjdfdf",userAlertList);
    setAlertModalVisible(false);
  }

  const handleAlertModalView = (row) => {
    console.log(row);
    setAlertModalVisible(true);
  }

  useEffect(() => {
   
    if(enrollParticipantData){
      populateAlertGroups();
    }
  }, [enrollParticipantData]);

  useEffect(() => {
      setSelectedAlertGroup(alertGroups && alertGroups[0]?.id);
      setselectedSubscription(alertGroups && alertGroups[0]?.topicArn);
      if(participantProfileData && participantProfileData.id && editParticipantProfile){
        console.log("work here pp",selectedAlertGroup);
        setSelectedAlertGroup(participantProfileData && participantProfileData.notificationList.id);
        console.log("work here kkkkk",participantProfileData);
        setselectedSubscription(participantProfileData.notificationList.topicArn);
        setselectedTopicArn(participantProfileData.topicArn);
      }
  }, [alertGroups]);
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  const handleCancel = () =>{
    setDeleteModal(false);
  }
  const onOpenDelete = (value, subscriptionArn) => {
    if(!value){
      value = 0;
    }
    setDeleteModal(true);
    setSContact(value);
    setsArn(subscriptionArn);
  }
  const onClcikDelete = () => {
    if(!scontact){
      setSContact(0);
    }
    apiHandler({
      // url: `${endpoint.SUBSCRIPTIONS}/${selectedAlertGroup}/${selectedSubscription}`,
      url: `${endpoint.SUBSCRIPTION}/${scontact}/${sArn}`,
      authToken,
      method:'DELETE',
    }).then((result) => {
      message.success(result.data.message);
      setDeleteModal(false);
      getavailableContacts();
    });
  }
  const updatedContacts = ()=>{
    apiHandler({
      // url: `${endpoint.AVAILABLE_CONTACTS}/${selectedAlertGroup}`,
      url: `${endpoint.AVAILABLE_CONTACTS}/${enrollParticipantData?.agency?.id}`,
      authToken,
    }).then((result) => {
      setAllcontact(result.data);
      let contactsList = [];

      contactsList = result.data.map((obj,i) => {
        return {
          id:obj.id,
          name : obj.fullName,
          email : obj.email,
          phone: obj.mobilePhone,
          role: obj.role,
          filter: obj.filter,
          value: obj.id,
          label: obj.fullName,
          
        }
      })
      setalertRecipients(contactsList);
      setAvailableContacts(contactsList);
      // let firstNameObj = result.data.map((obj)=>{return {value: obj.firstName}})
      // console.log("firstNameObj",firstNameObj);
     // setfirstNameOptions(firstNameObj);
    });
  }
  const getavailableContacts = () =>{
    apiHandler({
      // url: `${endpoint.SUBSCRIPTIONS}/${selectedAlertGroup}/${selectedSubscription}`,
      url: `${endpoint.SUBSCRIPTIONS}/${enrollParticipantData?.agency?.id}/${selectedTopicArn}`,
      authToken,
    }).then((result) => {
      let objData = result.data.map((obj)=>{
        console.log("objdjkd",obj);
        let returnObj = {};
        if(obj.protocol === "email"){
          returnObj ={
            name: obj?.contact?.fullName,
            role: obj?.contact?.role,
            email: obj.endpoint,
            phone: "",
            subscriptionStatus:obj?.subscriptionArn.startsWith("arn")?"Subscribed":"Pending",
            filterIcon: obj?.subscriptionArn.startsWith("arn")?<>{userData?.websiteUserType?.isAdmin? <DeleteOutlined   onClick={() => {
              onOpenDelete(obj?.contact?.id, obj?.subscriptionArn);
            }} />: ""}</>:""
          }
        }
        if(obj.protocol === "sms"){
          returnObj ={
            name: obj?.contact?.fullName,
            role: obj?.contact?.role,
            email: "",
            phone: obj.endpoint,
            subscriptionStatus:obj?.subscriptionArn.startsWith("arn")?"Subscribed":"Pending",
            filterIcon:   obj?.subscriptionArn.startsWith("arn")?<>{userData?.websiteUserType?.isAdmin? <DeleteOutlined   onClick={() => {
              onOpenDelete(obj?.contact?.id, obj?.subscriptionArn);
            }} />: ""}</>:""
          }
        }
        // availableContacts.map((ctObj)=>{
        //   if(obj.endpoint === ctObj.email || obj.endpoint === ctObj.mobilePhone){
        //     console.log("wrrrrrrrrrrrrrrrr",ctObj);
        //     returnObj = {
        //       name:ctObj.name,
        //       role: ctObj.role,
        //       email: ctObj.email,
        //       phone: ctObj.mobilePhone,
              
        //     }
        //   }
          
        // })
        return returnObj;
      });
     
     setadditionalContact(objData);
    });
  }
  useEffect(() => {
    if(selectedAlertGroup){
      apiHandler({
        // url: `${endpoint.AVAILABLE_CONTACTS}/${selectedAlertGroup}`,
        url: `${endpoint.AVAILABLE_CONTACTS}/${enrollParticipantData?.agency?.id}`,
        authToken,
      }).then((result) => {
        setAllcontact(result.data);
        let contactsList = [];

        contactsList = result.data.map((obj,i) => {
          return {
            id:obj.id,
            name : obj.fullName,
            email : obj.email,
            phone: obj.mobilePhone,
            role: obj.role,
            filter: obj.filter,
            value: obj.id,
            label: obj.fullName,
            
          }
        })
        setalertRecipients(contactsList);
        setAvailableContacts(contactsList);
        // let firstNameObj = result.data.map((obj)=>{return {value: obj.firstName}})
        // console.log("firstNameObj",firstNameObj);
       // setfirstNameOptions(firstNameObj);
      });
      // console.log(alertGroups[0]?.topicArn)
      if(selectedSubscription){
        apiHandler({
          // url: `${endpoint.SUBSCRIPTIONS}/${selectedAlertGroup}/${selectedSubscription}`,
          url: `${endpoint.SUBSCRIPTIONS}/${enrollParticipantData?.agency?.id}/${selectedSubscription}`,
          authToken,
        }).then((result) => {
          let objData = result.data.map((obj)=>{
            let returnObj = {};
            if(obj.protocol === "email"){
              returnObj ={
                name:"",
                role: "",
                email: obj.endpoint,
                phone: "",
              }
            }
            if(obj.protocol === "sms"){
              returnObj ={
                name:"",
                role: "",
                email: "",
                phone: obj.endpoint,
              }
            }
            availableContacts.map((ctObj)=>{
              if(obj.endpoint === ctObj.email || obj.endpoint === ctObj.mobilePhone){
                console.log("wrrrrrrrrrrrrrrrr",ctObj);
                returnObj = {
                  name:ctObj.name,
                  role: ctObj.role,
                  email: ctObj.email,
                  phone: ctObj.mobilePhone,
                  
                }
              }
              
            })
            return returnObj;
          });
         
          setDataSource(objData);
        });
        apiHandler({
          // url: `${endpoint.SUBSCRIPTIONS}/${selectedAlertGroup}/${selectedSubscription}`,
          url: `${endpoint.SUBSCRIPTIONS}/${enrollParticipantData?.agency?.id}/${selectedTopicArn}`,
          authToken,
        }).then((result) => {
          let objData = result.data.map((obj)=>{
            let returnObj = {};
            if(obj.protocol === "email"){
              returnObj ={
                name: obj?.contact?.fullName,
                role: obj?.contact?.role,
                email: obj.endpoint,
                phone: "",
                subscriptionStatus:obj?.subscriptionArn.startsWith("arn")?"Subscribed":"Pending",
                filterIcon: obj?.subscriptionArn.startsWith("arn")?<>{userData?.websiteUserType?.isAdmin? <DeleteOutlined   onClick={() => {
                  onOpenDelete(obj?.contact?.id, obj?.subscriptionArn);
                }} />: ""}</>:""
              }
            }
            if(obj.protocol === "sms"){
              returnObj ={
                name: obj?.contact?.fullName,
                role: obj?.contact?.role,
                email: "",
                phone: obj.endpoint,
                 subscriptionStatus:obj?.subscriptionArn.startsWith("arn")?"Subscribed":"Pending",
                filterIcon:  obj?.subscriptionArn.startsWith("arn")?<>{userData?.websiteUserType?.isAdmin? <DeleteOutlined   onClick={() => {
                  onOpenDelete(obj?.contact?.id, obj?.subscriptionArn);
                }} />: ""}</>:""
              }
            }
            // availableContacts.map((ctObj)=>{
            //   if(obj.endpoint === ctObj.email || obj.endpoint === ctObj.mobilePhone){
            //     console.log("wrrrrrrrrrrrrrrrr",ctObj);
            //     returnObj = {
            //       name:ctObj.name,
            //       role: ctObj.role,
            //       email: ctObj.email,
            //       phone: ctObj.mobilePhone,
                  
            //     }
            //   }
              
            // })
            return returnObj;
          });
         
         setadditionalContact(objData);
        });
      }
    }
  }, [selectedAlertGroup,selectedSubscription])

  return (
    <>
      <h2 style={{ marginTop: 30 }}>Manage Alert Notifications</h2>

      <Row style={{ marginTop: 50 }}>
        <StyledSelectLabel style={{ minWidth: "120px" }}>
          Manage Alert Group:{" "}
        </StyledSelectLabel>
        <Select
          className="enrollParticipantWizard"
          placeholder="Please select alert group"
          onChange={(e) => changeAlertGroup(e)}
          value={selectedAlertGroup}
        >
          {alertGroups?.map((obj) => {
            return <Option value={obj.id}>{obj.name}</Option>;
          })}
        </Select>
        <Button onClick={() => saveAlertGroup()} className="ml-4" type="primary">
          Save
        </Button>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Table scroll={{ y: 120 }} dataSource={dataSource} columns={columns} pagination={false} />
      </Row>

      <Row style={{ marginTop: 20 }}>
        <label><b>Additional Alert Recipients</b></label>
      </Row>
      <Row style={{ marginTop: 10 }}>
        
        <label >
          Select alert recipients from agency user list
        </label>
        </Row>
        <Row style={{marginTop:10}}>
        <Select 
        showSearch
          className="enrollParticipantWizard"
          placeholder=" Select alert recipients "
          onChange={(e) => changeAlertRecipients(e)}
          // value={selectedAlertGroup}
          options={alertRecipients}
          optionFilterProp="label"
        />
          
          {/* {alertRecipients?.map((obj) => {
            return <Option value={obj.id}>{obj.name}</Option>
          })} */}
        {/* </Select> */}
        <StyledButton
            onClick={() => saveAdditionalRecipients()}
            externalCss={css`
              padding: 2px 20px;
              border-radius: 0;
              height: 32px;
            `}
          >
            Add
          </StyledButton>
      </Row>
      <Row style={{ marginTop: 10 }}>
      <Col md={3} style={{ marginRight: 10 }}>
          <Input
            name="firstName"
            onChange={handleInputChange}
            placeholder="First Name"
          />
          <p style={{color: 'red'}}>{formErrors && formErrors['firstName']}</p>
        </Col>
        <Col md={3} style={{ marginRight: 10 }}>
          <Input
            name="lastName"
            onChange={handleInputChange}
            placeholder="Last Name"
          />
          <p style={{color: 'red'}}>{formErrors && formErrors['lastName']}</p>
        </Col>
        <Col md={3} style={{ marginRight: 10 }}>
          <Input
            name="role"
            onChange={handleInputChange}
            placeholder="Judge, Lawyer, Spouse etc"
          />
        </Col>
        <Col md={3} style={{ marginRight: 10 }}>
          <Input
            name="email"
            onChange={handleInputChange}
            placeholder="Email"
          />
            <p style={{color: 'red'}}>{formErrors && formErrors['email']}</p>
        </Col>
        <Col md={3} style={{ marginRight: 10 }}>
          <Input
            name="mobilePhone"
            onChange={handleInputChange}
            placeholder="XXXX-XXXX-XXXX"
          />
          <p style={{color: 'red'}}>{formErrors && formErrors['mobilePhone']}</p>
        </Col>
        <Col md={3} style={{ marginRight: 10, marginBottom:50}}>
          <StyledButton
            onClick={() => addNewContact()}
            externalCss={css`
              padding: 2px 20px;
              border-radius: 0;
              height: 32px;
            `}
          >
            Submit
          </StyledButton>
        </Col>

      </Row>
      <Row style={{marginBottom:100}}>
        <Table scroll={{y: 80}} dataSource={additionalContact} columns={columns2} pagination={false} />
      </Row>

      {/* <Row style={{ marginTop: 10 }}>
      <Button type="primary" style={{marginTop: '1rem'}}>
       <PlusCircleOutlined /> Add
      </Button>
      </Row>
       */}

      {alertModalVisible&&  (
        <StyledModal visible={alertModalVisible}  width={1000} footer={false} closable={false} onCancel={handleAlertCancel}
          title={[
            <Row>
              <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>Alerts Board</Col>
              <Col md={15}>
                <Button type="primary" style={{float: 'right',marginLeft:'1rem'}} onClick={handleAlertSave}>
                  Save
                </Button>
                <Button style={{float: 'right'}} onClick={handleAlertCancel}>Cancel</Button>
              </Col>
            </Row>
          ]}        
        >
          <Row>
            <Col md={24} className="cs_table">
              <Table dataSource={alertList} columns={alertColumns} pagination={false} className="cs_check" />
            </Col>
          </Row>
        </StyledModal>)
        
      }
       <Modal
            title="Delete Subscription"
            open={deleteModal}
            onOk={onClcikDelete}
            onCancel={handleCancel}
            okText="Delete"
            cancelText="Cancel"
          >
            {/* {modalType.mType == "UP" ? (
              <h1>Confirmed Alcohol Consumption</h1>
            ) : (
              <h1>Not An Alcohol Consumption</h1>
            )} */}

            <h3>
              {" "}
              Are you sure you want to delete subscription?
            </h3>
          </Modal>
    </>
  );
};

export default WizardStepTwo;
