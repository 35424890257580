// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-form-item {
  margin-bottom: 10px;
}

/* .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 24px;
} */

.enrollParticipantWizard.ant-select-single .ant-select-selector .ant-select-selection-item,
.enrollParticipantWizard.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 32px;
}

.ant-form-horizontal .ant-form-item-label {
  min-width: 200px;
  text-align: left !important;
}

.enrollParticipantWizard .ant-select-selector {
  height: 32px !important;
}

.historyDropdown .ant-select-selector {
  height: 32px !important;
}

.historyDropdown .ant-select-selector .ant-select-selection-item {
  line-height: 32px !important;
}

.enrollParticipantWizard
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 32px;
}

.enrollParticipantWizard {
  margin-right: 10px;
  min-width: 250px !important;
  max-width: 250px !important;
}

.ant-input-affix-wrapper {

}

.antd-country-phone-input .ant-select {
  min-width: 50px;
}

/* .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none !important;
} */

input:focus {
  outline: none !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/InputAntd/InputAntd.custom.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;;;GAGG;;AAEH;;EAEE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;;;EAGE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;;AAEA;;AAEA;EACE,eAAe;AACjB;;AAEA;;GAEG;;AAEH;EACE,wBAAwB;AAC1B;;AAEA;;;EAGE,2BAA2B;AAC7B","sourcesContent":[".ant-form-item {\n  margin-bottom: 10px;\n}\n\n/* .ant-select-single .ant-select-selector .ant-select-selection-item,\n.ant-select-single .ant-select-selector .ant-select-selection-placeholder {\n  line-height: 24px;\n} */\n\n.enrollParticipantWizard.ant-select-single .ant-select-selector .ant-select-selection-item,\n.enrollParticipantWizard.ant-select-single .ant-select-selector .ant-select-selection-placeholder {\n  line-height: 32px;\n}\n\n.ant-form-horizontal .ant-form-item-label {\n  min-width: 200px;\n  text-align: left !important;\n}\n\n.enrollParticipantWizard .ant-select-selector {\n  height: 32px !important;\n}\n\n.historyDropdown .ant-select-selector {\n  height: 32px !important;\n}\n\n.historyDropdown .ant-select-selector .ant-select-selection-item {\n  line-height: 32px !important;\n}\n\n.enrollParticipantWizard\n  .ant-select-selector\n  .ant-select-selection-placeholder {\n  line-height: 32px;\n}\n\n.enrollParticipantWizard {\n  margin-right: 10px;\n  min-width: 250px !important;\n  max-width: 250px !important;\n}\n\n.ant-input-affix-wrapper {\n\n}\n\n.antd-country-phone-input .ant-select {\n  min-width: 50px;\n}\n\n/* .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {\n  border: none !important;\n} */\n\ninput:focus {\n  outline: none !important;\n}\n\n.ant-input-affix-wrapper:focus,\n.ant-input-affix-wrapper:hover,\n.ant-input-affix-wrapper-focused {\n  box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
