import { Col, Collapse, Row, Space, Spin, Tooltip ,Modal,Button,notification,Table} from "antd";
import styled, { css } from "styled-components";
import { StyledButton } from "theme/StyledComponents";
import BatteryIcon from "assets/caseload/Battery.svg";
import infoCircleIcon from "assets/caseload/InfoCircle.svg";
import ScheduleIcon from "assets/caseload/Schedule.svg";
import TamperIcon from "assets/caseload/Tamper.svg";
import ConnectivityIcon from "assets/caseload/Connectivity.svg";
import ParticipantProfileDefaultPic from "assets/caseload/participant-default-pic.png";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setParticipantProfileData } from "features/caseload/participantProfileDataslice";
import Events from "../Events/Events";
import PinnedNotes from "../PinnedNotes/PinnedNotes";
import Logo from "assets/logo.png";
import {
  setActiveParticipant,
  setEditParticipantProfile,
  setEnrollParticipantData,
  setHistoryStartDate,
  showEnrollParticipant,
  setHistoryDrawer,
  showLoader,
  showMessageDrawer,
  showSurvivorDrawer,
  userType
} from "features/common/commonSlice";
import { setWizardStep } from "features/enrollWizard/enrollWizardSlice";
import apiHandler from "api";
import endpoint from "api/endpoint";
import moment from "moment";

import ManageZones from "../ManageZones/ManageZones";
import ManageSchedule from "../ManageSchedule/ManageSchedule";
import { useParams } from "react-router-dom";
const StyledDataList = styled.ul`
  list-style: none;
`;
export const StyledModal = styled(Modal)`
  border-radius: 10px;
`;
const StyledDataListItem = styled.li`
  font-family: Bicyclette-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  margin: 20px 0;

  &:first-child {
    margin: 0;
  }
`;

const StyledIconList = styled.ul`
  list-style: none;
  display: inline;
`;

export const StyledSpace = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  font-size: 20px;
  font-weight: bold;
`;

const StyledIconListItem = styled.li`
  float: left;
  display: inline-block;
  height: 45px;
  margin: 10px 10px 10px 0px;
  background: ${(props) => props.color};
  border-radius: 6px;
  padding: 10px;
  width: 48px;
  text-align: center;
`;

const { Panel } = Collapse;

const ParticipantProfile = () => {
  const { id,startDate,endDate } = useParams();
  const [participantData, setParticipantData] = useState([]);
const [activateOrnot, setActivateOrnot] =useState(true);
  const { authToken } = useSelector((state) => state.login);
  const { activeParticipantId } = useSelector((state) => state.common);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [hstartDate, sethstartDate] = useState({});
  const profileIcons = [
    { icon: infoCircleIcon, color: "#1A8917", title: "Info Circle" },
    { icon: ConnectivityIcon, color: "#1A8917", title: "Connectivity" },
    { icon: BatteryIcon, color: "#FF0033", title: "Battery" },
    { icon: ScheduleIcon, color: "#1A8917", title: "Calendar" },
    { icon: TamperIcon, color: "#FF0033", title: "Shield" },
  ];
  let programData = useSelector((state) => state.enrollWizardData.programData);
  const [participantBtnShow, setParticipantBtnShow] = useState({});
  const [panelOpen, setPanelOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleDevices, setmodalVisibleDevices] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const deviceColumn = [
    {
      title: 'Device Name',
      dataIndex: 'deviceTypeName',
      key: 'deviceTypeName',
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Assigned On',
      dataIndex: 'assignedOn',
      key: 'assignedOn',
    },
    
  ];
  const openPanel = (e) => {
    // e.stopPropogation();
    setPanelOpen(!panelOpen);
  };
  const handleCancel = () => {
    setModalVisible(false);
    setmodalVisibleDevices(false);

  };
  const handleSaveData = async ()=>{
    //dispatch(setButtonLoading(true));
    var tz = moment.tz.guess();
    var date = moment().minute(0).seconds(0).milliseconds(0).tz(tz);

    date = date.toISOString();
    date = date.replace(":00.000Z", "+0000Z");

 
      const programList = { ...programData, activationDate: date };
      const payload = {};
      if (participantData && participantData.status === 'INACTIVE') {
        payload['activationDate'] = date;
        if(participantData && participantData.latestProgram !== null){
          payload['alcoholProgramType'] = {id: participantData.latestProgram.alcoholProgramType.id}
        }
      } else {
        payload['deactivationDate'] = date;
        payload['alcoholProgramType'] = {id: participantData.latestProgram.alcoholProgramType.id}
      }
      if(participantData && participantData.latestProgram !== null){
        await apiHandler({
          url:
            endpoint.ENROLL_PARTICIPANT +
            "/" +
            `${participantData.id || activeParticipantId}` +
            "/program",
          method: "POST",
          data: payload,
          authToken: authToken,
        }).then(async (result) => {
          // await apiHandler({
          //   url:
          //     endpoint.ENROLL_PARTICIPANT +
          //     "/" +
          //     `${participantData.id || activeParticipantId}` +
          //     "/program",
          //   method: "POST",
          //   data: {alcoholProgramType: {id: participantData.latestProgram.alcoholProgramType.id}},
          //   authToken: authToken,
          // }).then((result) => {
            if(result){
              dispatch(setEditParticipantProfile(false));
                dispatch(setParticipantProfileData([]));
                dispatch(setEnrollParticipantData([]));
                //dispatch(setDrawerErrorMessage());
                 setModalVisible(false)
                dispatch(showEnrollParticipant(false));
          
                notification.info({
                  description: result.data.message,
                  placement: "topRight",
                  duration: 5,
            });
            loadProfile()
            }else{
              dispatch(setEditParticipantProfile(false));
                dispatch(setParticipantProfileData([]));
                dispatch(setEnrollParticipantData([]));
                //dispatch(setDrawerErrorMessage());
                 setModalVisible(false)
                dispatch(showEnrollParticipant(false));
                loadProfile();
            }
            
          });
          dispatch(setActiveParticipant(participantData.id || activeParticipantId));
      }else{
        setModalVisible(false)
        notification.info({
          description: "Latest Program is not Assigned To Participant",
          placement: "topRight",
          duration: 5,
        });

      }
      
      //});
    
  }
  const getActiveDeiveList = (data) => {
    apiHandler({
      url: `${endpoint.ACTIVE_DEVICES}/${data.agency.id}`,
      authToken,
    }).then((result) => {
      const btnShow = {};
        result.data.forEach(dv => {
          if (data.status==='ACTIVE' &&dv.name&&(dv.name === 'Breathalyzer' || dv.name === 'Watch')) {
            btnShow['showSendMessage'] = true;
          } 
          if (dv.name&&(dv.name === 'Breathalyzer' || dv.name === 'GPS')) {
            btnShow['showManageSchedule'] = true;
          } 
          if (data.status==='ACTIVE' &&dv.name&&dv.name === 'GPS') {
            btnShow['showEnrollSurviror'] = true;
          }
          if (dv.name&&dv.name === 'GPS') {
            btnShow['showManageZones'] = true;
          }
        });
        setParticipantBtnShow(btnShow);
    });

  }

  useEffect(() => {
    loadProfile();
  }, []);
  const loadProfile= ()=>{
    setLoading(true);
    apiHandler({
      url: `${endpoint.ENROLL_PARTICIPANT}/${activeParticipantId}
        `,
      authToken,
    }).then((result) => {
      setParticipantData(result.data);
      dispatch(setParticipantProfileData?.(result.data));
      console.log(result.data,"data here");
      
      if (result.data) {
        getActiveDeiveList(result.data);
        let objArray = []
        if(result.data.assignedDevices.length>0){
          console.log("work here");
          result.data.assignedDevices.forEach(el=>{
            console.log("work here",el);
            objArray.push(
              {
                deviceTypeName: el.device.deviceTypeName,
                serialNumber: el.device.serialNumber,
                status: el.device.status,
                assignedOn: el.assignedOn
              }
            );
          })
          setDataSource(objArray);
        }
      }
      // checkParticipantProgramType(result.data);
      if(!startDate && !endDate){
        if(result?.data?.latestProgram !== null){
          dispatch(setHistoryStartDate(moment(result?.data?.latestProgram?.activationDate)));
          sethstartDate(result?.data?.latestProgram?.activationDate)
          let startDate = moment().add(-30, 'days');
          dispatch(setHistoryStartDate(startDate));
          sethstartDate();
        }
      }
      
      
      setLoading(false);
    });
  }
  /* const checkParticipantProgramType = (data) => {
    if (data.agency && data.agency.id) {
      apiHandler({
        url: `${endpoint.AVAILABLE_PROGRAM_TYPES}/${data.agency.id}
          `,
        authToken,
      }).then((result) => {
        console.log('Program Types===', result);
        
        if (result.data&& result.data.assignedDevices && result.data.assignedDevices.length > 0) {
          const btnShow = participantBtnShow;
          result.data.assignedDevices.forEach(dv => {
            
            if (result.data.status==='ACTIVE' &&dv.device&&dv.device.deviceTypeName&&dv.device.deviceTypeName === 'GPS') {
              btnShow['showEnrollSurviror'] = true;
            } 
            if (dv.device&&dv.device.deviceTypeName&&dv.device.deviceTypeName === 'GPS') {
              btnShow['showManageZones'] = true;
            }
          });
          setParticipantBtnShow(btnShow);
        }
      });
    }
    
  } */

  if(loading){
    return <StyledSpace><Spin size={"large"} /></StyledSpace>
  }

  return (
    <>
      {/* <Row>
        <Col md={12}>
          <StyledIconList>
            {profileIcons.map(({ icon, color, title }) => {
              return (
                <Tooltip
                  placement="bottom"
                  title={title}
                  color="#c4c4c4"
                  key={Math.random() * 99}
                >
                  <StyledIconListItem color={color}>
                    <img src={icon} alt="icon" />
                  </StyledIconListItem>
                </Tooltip>
              );
            })}
          </StyledIconList>
        </Col>
      </Row> */}
      <Row>
        <Col md={2}>
          <img
            src={
              participantData?.participantProfileImage
                ? participantData?.participantProfileImage
                : ParticipantProfileDefaultPic
            }
            width="80"
            height="100"
            alt="placeholder"
          />
        </Col>
        <Col md={8}>
          <StyledDataList>
            <StyledDataListItem>{`Name: ${
              participantData?.fullName ?? ""
            }`}</StyledDataListItem>
            <StyledDataListItem>{`AKA: ${
              participantData?.firstName ?? ""
            }`}</StyledDataListItem>
            <StyledDataListItem>{`Phone Number: ${
              participantData?.mobilePhone ?? ""
            }`}</StyledDataListItem>
          </StyledDataList>
        </Col>
        <Col md={8}>
          <StyledDataList>
            <StyledDataListItem>{`Time Zone: ${
              participantData?.timeZone ?? ""
            }`}</StyledDataListItem>
            <StyledDataListItem>{`Client Type: ${
              participantData?.clientType ?? ""
            }`}</StyledDataListItem>
            <StyledDataListItem>{`Offense: ${
              participantData?.offense ?? ""
            }`}</StyledDataListItem>
          </StyledDataList>
        </Col>
        <Col md={6}>
          
          <Row> 
            <Col span={24}>
              <StyledButton
                externalCss={css`
                  padding: 10px 20px;
                  float: right;
                `}
                onClick={(e) => {
                  dispatch(setEditParticipantProfile(true));
                  dispatch(setActiveParticipant(participantData?.id));
                  dispatch(setEnrollParticipantData(participantData));
                  dispatch(showEnrollParticipant(true));
                  dispatch(setParticipantProfileData(participantData));
                  // if (currentStep < 0 || !currentStep) {
                  dispatch(setWizardStep(0));
                  localStorage.setItem("userType","old");
                  dispatch(userType("old"));
                  // }
                }}
              >
                Edit Profile/Manage Program
              </StyledButton>
            </Col>
            
          </Row>
          <Row> 
            <Col span={24} >
              <StyledButton
                externalCss={css`
                  background: #fff;
                  border: 1px solid #0557a2;
                  color: #0557a2;
                  padding: 10px;
                  margin: 15px 0px;
                  display: flex;
                  justify-content: flex-end;
                  float: right;
                `}
                onClick={(e) => {
                  // e.stopPropagation();
                  dispatch(setHistoryDrawer(true));
                  dispatch(setHistoryStartDate(moment(hstartDate)))
                }}
              >
                View History/Reports
              </StyledButton>
            </Col>
          </Row>
          <Row> 
            <Col span={24} >
            {participantData.status === "ACTIVE" ?(
               <StyledButton
               externalCss={css`
                 background: #FF0000;                  ;
                 border: 1px solid #0557a2;
                 color: #fff;
                 padding: 10px;
                 margin: 15px 0px;
                 display: flex;
                 justify-content: flex-end;
                 float: right;
               `}
               onClick={(e) => {
                 // e.stopPropagation();
                 setModalVisible(true);
                 setActivateOrnot(true)
                 
               }}
             >
               Deactivate
             </StyledButton>
        ):(
          <StyledButton
          externalCss={css`
            background: #52c41a;                  ;
            border: 1px solid #0557a2;
            color: #fff;
            padding: 10px;
            margin: 15px 0px;
            display: flex;
            justify-content: flex-end;
            float: right;
          `}
          onClick={(e) => {
            // e.stopPropagation();
            setModalVisible(true);
            setActivateOrnot(false)
           
          }}
        >
          Activate
        </StyledButton>
        )}
             
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          {participantBtnShow&&participantBtnShow.showSendMessage&& (
            <StyledButton
            externalCss={css`
              background: #fff;
              border: 1px solid #0557a2;
              color: #0557a2;
              padding: 10px;
              margin: 15px 5px;
            `}
            onClick={() => {
              dispatch(showMessageDrawer(true));
            }}
            >
            Send Message 
            </StyledButton>
          )}
          
          {participantBtnShow&&participantBtnShow.showManageZones&& (
            <ManageZones />
          )}

          {participantBtnShow&&participantBtnShow.showManageSchedule&& (
            <ManageSchedule />
          )}

          {participantBtnShow&&participantBtnShow.showEnrollSurviror&& (
            <StyledButton
              externalCss={css`
                background: #fff;
                border: 1px solid #0557a2;
                color: #0557a2;
                padding: 10px;
                margin: 15px 5px;
              `}
              onClick={() => {
                dispatch(showSurvivorDrawer(true));
              }}
              >
              Enroll Survivor
            </StyledButton>
          )}
           <StyledButton
            externalCss={css`
              background: #fff;
              border: 1px solid #0557a2;
              color: #0557a2;
              padding: 10px;
              margin: 15px 5px;
            `}
            onClick={() => {
              //dispatch(showMessageDrawer(true));
              setmodalVisibleDevices(true)
            }}
            >
            Assigned Devices 
            </StyledButton>
        </Col>
      </Row>
      <Row className="participantProfileCollapse">
        <Collapse
          destroyInactivePanel
          defaultActiveKey={["0"]}
          onChange={(e) => openPanel(e)}
        >
          <Panel
            forceRender={true}
            header={panelOpen ? "Show Less" : "Show More"}
            key="1"
          >
            <Row>
              <Col md={8}>
                <StyledDataList>
                  <StyledDataListItem>
                    {`Address: ${[
                      participantData?.address?.address1,
                      participantData?.address?.address2,
                      participantData?.address?.city,
                      participantData?.address?.state,
                      participantData?.address?.zip,
                    ]
                      .filter(Boolean)
                      .join(", ")}`}
                  </StyledDataListItem>
                  <StyledDataListItem>
                    {`Birth Date: ${
                      (participantData?.birthDate &&
                        moment(participantData?.birthDate).format(
                          "MM-DD-YYYY"
                        )) ??
                      ""
                    }`}
                  </StyledDataListItem>
                  <StyledDataListItem>
                    Email : {participantData?.email}
                  </StyledDataListItem>
                  <StyledDataListItem>
                    Alt. Phone Number : {participantData?.altPhone ?? ""}
                  </StyledDataListItem>
                </StyledDataList>
              </Col>
              <Col md={8}>
                <StyledDataList>
                  <StyledDataListItem>
                    Drivers License Number:{" "}
                    {participantData?.driversLicense ?? ""}
                  </StyledDataListItem>
                  <StyledDataListItem>
                    Occupation: {participantData?.occupation ?? ""}
                  </StyledDataListItem>
                  <StyledDataListItem>
                    Gender: {participantData?.gender ?? ""}
                  </StyledDataListItem>
                  <StyledDataListItem>
                    Ethnicity: {participantData?.ethnicity ?? ""}
                  </StyledDataListItem>
                </StyledDataList>
              </Col>
              <Col md={8}>
                <StyledDataList>
                  <StyledDataListItem>
                    Height: {participantData?.height ?? ""}
                  </StyledDataListItem>
                  <StyledDataListItem>
                    Weight: {participantData?.weight ?? ""}
                  </StyledDataListItem>
                  <StyledDataListItem>
                    Hair Color: {participantData?.hairColor ?? ""}
                  </StyledDataListItem>
                  <StyledDataListItem>
                    Eye Color: {participantData?.eyeColor ?? ""}
                  </StyledDataListItem>
                </StyledDataList>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Row>
      <Row>
        <Col md={18}>
          <Events />
        </Col>
        <Col md={6}>
          <PinnedNotes participantId={participantData?.id} />
        </Col>
      </Row>
      <StyledModal visible={modalVisible} width={800} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>  <img alt="Shop Life Logo" src={Logo} style={{width: '120px'}} /> &nbsp; &nbsp; &nbsp;Save Events</Col>
            <Col md={15}>
              <Button type="primary" onClick={handleSaveData} style={{float: 'right',marginLeft:'1rem'}}>
                Yes
              </Button>
              <Button style={{float: 'right'}} onClick={handleCancel}>No</Button>
            </Col>
          </Row>
        ]}        
      >
        
        <Row>
          <Col>
            {activateOrnot === true ?(
              <>
               Do you want to Deactivate This Participant { participantData?.fullName}
               </>
            ):(
              <>
              Do you want to Activate This Participant { participantData?.fullName}
              </>
            )}
          </Col>
          {/* <Col md={24} className="cs_table">
            <Table dataSource={dataSource} columns={columns} pagination={false} className="cs_check" />
          </Col> */}
          
        </Row>
      

       
      </StyledModal>
      <StyledModal visible={modalVisibleDevices} width={800} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>  <img alt="Shop Life Logo" src={Logo} style={{width: '120px'}} /> &nbsp; &nbsp; &nbsp;Assigned Devices</Col>
            <Col md={15}>
              {/* <Button type="primary" onClick={handleSaveData} style={{float: 'right',marginLeft:'1rem'}}>
                Yes
              </Button> */}
              <Button style={{float: 'right'}} onClick={handleCancel}>Close</Button>
            </Col>
          </Row>
        ]}        
      >
        
        <Row>
          <Col>
          </Col>
          <Col md={24} className="cs_table">
            <Table dataSource={dataSource} columns={deviceColumn} pagination={false} className="cs_check" />
          </Col>
          
        </Row>
      

       
      </StyledModal>
    </>
  );
};

export default ParticipantProfile;
