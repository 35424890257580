import React, { useState, useEffect } from "react";
import apiHandler from "api";
import endpoint from "api/endpoint";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import SideMenu from "components/SideMenu/SideMenu";
import Logo from "assets/logo.png";
import "./header.css";
import { StyledButtonLink } from "theme/StyledComponents";
import {
  setEditParticipantProfile,
  setEnrollParticipantData,
  showEnrollParticipant,
  userType,
} from "features/common/commonSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setWizardStep } from "features/enrollWizard/enrollWizardSlice";
import { saveEnrollParticipantFormData } from "features/enrollParticipant/enrollParticipantSlice";
import { setParticipantProfileData } from "features/caseload/participantProfileDataslice";
import { Dropdown } from "antd";
import UserLogo from "assets/user.png";
import Profile from "../Profile/Profile";

const Header = (props) => {
  
  const dispatch = useDispatch();


  const enrollParticipant = () => {
    dispatch(showEnrollParticipant(true));
    dispatch(setEditParticipantProfile(false));
    dispatch(setEnrollParticipantData([]));
    dispatch(saveEnrollParticipantFormData([]));
    dispatch(setParticipantProfileData([]));
    dispatch(setWizardStep(0));
    localStorage.setItem("userType","new");
    dispatch(userType("new"));
    navigate("/caseload");

  };
  const items = [
    {
      key: "1",
      label: (
        <span
          className="cursor-pointer"
          key="MyProfile"
          onClick={() => handleProfileMenuClick("MyProfile")}
        >
          My Profile
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          className="cursor-pointer"
          key="Logout"
          onClick={() => handleProfileMenuClick("Logout")}
        >
          Logout
        </span>
      ),
    },
  ];

  const navigate = useNavigate();
  const { authToken, userData } = useSelector((state) => state.login);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [deviceList, setDevice] = useState([]);
  const [photosCount, setPhotosCount] = useState(0);
  const filters = useSelector((state) => state.filterData.filterDataObj);
  const handleProfileMenuClick = (key) => {
    // console.log('menu click===', key);

    if (key === "MyProfile") {
      setShowProfileModal(true);
    } else if (key === "Logout") {
      sessionStorage.clear();
      navigate("/logout");
    }
  };

  const handleOk = () => {
    setShowProfileModal(false);
  };
  const handleCancel = () => {
    setShowProfileModal(false);
  };

  const getAvailableDeviceTypes = (agencyId) => {
    apiHandler({
      url: `${endpoint.ACTIVE_DEVICES}/${agencyId}`,
      authToken,
    }).then((result) => {
      console.log("result here", result);
      setDevice(result?.data);
    });
  };
  const getUnvalidatedPhotos = () => {
    apiHandler({
      url: `${endpoint.ADMIN_UNVALIDATED_PHOTOS_COUNT}`,
      authToken,
    }).then((result) => {
      setPhotosCount(result.data.count);
    });
  };
  useEffect(() => {
    console.log("userData here", userData);
    let activeId = "";
    if (userData?.agencies.length > 0) {
      activeId = userData?.agencies[0]["id"];
    }

    getAvailableDeviceTypes(activeId);
    getUnvalidatedPhotos();
  }, []);

  return (
    <div className="container">
      <header className="toolbar">
        <nav className="toolbar_navigator">
          <div />
          <div className="toggle-btn">
            <SideMenu click={props.drawerToggleClickHandler} />
          </div>
          <div className="toolbar_logo">
            <Link to="/caseload">
              <img alt="Shop Life Logo" src={Logo} />
            </Link>
          </div>
          <div className="toolbar_navigation-items">
            <ul>
              <li>
                <Link to="/caseload">Caseload</Link>
              </li>
              {userData.websiteUserType.isSuperUser ? (
                <>
                  <li>
                    <Link to="/agency">Agency</Link>
                  </li>
                  <li>
                    <Link to="/openevents">Open Events</Link>
                  </li>
                  <li>
                    <Link to="/maintenanceevents">Maintenance Events</Link>
                  </li>
                </>
              ) : (
                <></>
              )}
              
              {userData.role === 'Agency User' ? (
                <>
                  <li>
                    <Link to="/openevents">Open Events</Link>
                  </li>
                </>
              ) : (
                <></>
              )}
              {userData.websiteUserType.isAgencyOnly ? (
                <>
                  <li>
                    <Link to="/agency">Agency</Link>
                  </li>
                  <li>
                    <Link to="/openevents">Open Events</Link>
                  </li>
                </>
              ) : (
                <></>
              )}
              {/* The Open Events page should be visible to SuperUsers and Agency Admins. Right now, Agency Admins are unable to see that page. Please get this fixed as soon as you can and let me know.  
              */}
              {userData.canValidatePhotos ? (
                <li>
                  <Link to="/photos">Photos({photosCount})</Link>
                </li>
              ) : (
                <></>
              )}

              {/* <li>
                <Link to="/reports">Reports</Link>
              </li> */}
              {/* <li>
                <Link to="/admin">Admin</Link>
              </li>
              <li>
                <Link to="/my-account">My Account</Link>
              </li> */}
              {/* <img
                style={{ marginLeft: 20, cursor: "pointer" }}
                width="40"
                src={LogoutIcon}
                alt="Logout"
                onClick={() => authenticateUser.signout(dispatch, history)}
              /> */}
            </ul>
          </div>
          <div className="sec-stl">
            <StyledButtonLink
              externalCss={css`
                padding: 5px;
                min-width: 200px;
                font-family: Aileron-SemiBold;
                font-weight: 600;
                box-shadow: none;
                border: none;
                margin-right: 10px;

                @media (max-width: 768px) {
                  display: none;
                }
              `}
              type="primary"
              size="large"
              onClick={enrollParticipant}
            >
              Enroll Participant
            </StyledButtonLink>
            <>
              <Dropdown menu={{ items }}>
                <img
                  onClick={(e) => e.preventDefault()}
                  className="img-stl"
                  src={UserLogo}
                  alt="User"
                ></img>
              </Dropdown>
            </>
          </div>
        </nav>
      </header>

      {/* {showProfileModal && ( */}
      <Profile
        deviceList={deviceList}
        showProfile={showProfileModal}
        handleCancel={handleCancel}
      />

      {/* )} */}

      {/* {showProfileModal &&  (
        <StyledModal visible={showProfileModal}  width={1000} footer={false} closable={false} onCancel={handleCancel}
          title={[
            <Row>
              <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>Profile</Col>
              <Col md={15}>
                <Button type="primary" style={{float: 'right',marginLeft:'1rem'}} onClick={handleOk}>
                  Save
                </Button>
                <Button style={{float: 'right'}} onClick={handleCancel}>Cancel</Button>
              </Col>
            </Row>
          ]}        
        >
          <Row>
            <Col md={24} >
              Profile Detail should be here...
            </Col>
          </Row>
        </StyledModal>
        )} */}
    </div>
  );
};

export default Header;
