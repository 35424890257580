// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ant-input-group {
      border: none!important;
      border-radius: 6px;
      margin-bottom: 10px;
}
.mg_2{
      padding-right: 10px;
      padding-left: 10px;
}
.required {
      color: red;
      padding-right: 5px;
}

.ant-btn > .plus-circle svg {
      color: #fff !important;
}

.anticon-close svg {
      color: rgba(0, 0, 0, 0.45);
      font-size: 16px;
      margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/containers/EditAlerts/EditAlerts.css"],"names":[],"mappings":";AACA;MACM,sBAAsB;MACtB,kBAAkB;MAClB,mBAAmB;AACzB;AACA;MACM,mBAAmB;MACnB,kBAAkB;AACxB;AACA;MACM,UAAU;MACV,kBAAkB;AACxB;;AAEA;MACM,sBAAsB;AAC5B;;AAEA;MACM,0BAA0B;MAC1B,eAAe;MACf,kBAAkB;AACxB","sourcesContent":["\n.ant-input-group {\n      border: none!important;\n      border-radius: 6px;\n      margin-bottom: 10px;\n}\n.mg_2{\n      padding-right: 10px;\n      padding-left: 10px;\n}\n.required {\n      color: red;\n      padding-right: 5px;\n}\n\n.ant-btn > .plus-circle svg {\n      color: #fff !important;\n}\n\n.anticon-close svg {\n      color: rgba(0, 0, 0, 0.45);\n      font-size: 16px;\n      margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
