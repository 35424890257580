// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-collapse > .ant-collapse-item > .ant-collapse-header{
      padding : 10px 0;
}

.ant-collapse-content > .ant-collapse-content-box{
      padding : 0px 0 !important;
      max-height: 100% !important;
      overflow-y: hidden !important;
}

.ant-collapse-item:last-child > .ant-collapse-content{
      border-top : none;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg, .ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
      border: none;
}
`, "",{"version":3,"sources":["webpack://./src/containers/EnrollParticipant/CollapseCustom.css"],"names":[],"mappings":"AAAA;MACM,gBAAgB;AACtB;;AAEA;MACM,0BAA0B;MAC1B,2BAA2B;MAC3B,6BAA6B;AACnC;;AAEA;MACM,iBAAiB;AACvB;;AAEA;MACM,YAAY;AAClB","sourcesContent":[".ant-collapse > .ant-collapse-item > .ant-collapse-header{\n      padding : 10px 0;\n}\n\n.ant-collapse-content > .ant-collapse-content-box{\n      padding : 0px 0 !important;\n      max-height: 100% !important;\n      overflow-y: hidden !important;\n}\n\n.ant-collapse-item:last-child > .ant-collapse-content{\n      border-top : none;\n}\n\n.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg, .ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{\n      border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
